import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternalMessageService {
    // private subject = new Subject<any>();
    private subject: ReplaySubject<any> = new ReplaySubject(1);

    sendMessage(message: any): void {
        this.subject.next( message );
    }

    clearMessages(): void {
        this.subject.next({});
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}

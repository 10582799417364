import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
// import { MessagesService } from 'app/layout/common/messages/messages.service';
import { map } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() messages: any[] =[]
    @Input() title: string = '';

    replies: any[] = [];

    public subs = new SubSink();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.replies = this.messages.filter((x:any) => x.parent_id > 0)
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    ngAfterViewInit(): void
    {
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

}

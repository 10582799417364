
<form class="flex flex-col md:w-140 flex-auto" [formGroup]="uploadForm">
    <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop a File or Select a File</ngx-dropzone-label>
        <ngx-dropzone-preview [removable]="true" (removed)="onRemove()" *ngIf="file">
            <ngx-dropzone-label >{{ file?.name }} ({{ file?.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
    <!-- Alert -->
    <fuse-alert class="mt-4 -mb-2" *ngIf="alert.message" [appearance]="'outline'" [showIcon]="false" [type]="alert.type" >
        {{alert.message}}
    </fuse-alert>
    <!-- Actions -->
    <div class="flex items-center justify-center mt-2 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7">
        <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="uploadForm.invalid" (click)="uploadFile()">
            Submit
        </button>
    </div>

</form>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApprovalQueueService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _approvalQueues: BehaviorSubject<any[] | null> = new BehaviorSubject<any[] | null>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for approvalQueues
     */
     get approvalQueues$(): Observable<any[] | null>
     {
         return this._approvalQueues.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get approvalQueues by projectId
     */
    getApprovalQueueByProjectId(projectId: any): Observable<any[]>
    {
        // //console.error('getApprovalQueue:+++++++++++++++++++++++', projectId);
        //console.log('getIApprovalQueue service1:');
        return this.http.get<any[]>(`${this.apiURL}approvalqueues/project/${projectId}`).pipe(
            // tap((x:any) => console.log(3333333333331, x)),
            map((val: any) => val?.data ? val.data[0] : []),
            // tap((x:any) => console.log(3333333333332, x)),
            tap((response: any) => {
                // //console.error('getApprovalQueue service2:+++++++++++++++++++++++', response);
                this._approvalQueues.next(response);
            })
        );
    }

    // getApprovalQueueByUserId(userTypeId: number): Observable<any[]>
    // {
    //     // console.log('getApprovalQueue:+++++++++++++++++++++++', userTypeId);
    //     //console.log('getIApprovalQueue service1:');
    //     return this.http.get<any[]>(`${this.apiURL}approvalqueues/user/${userTypeId}`).pipe(
    //         map((val: any) => val?.data ? val.data[0] : []),
    //         tap((response: any) => {
    //             // console.log('getApprovalQueue service2:+++++++++++++++++++++++', response);
    //             this._approvalQueues.next(response);
    //         })
    //     );
    // }

    getApprovalQueueByUserTypeId(userTypeId: number, userId: number): Observable<any[]>
    {
        // console.log('getApprovalQueue:+++++++++++++++++++++++', userTypeId);
        //console.log('getIApprovalQueue service1:');
        return this.http.get<any[]>(`${this.apiURL}approvalqueues/user/type/${userTypeId}/${userId}`).pipe(
            map((val: any) => val?.data ? val.data[0] : []),
            tap((response: any) => {
                // console.log('getApprovalQueue service2:+++++++++++++++++++++++', response);
                this._approvalQueues.next(response);
            })
        );
    }

    createApprovalQueue(engagementId: string, roleId: number, isCompleted: boolean = false): Observable<any>
    {
        // console.log('updatedCompleted:', engagementId, roleId);
        const data = {'role_id': roleId, 'completed': isCompleted};
        return this.http.post<any>(this.apiURL + `approvalqueues/${engagementId}`, data)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // // HttpClient API put() method => Update engagement
    // updateApprovalQueue(id, engagement): Observable<any> {
    //     return this.http.put<any>(`${this.apiURL}approvalqueues/${id}`, JSON.stringify(engagement), this.httpOptions)
    //     .pipe(
    //         retry(1),
    //         catchError(this.handleError)
    //     );
    // }

    // // HttpClient API delete() method => Delete engagement
    // deleteApprovalQueue(id): Observable<any>{
    //     return this.http.delete<any>(`${this.apiURL}approvalqueues/${id}`, this.httpOptions)
    //     .pipe(
    //         retry(1),
    //         catchError(this.handleError)
    //     );
    // }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

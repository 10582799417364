
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { base_component } from 'app/shared/components/base_component';
import { NotificationService } from 'app/shared/service/notification.service';
import { map } from 'rxjs';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectQuestionsService } from 'app/shared/service/project-questions.service';

@Component({
    selector: 'app-project-questions-view',
    templateUrl: './project-questions-view.component.html',
    styleUrls: ['./project-questions-view.component.scss']
})
export class ProjectQuestionsViewComponent extends base_component implements OnInit {

    questionForm: FormGroup;
    questions: any[] = [];
    questionGroups: any;
    projectId:string = '';

    constructor(
        public _matDialogRef: MatDialogRef<ProjectQuestionsViewComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: { projectId: string },
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _projectQuestionsService: ProjectQuestionsService)
    {
        super(_notifyService, _userService);
    }

    ngOnInit() {
        this.getQuestions(this._data.projectId);
    }

    setUpQuestions() {
        const questionControlArray = [];
        for (let i = 0; i < 20; i++) {
            questionControlArray.push({
                question: ['', Validators.required],
                answer: [{response:'', answer_id:null}]
            });
        }
        return questionControlArray;
    }

    discard(): void
    {
        this._matDialogRef.close();
    }

    private getQuestions(projectId:any) {
        console.log("111111111111111~ getQuestions ~ projectId:", projectId)
        this.subs.sink = this._projectQuestionsService.getQuestions(projectId)
            .pipe(
                map((val: any) => val?.data && val?.data[0] || [])
            )
            .subscribe((result: any) => {
                this.questions = result;
                // this.questions.forEach((x:any,index) => {
                //     this.questions[index] = x.answer;
                // })

                this._changeDetectorRef.markForCheck();
            });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { Category, Course, Engagement, Interview, Status } from 'app/shared/model/engagement.types';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TprmProjectService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _interviews: BehaviorSubject<Interview[] | null> = new BehaviorSubject<Interview[] | null>(null);
    private _engagement: BehaviorSubject<Engagement | null> = new BehaviorSubject<Engagement | null>(null);
    private _engagements: BehaviorSubject<Engagement[] | null> = new BehaviorSubject<Engagement[] | null>(null);
    private roleCompleted: BehaviorSubject<any[] | null> = new BehaviorSubject<any[] | null>(null);
    // private controlCompleted: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private engagementInserted: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for engagements
     */
     get engagements$(): Observable<Engagement[] | null>
     {
         return this._engagements.asObservable();
     }

     get engagement$(): Observable<Engagement | null>
     {
         return this._engagement.asObservable();
     }

    get interviews$(): Observable<Interview[] | null>
    {
        return this._interviews.asObservable();
    }

     get roleCompleted$(): Observable<any>
     {
         return this.roleCompleted.asObservable();
     }

    get engagementInserted$(): Observable<any>
    {
        return this.engagementInserted.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /************************* tprm engagements **********************************/

    getEngagements(pageLimit:number, pageOffset:number): Observable<any[]>
    {
        return this.http.get<any[]>(this.apiURL + `tprmprojects?pageLimit=${pageLimit}&pageOffset=${pageOffset}`).pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
    }

    getEngagementsByStatus(status: number = 2, pageLimit:number, pageOffset:number): Observable<any[]>
    {
        return this.http.get<any[]>(this.apiURL + `tprmprojects?active=${status}&pageLimit=${pageLimit}&pageOffset=${pageOffset}`).pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
    }

    getUnassignedProjects(): Observable<any[]>
    {
        return this.http.get<any[]>(this.apiURL + `tprmprojects/projects/type/unassigned`).pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
    }

    getTPRMProjectsByRole(userId: number, roleId: number): Observable<any>
    {
        return this.http.get(this.apiURL + `tprmprojects/projects/type/assigned/${userId}/${roleId}`)
        .pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
    }

    getTPRMProjectsForPoc(userId: number): Observable<any>
    {
        return this.http.get(this.apiURL + `tprmprojects/projects/poc/${userId}`)
        .pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
    }

    /**
     * Get project by id
     */
    getEngagementById(id: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + 'tprmprojects/projects/' + id ).pipe(
            map((val: any) => val?.data && val?.data[0] || []),
            map((engagement: any) => {
                const project = engagement[0];
                // Update the course
                this._engagement.next(project);
                // Return the course
                return project;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagement with id of ' + id + '!');
                }
                return of(engagement);
            })
        );
    }

    createEngagement(data: any): Observable<any>
    {
        return this.http.post(this.apiURL + 'tprmprojects', data)
            .pipe(
                tap((result: any) => {
                    this.engagementInserted.next(result);
                }),
                catchError(this.handleError)
            );
    }

    /************************* engagements **********************************/


    // getEngagementsByAuditor(userId: number): Observable<any[]>
    // {
    //     return this.http.get<any[]>(this.apiURL + `engagements/audit/${userId}`).pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             // console.log('getEngagements service2:', response);
    //             this._engagements.next(response);
    //         })
    //     );
    // }
    // /**
    //  * Get projects by type
    //  */
    // getEngagementsByType(paramType: string, page: any = null): Observable<any[]>
    // {
    //    //  return this.http.get<any[]>(this.apiURL + `engagements/type/${paramType}`).pipe(
    //    //      tap((response: any) => {
    //    //          console.log('getEngagements service2:', response);
    //    //          this._engagements.next(response);
    //    //      })
    //    //  );
    //    return this.http.get<any[]>(this.apiURL + 'engagements').pipe(
    //        retry(1),
    //        tap((response: any) => {
    //            console.info('getEngagements service2:', response);
    //            this._engagements.next(response);
    //        })
    //    );
    // }

    // ///roles/:type/:userid
    // getAssignedEngagementsByRole(role: string, userid: number): Observable<any>
    // {
    //     return this.http.get<any>(this.apiURL + `engagements/roles/${role}/${userid}` ).pipe(
    //         retry(1),
    //         map((x: any) => x.data ? x.data[0] : []),
    //         map((engagements: any) => {
    //             // console.log('>>>> getEngagementById', engagements );
    //             // Update the course
    //             this._engagements.next(engagements );
    //             // Return the course
    //             return engagements;
    //         }),
    //         switchMap((engagement) => {
    //             if ( !engagement )
    //             {
    //                 return throwError('Could not found engagement with role of ' + role + '! 4');
    //             }
    //             return of(engagement);
    //         })
    //     );
    // }

    // search(query: string): Observable<any[]>
    // {
    //     return this.http.get<any[]>(this.apiURL + `engagements/search/${query}` ).pipe(
    //         retry(1),
    //         map((result: any[]) => {
    //             console.log('>>>> search', result);

    //             // Return the course
    //             return result;
    //         }),
    //         switchMap((engagement) => {
    //             if ( !engagement )
    //             {
    //                 return throwError('Could not found projects!');
    //             }
    //             return of(engagement);
    //         })
    //     );
    // }

    // //get all completed count
    // getCompletedControlCount(id: string): Observable<any>
    // {
    //      return this.http.get<any>(this.apiURL + `engagements/${id}/completed` ).pipe(
    //         retry(1),
    //          map((val: any) => val?.data[0] || []),
    //          map((completed: any) => {
    //              console.log('>>>> getProjectControlCompleted', completed);
    //              // Update the course
    //              this.controlCompleted.next(completed);
    //              // Return the course
    //              return completed;
    //          }),
    //          switchMap((data) => {
    //              if ( !data )
    //              {
    //                  return throwError('Could not found roles with id of ' + id + '! 5');
    //              }
    //              return of(data);
    //          })
    //      );
    //  }

    //  getRoleCompleted(id: string, roleId: string): Observable<any>
    //  {
    //      return this.http.get<any>(this.apiURL + `engagements/${id}/role/${roleId}/completed`).pipe(
    //         retry(1),
    //          map((val: any) => val?.data[0] || []),
    //          map((completed: any) => {
    //              console.log('>>>> getRoleCompleted', completed);
    //              // Update the course
    //              this.roleCompleted.next(completed);
    //              // Return the course
    //              return completed;
    //          }),
    //          switchMap((data) => {
    //              if ( !data )
    //              {
    //                  return throwError('Could not found roles with id of ' + id + '! 5');
    //              }
    //              return of(data);
    //          })
    //      );
    //  }

    // createCompleted(data: any): Observable<any>
    // {
    //     return this.http.post<any>(this.apiURL + `engagements/${data.engagement_id}/completed`, data)
    //     .pipe(
    //         retry(1),
    //         catchError(this.handleError)
    //     );
    // }

    // updateStatus(id: any, data: any): Observable<any>
    // {
    //     console.log('updateStatus', id, data);
    //     return this.http.post(`${this.apiURL}engagements/${id}/status`,
    //         JSON.stringify({status: data.statusId, updated_by: data.updated_by}),
    //         this.httpOptions)
    //         .pipe(
    //             retry(1),
    //             tap((result: any) => {
    //                 console.log('postEngagement:', result);
    //                 // this.engagementInserted.next(result);
    //             }),
    //             catchError(this.handleError)
    //         );
    // }

    // HttpClient API put() method => Update engagement
    updateEngagementStandard(project:any): Observable<any> {
        return this.http.post<any>(`${this.apiURL}tprmprojects/standard`, JSON.stringify(project), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    updateEngagement(id:any, project:any): Observable<any> {
        return this.http.put<any>(`${this.apiURL}tprmprojects/${id}`, JSON.stringify(project), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API delete() method => Delete engagement
    deleteEngagement(guid:string): Observable<any>{
        return this.http.delete<any>(`${this.apiURL}tprmprojects/${guid}`, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

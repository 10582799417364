import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'app/core/user/user.service';
import { EvidenceRequestService } from 'app/shared/service/evidence-request.service';
import { base_component } from 'app/shared/components/base_component';
import { NotificationService } from 'app/shared/service/notification.service';
import { map } from 'rxjs';
import { v4 as uuid, validate } from 'uuid';
import { RoleService } from 'app/shared/service/role.service';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent extends base_component implements OnInit, OnDestroy {

    requestForm: FormGroup;
    projectPOC: any;
    requestSent: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: { controlGuid: string, projectGuid: string, projectName: string, controlIdName: string},
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _formBuilder: FormBuilder,
        private _evidenceRequestService: EvidenceRequestService,
        private _roleService: RoleService,
        private _matDialog: MatDialog,
    )
    {
        super(_notifyService, _userService);
    }

    ngOnInit(): void {
        this.getCurrentUser();
        this.setupForm();
        this.getPrjectPOC();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    close(): void {
        this._matDialog.closeAll();
    }

    submit(): void {
        if ( this.requestForm.invalid ) { return; }
        // Get the contact object
        const formData = this.requestForm.getRawValue();
        var date = new Date();
        let msg = {
            guid: uuid(),
            title: `Message From Assessor - Project, ${this._data.projectName}`,
            link: '',
            content: formData.note,
            icon: 'heroicons_outline:chat-alt',
            userouter: '',
            image: '',
            message_type_id: 4, //note
            parent_id: 0,
            labels: `Request Message - Control, ${this._data.controlIdName}`,
            date: date,
            subject: `Message From Assessor - Control, ${this._data.controlIdName}`,
            toUser_id: this.projectPOC.id,
            project_id: this._data.projectGuid,
            control_id: this._data.controlGuid,
            unread: 1,
            important: 1,
            created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            user_id: this.currentUser.id
        }
        this.subs.sink = this._evidenceRequestService.createRequest(msg)
        .subscribe((x:any) => {
            if (x.status === 'success') {
                this.requestSent = true;
                setTimeout(() => {
                    this.close();
                  }, 2000); // 2000 milliseconds = 2 seconds
            }
            else {
                this.requestSent = false;
            }
        });
    }

    private getPrjectPOC(): void {
        this.subs.sink = this._roleService.getProjectPOC(this._data.projectGuid)
        .pipe(
            map((val: any) => val?.data[0] || [])
        )
        .subscribe((arg: any) => {
            if (arg?.length > 0) {
                this.projectPOC = arg[0];
                // this.projectPOCemail = this.projectPOC.email;
            }
            else {
                this._notifyService.showError('','Project POC is missing, Please contact your Manager');
            }
        });
    }

    private setupForm() {
        this.requestForm = this._formBuilder.group({
            note: ['', [Validators.required, Validators.min(1)]],
        });
    }
}

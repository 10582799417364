import { CurrentUser } from "app/core/auth/user";
import { UserService } from "app/core/user/user.service";
import { map } from "rxjs";
import { SubSink } from "subsink";
import { NotificationService } from "../service/notification.service";

export class base_component {

    public currentUser: CurrentUser;

    public subs = new SubSink();

    constructor(
       public _notifyService: NotificationService,
       public _userService: UserService) {
    }

    get isAssessor(): boolean {
        return this.getUserType('Assessor');
    }

    get isSuperUser(): boolean {
        return this.getUserType('Super User');
    }

    get isVendorPoc(): boolean {
        return this.getUserType('Supplier POC');
    }

    get isVendorUser(): boolean {
        return this.getUserType('Supplier User');
    }

    get isProjectManager(): boolean {
        return this.getUserType('Project Manager');
    }

    get isTPRMManager(): boolean {
        return this.getUserType('TPRM Manager');
    }

    get isSecurityManager(): boolean {
        return this.getUserType('Security Manager');
    }

    showToasterSuccess(message:string, title:string):void {
        this._notifyService.showSuccess(message, title);
    }

    showToasterError(message:string, title:string):void {
        this._notifyService.showError(message, title);
    }

    showToasterInfo(message:string, title:string):void {
        this._notifyService.showInfo(message, title);
    }

    showToasterWarning(message:string, title:string):void {
        this._notifyService.showWarning(message, title);
    }

    getUserType(type: string) {
        if (this.currentUser) {
            const isType = this.currentUser?.roles.find((x:any) => x.name === type) ? true : false;
            return isType;
        }
        else {
            return false;
        }
    }

    getCurrentUser(): void {
        this.subs.sink = this._userService.currentUser$
        .pipe(
            map((val: any) => val ?
                (Array.isArray(val) ? val[0] : val) : null)
        )
        .subscribe((x: CurrentUser) => {
            this.currentUser = x;
        });

    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatTableModule } from '@angular/material/table';
import { DisplayDocumentsComponent } from './components/display-documents/display-documents.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { FuseHighlightModule } from '@fuse/components/highlight';
import { SearchFilterPipe } from './filter/search-filter.pipe';
import { OrderBy } from './pipe/order-by.pipe';
import { ProjectStatusComponent } from './components/project-status/project-status.component';
import { SafeHtmlPipe } from './pipe/safehtml.pipe';
import { UploadBlobControlComponent } from './components/upload-blob-control/upload-blob-control.component';
import { UploadLogoControlComponent } from './components/upload-logo-control/upload-logo-control.component';
import { MessagesComponent } from './components/messages/messages.component';
import { RequestMessageComponent } from './components/request-message/request-message.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingDialogComponent } from './components/loading/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from './components/errors/error-dialog/error-dialog.component';
import { RouterModule } from '@angular/router';
import { RequestFormComponent } from './components/request-form/request-form.component';
import { ProjectQuestionsViewComponent } from './components/project-questions-view/project-questions-view.component';
import { ReassignComponent } from './components/reassign/reassign.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TestAudioComponent } from './components/test-audio/test-audio.component';
import { IntervieweePanelComponent } from './components/record-interviewee-question/interviewee-panel.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RecordInterviewComponent } from './components/record-interview/record-interview.component';
import { QuillModule } from 'ngx-quill';
import { ControlAuditInquiryComponent } from './components/control-audit-inquiry/control-audit-inquiry.component';

@NgModule({
    declarations: [
        DisplayDocumentsComponent,
        SearchFilterPipe,
        SafeHtmlPipe,
        OrderBy,
        ProjectStatusComponent,
        UploadBlobControlComponent,
        UploadLogoControlComponent,
        MessagesComponent,
        RequestMessageComponent,
        StarRatingComponent,
        LoadingDialogComponent,
        ErrorDialogComponent,
        RequestFormComponent,
        ProjectQuestionsViewComponent,
        ReassignComponent,
        TestAudioComponent,
        IntervieweePanelComponent,
        RecordInterviewComponent,
        ControlAuditInquiryComponent
    ],
    imports: [
        CommonModule,
        NgxDropzoneModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatSelectModule,
        MatTableModule,
        FuseHighlightModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatSidenavModule,
        FuseAlertModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        RouterModule,
        MatProgressBarModule,
        QuillModule.forRoot()

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DisplayDocumentsComponent,
        UploadBlobControlComponent,
        UploadLogoControlComponent,
        SearchFilterPipe,
        OrderBy,
        MessagesComponent,
        StarRatingComponent,
        ReassignComponent,
        TestAudioComponent,
        IntervieweePanelComponent,
        RecordInterviewComponent,
        ControlAuditInquiryComponent
    ]
})
export class SharedModule
{
}

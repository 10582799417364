import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { User } from 'app/core/user/user.types';
import { v4 as uuid } from 'uuid';
import { Facility } from '../models/facility.types';

@Injectable({
    providedIn: 'root'
})
export class FacilityService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    };

    apiURL = PROJECT_API + 'api/';
    // Private
    private _facility: BehaviorSubject<Facility | null> = new BehaviorSubject(null);
    private _facilities: BehaviorSubject<Facility[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for facility
     */
    get facility$(): Observable<Facility>
    {
        return this._facility.asObservable();
    }

    /**
     * Getter for facilities
     */
    get facilities$(): Observable<Facility[]>
    {
        return this._facilities.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get facilities
     */
     getFacilities(id: string): Observable<any>
     {
         return this._httpClient.get(this.apiURL + `facilities/engagement/${id}`).pipe(
            retry(1),
             map((val: any) => val?.data[0] || []),
             tap((facilities) => {
                 this._facilities.next(facilities);
             })
         );
     }

    /**
     * Search facilities with given query
     *
     * @param query
     */
    searchFacilities(query: string): Observable<Facility[]>
    {
        return this._httpClient.get<Facility[]>(this.apiURL + 'facilities/search', {
            params: {query}
        }).pipe(
            retry(1),
            tap((facilities) => {
                this._facilities.next(facilities);
            })
        );
    }

    /**
     * Get facility by id
     */
    getFacilityById(id: number): Observable<Facility>
    {
        return this._facilities.pipe(
            take(1),
            map((facilities) => {
                if (facilities) {
                    // Find the facility
                    const facility = facilities.find(item => item.id === id) || null;
                    // Update the facility
                    this._facility.next(facility);

                    // Return the facility
                    return facility;
                }
                else {
                    return null;
                }
            }),
            switchMap((facility) => {

                if ( !facility )
                {
                    return throwError('Could not found facility with id of ' + id + '!');
                }

                return of(facility);
            })
        );
    }

    /**
     * Create facility
     */
    createFacility(facility: any): Observable<any>
    {
        const guid: string = uuid();
        facility.guid = guid;
        const data = JSON.stringify(facility);
        return this.facilities$.pipe(
            take(1),
            switchMap(facilities => this._httpClient.post<Facility>(this.apiURL + 'facilities', data, this.httpOptions).pipe(
                retry(1),
                map((newFacility: any) => {
                    // Update the facilities with the new facility
                    //TODO: (REFACTOR): get insertedId from api/mysql so we dont' need to fetch the whole data again.
                    // facility.id = newFacility?.data[0].insertedId;
                    this._facilities.next([facility, ...facilities]);

                    // Return the new facility
                    return newFacility;
                })
            )),
            catchError(this.handleError)
        );
    }

    /**
     * Update facility
     *
     * @param id
     * @param facility
     */
    updateFacility(id: number, facility: Facility): Observable<Facility>
    {
        return this.facilities$.pipe(
            take(1),
            switchMap(facilities => this._httpClient.post<Facility>(this.apiURL + `facilities/${id}`, {
                facility
            }).pipe(
                retry(1),
                map((updatedFacility) => {

                    // Find the index of the updated facility
                    const index = facilities.findIndex(item => item.id === id);

                    // Update the facility
                    facilities[index] = updatedFacility;

                    // Update the facilities
                    // this._facilities.next(facilities);

                    // Return the updated facility
                    return updatedFacility;
                }),
                switchMap(updatedFacility => this.facility$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the facility if it's selected
                        // this._facility.next(updatedFacility);

                        // Return the updated facility
                        return updatedFacility;
                    })
                ))
            ))
        );
    }

    /**
     * Delete the facility
     *
     * @param id
     */
    deleteFacility(id: number): Observable<boolean>
    {
        return this.facilities$.pipe(
            take(1),
            switchMap(facilities => this._httpClient.delete(this.apiURL + `facilities/${id}`).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted facility
                    const index = facilities.findIndex(item => item.id === id);

                    // Delete the facility
                    facilities.splice(index, 1);

                    // Update the facilities
                    this._facilities.next(facilities);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, retry, take, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class AssignInterviewerService
{
    apiURL = PROJECT_API + 'api/';
    // Private
    private _assigninterviewee: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _assigninterviewees: BehaviorSubject<any | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for assigninterviewee
     */
    get assigninterviewee$(): Observable<any>
    {
        return this._assigninterviewee.asObservable();
    }

    /**
     * Getter for assigninterviewee
     */
    get assigninterviewees$(): Observable<any>
    {
        return this._assigninterviewee.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getAssignIntervieweeProjects(email: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `assigninterviewers/email/${email}` ).pipe(
            map((assigninterviewee) => {
                this._assigninterviewee.next(assigninterviewee);
                // Return the course
                return assigninterviewee;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagements');
                }
                return of(engagement);
            })
        );
    }

    getAssignInterviewee(projectId: string, email: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `assigninterviewers/project/${projectId}/email/${email}` ).pipe(
            retry(1),
            map((assigninterviewee) => {
                this._assigninterviewee.next(assigninterviewee);
                // Return the course
                return assigninterviewee;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagement with id of ' + projectId + '!');
                }
                return of(engagement);
            })
        );
    }

    /**
     * Get assigninterviewee by id
     */
    getAssignIntervieweeList(projectId: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + 'assigninterviewers/project/' + projectId ).pipe(
            retry(1),
            map((assigninterviewees) => {
                this._assigninterviewees.next(assigninterviewees);
                // Return the course
                return assigninterviewees;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagement with id of ' + projectId + '!');
                }
                return of(engagement);
            })
        );
    }

    /**
     * Create assigninterviewee
     */
    createAssignIntervieweeList(data: any): Observable<any>
    {
        return this._httpClient.post(this.apiURL + 'assigninterviewers', data)
        .pipe(
            tap((result: any) => {
                // this._assigninterviewee.next(result);
            }),
            catchError(this.handleError)
        );
    }

    createExistingInterviewee(data: any): Observable<any>
    {
        return this._httpClient.post(this.apiURL + 'assigninterviewers/exists', data)
        .pipe(
            tap((result: any) => {
                // this._assigninterviewee.next(result);
            }),
            catchError(this.handleError)
        );
    }

    /**
     * Update assigninterviewee
     *
     * @param id
     * @param assigninterviewee
     */
    updateAssignInterviewList(id: string, assigninterviewee: any): Observable<any>
    {
        return this._httpClient.patch<any>(this.apiURL + `assigninterviewers/${id}`, assigninterviewee).pipe(
            tap((result: any) => {
                // Update the assigninterviewers
                this._assigninterviewee.next(result);
            })
        );
    }

    /**
     * Delete the assigninterviewee
     *
     * @param id
     */
    deleteAssignInterviewList(id: string, projectId: string): Observable<boolean>
    {
        return this._httpClient.delete(this.apiURL + `assigninterviewers/${id}/${projectId}`)
        .pipe(
            tap((result: any) => {
                // Update the assigninterviewers
                this._assigninterviewee.next(result);
            })
        );
    }

    deleteAssignInterviewFromProject(id: string, projectId: string): Observable<boolean>
    {
        return this._httpClient.delete(this.apiURL + `assigninterviewers/deletefromproject/${id}/${projectId}`)
        .pipe(
            tap((result: any) => {
                // Update the assigninterviewers
                this._assigninterviewee.next(result);
            })
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}


class userTypeList {
    public name: string = '';
    public id: number = 0;
}

class projectType {
    public name: string = '';
    public id: number = 0;
}

export class fileObject {
    constructor(fileName:any, fileType:any, fileSource:any) {
        this.fileName = fileName;
        this.fileType = fileType;
        this.fileSource = fileSource;
    }
    public fileName: string;
    public fileType: string;
    public fileSource: string;
}

export const userTypes = {
    superUser: {id: 1, name: "Super User"},
    projectUser: {id: 2, name: "Project User"},
    projectManager: {id: 3, name: "Project Manager"},
    supplierPOC: {id: 4, name: "Supplier POC"},
    projectPOC: {id: 5, name: "Project POC"},
    assessor: {id: 6, name: "Assessor"},
    qa: {id: 7, name: "QA"},
    peerReviewer: {id: 8, name: "Peer Reviewer"},
    interviewee: {id: 9, name: "Interviewee"},
    supplierUser: {id: 10, name: "Supplier User"},
    tprmManager: {id: 11, name: "TPRM Manager"},
    securityManager: {id: 12, name: "Security Manager"},


} as const;

export const projectTypes = {
    standard: {id: 1, name: "Standard"},
    tprm: {id: 2, name: "TPRM"},
    custom: {id: 3, name: "Custom"},
} as const;

export const assesmentType = {
    gap: {id: 1, name: 'GAP'},
    risk: {id: 2, name: 'RISK'},
    risk_cmmc: {id: 3, name: 'RISK + CMMC'}
} as const;

export const tprmTiers = [
    {id: 1, name: 'Tier 1'},
    {id: 2, name: 'Tier 2'},
    {id: 3, name: 'Tier 3'}
];

export const contractLengths = [
    {id: '1', name: '1 Year'},
    {id: '2', name: '2 Years'},
    {id: '3', name: '3 Years'},
];

export const scheduledList = [
    {id: '1', name: 'Annual'},
    {id: '2', name: 'Biannual'},
    {id: '3', name: 'Quarterly'},
];

// export const sensitiveDataTypes = [
//     {id: 1, name:'N/A'},
//     {id: 2, name:'PCI'},
//     {id: 3, name:'HIPAA'},
//     {id: 4, name:'PII'},
//     {id: 5, name:'GDPR'}
// ];

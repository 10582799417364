<div class="inset-0 flex flex-col min-w-0 overflow-hidden">
    <!-- Header -->
    <div class="relative w-full px-8 sm:px-12 bg-accent-100 dark:bg-accent-700">

        <!-- Close button -->
        <div class="flex items-center justify-end">
            <!-- <button
                mat-icon-button (click)="closeDrawer()"
                [routerLink]="['../../']">
                <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button> -->
        </div>
        <!-- title -->
        <div class="flex justify-center w-full max-w-3xl mx-auto">
            <div class="mt-2 text-2xl font-bold truncate justify-center">
                Request TRPM Evidences or Send a message
            </div>
        </div>
    </div>
    <div class="relative flex flex-col flex-auto items-center  p-6 md:p-8">
       <form class="flex flex-col md:w-140 flex-auto p-4 pt-4 sm:p-4 sm:pt-4 mr-5" [formGroup]="requestForm">
            <div class="w-full max-w-3xl">
                <div class="mt-5">
                    <mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full">
                        <mat-label>Message</mat-label>
                        <textarea rows="10" matInput [formControlName]="'note'" [spellcheck]="true" matTextareaAutosize></textarea>
                    </mat-form-field>
                </div>
                <!-- Actions -->
                <div class="flex justify-center mt-10 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t ">
                    <!-- Cancel -->
                    <button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="close()">
                        Cancel
                    </button>
                    <!-- Save -->
                    <button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="requestForm.invalid" [matTooltip]="'Save'" (click)="submit()">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

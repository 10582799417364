
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { IApprovals } from '../model/approval.model';

@Injectable({
    providedIn: 'root'
})
export class ApprovalService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private approval: BehaviorSubject<IApprovals | null> = new BehaviorSubject<IApprovals | null>(null);
    private approvals: BehaviorSubject<IApprovals[] | null> = new BehaviorSubject<IApprovals[] | null>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for workflows
     */
     get approvals$(): Observable<IApprovals[] | null>
     {
         return this.approvals.asObservable();
     }


    /**
     * Getter for course
     */
     get approval$(): Observable<IApprovals |null>
     {
         return this.approval.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    getCreateApprovals(id: string): Observable<any[]>
    {
        return this.http.get<any[]>(this.apiURL + 'approvals/engagement/create/' + id ).pipe(
            tap((response: any) => {
                this.approvals.next(response);
            })
        );
    }

    getApprovals(id: string): Observable<any[]>
    {
        return this.http.get<any[]>(this.apiURL + 'approvals/engagement/' + id ).pipe(
            tap((response: any) => {
                this.approvals.next(response);
            })
        );
    }

    /**
     * Get course by id
     */
    getApprovalById(id: string): Observable<IApprovals | null>
    {
        return this.http.get<IApprovals>(this.apiURL + 'approvals/' + id ).pipe(
            map((workflow:any) => {
                if (workflow) {
                    // Update the workflow
                    this.approval.next(workflow[0]);
                    // Return the workflow
                    return workflow[0];
                }
                this.approval.next(null);
                // Return the workflow
                return null;
            }),
            switchMap((workflow) => {

                if ( !workflow )
                {
                    return throwError('Could not found workflow with id of ' + id + '!');
                }

                return of(workflow);
            })
        );
    }

    finalizeApprovals(approvals:any): Observable<any> {
        return this.http.post<IApprovals>(this.apiURL + 'approvals/finalize', JSON.stringify(approvals), this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    // HttpClient API post() method => Create approvals
    createApprovals(approvals:any): Observable<any> {
        return this.http.post<IApprovals>(this.apiURL + 'approvals', JSON.stringify(approvals), this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    // HttpClient API put() method => Update approvals
    updateApprovals(id:any, user_type_id:any, approvals:any): Observable<any> {
        return this.http.put<IApprovals>(this.apiURL + 'approvals/' + id, JSON.stringify({user_type_id, approvals}), this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    // HttpClient API delete() method => Delete approvals
    deleteApprovals(id:any): Observable<any>{
        return this.http.delete<IApprovals>(this.apiURL + 'approvals/' + id, this.httpOptions)
        .pipe(
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

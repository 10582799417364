import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { delay, merge, ReplaySubject, Subject, Subscription, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseUtilsService } from '@fuse/services/utils/utils.service'; 
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar/scrollbar.directive';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
    selector       : 'fuse-horizontal-navigation',
    templateUrl    : './horizontal.component.html',
    styleUrls      : ['./horizontal.component.scss'],
    animations     : fuseAnimations,
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'fuseHorizontalNavigation'
})
export class FuseHorizontalNavigationComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() name: string = this._fuseUtilsService.randomId();
    @Input() navigation: FuseNavigationItem[];
    @ViewChild('navigationContent') private _navigationContentEl: ElementRef;
    
    onCollapsableItemCollapsed: ReplaySubject<FuseNavigationItem> = new ReplaySubject<FuseNavigationItem>(1);
    onCollapsableItemExpanded: ReplaySubject<FuseNavigationItem> = new ReplaySubject<FuseNavigationItem>(1);
    onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _fuseScrollbarDirectives!: QueryList<FuseScrollbarDirective>;
    private _fuseScrollbarDirectivesSubscription: Subscription;
    
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _scrollStrategyOptions: ScrollStrategyOptions,
        private _fuseUtilsService: FuseUtilsService
    )
    {
    }

     /**
     * Setter for fuseScrollbarDirectives
     */
      @ViewChildren(FuseScrollbarDirective)
      set fuseScrollbarDirectives(fuseScrollbarDirectives: QueryList<FuseScrollbarDirective>)
      {
          // Store the directives
          this._fuseScrollbarDirectives = fuseScrollbarDirectives;
  
          // Return if there are no directives
          if ( fuseScrollbarDirectives.length === 0 )
          {
              return;
          }
  
          // Unsubscribe the previous subscriptions
          if ( this._fuseScrollbarDirectivesSubscription )
          {
              this._fuseScrollbarDirectivesSubscription.unsubscribe();
          }
  
          // Update the scrollbars on collapsable items' collapse/expand
          this._fuseScrollbarDirectivesSubscription =
              merge(
                  this.onCollapsableItemCollapsed,
                  this.onCollapsableItemExpanded
              )
                  .pipe(
                      takeUntil(this._unsubscribeAll),
                      delay(250)
                  )
                  .subscribe(() => {
  
                      // Loop through the scrollbars and update them
                      fuseScrollbarDirectives.forEach((fuseScrollbarDirective) => {
                          fuseScrollbarDirective.update();
                      });
                  });
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Navigation
        if ( 'navigation' in changes )
        {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Make sure the name input is not an empty string
        if ( this.name === '' )
        {
            this.name = this._fuseUtilsService.randomId();
        }

        // Register the navigation component
        this._fuseNavigationService.registerComponent(this.name, this);
    }

    
    /**
     * After view init
     */
     ngAfterViewInit(): void
     {
         setTimeout(() => {
 
             // Return if 'navigation content' element does not exist
             if ( !this._navigationContentEl )
             {
                 return;
             }
 
             // If 'navigation content' element doesn't have
             // perfect scrollbar activated on it...
             if ( !this._navigationContentEl.nativeElement.classList.contains('ps') )
             {
                 // Find the active item
                 const activeItem = this._navigationContentEl.nativeElement.querySelector('.fuse-vertical-navigation-item-active');
 
                 // If the active item exists, scroll it into view
                 if ( activeItem )
                 {
                     activeItem.scrollIntoView();
                 }
             }
             // Otherwise
             else
             {
                 // Go through all the scrollbar directives
                 this._fuseScrollbarDirectives.forEach((fuseScrollbarDirective) => {
 
                     // Skip if not enabled
                     if ( !fuseScrollbarDirective.isEnabled() )
                     {
                         return;
                     }
 
                     // Scroll to the active element
                     fuseScrollbarDirective.scrollToElement('.fuse-vertical-navigation-item-active', -120, true);
                 });
             }
         });
     }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Deregister the navigation component from the registry
        this._fuseNavigationService.deregisterComponent(this.name);

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh the component to apply the changes
     */
    refresh(): void
    {
        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Execute the observable
        this.onRefreshed.next(true);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}


import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { Engagement, Task } from 'app/shared/model/engagement.types';
import { HelperService } from 'app/shared/service/helper.service';
import { DocumentService } from 'app/shared/service/document.service';
import { fuseAnimations } from '@fuse/animations';
import { base_component } from 'app/shared/components/base_component';
import { UserService } from 'app/core/user/user.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { v4 as uuid, validate } from 'uuid';
import { map, mergeMap, tap } from 'rxjs';
import { CurrentUser } from 'app/core/auth/user';
import { InternalMessageService } from 'app/layout/layout.service';
import { FuseAlertType } from '@fuse/components/alert';
import { ProjectUserService } from 'app/shared/service/project-user.service';
import { UserTypesService } from 'app/shared/service/user-types.service';
import { ApprovalService } from 'app/shared/service/approval.service';
import { MatDialogRef } from '@angular/material/dialog';
import { userTypes } from 'app/shared/model/user-type-list';
import { ApprovalQueueService } from 'app/shared/service/approval_queue.service';
import { FileUploaderServiceService } from 'app/shared/service/file-uploader-service.service';
import { Member } from 'app/shared/model/user.types';

@Component({
    selector: 'app-upload-blob-control',
    templateUrl: './upload-blob-control.component.html',
    styleUrls: ['./upload-blob-control.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations : fuseAnimations
})
export class UploadBlobControlComponent extends base_component implements OnInit, OnDestroy
{
    @Input() drawer: MatDrawer;
    @Input() controlName: string;
    @Input() companyId: string;
    @Input() projectId: string;
    @Input() controlId: string;
    // @Input() showUploadedFiles: boolean = true;
    @Input() showFinalButton: boolean;
    @Input() showFlow: boolean;
    @Input() showNote: boolean;// = true;
    @Input() isAuditor: boolean;// = true;
    @Input() showClose: boolean;
    @Input() expandNote: boolean;
    @Input() docType: string = 'evidences';

    // control: Control;
    showDocComment: boolean = false;
    approvalQueueList: any[] = [];
    approvalWithAssessorList: any[] = [];
    currentDoc: string = '';
    projectAuditorId: number = 0;
    engagement: Engagement;
    uploadForm: FormGroup;
    evidenceDocuments: any[] = [];
    evidenceList: any[] = [];
    documents: any[] = [];
    ManagerAndPoc: any[] = [];
    files: File[] = [];
    evidences: any[] = [];
    approvalList: any[] = [];
    members: any[] = [];
    reportFlow: string = '';
    currentQueue:any = {role: 'Assessor'};
    nextQueueUser: any;
    assessor: any;
    canSubmit: boolean = false;
    submitting: boolean = false;
    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
      };

    /**
     * Constructor
     */
    constructor(
        public _userService: UserService,
        public _notifyService: NotificationService,
        public _matDialogRef: MatDialogRef<UploadBlobControlComponent>,
        private _formBuilder: FormBuilder,
        private _docService: DocumentService,
        private _helper: HelperService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _projectUserService: ProjectUserService,
        private _userTypesService: UserTypesService,
        private _approvalService: ApprovalService,
        private _approvalQueueService: ApprovalQueueService,
        private _fileUploadService: FileUploaderServiceService,
        private _internalMessageService: InternalMessageService,
    )
    {
        super(_notifyService, _userService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ GET methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        if (!this.isAuditor) {
            this.isAuditor = true;
        }
        if (!this.showNote) {
            this.showNote = true;
        }

        this.setUpForm();
        this.getCurrentUser();
        this.getMembers(this.projectId);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    async onSelect(event:any): Promise<void> {
        this.files.push(...event.addedFiles);
    }

    onRemove(event:any): void {
        this.files.splice(this.files.indexOf(event), 1);
    }

    getNextUserAndSubmit(): void {
        this.submitAll();
    }

    /*
    1.if user is not auditor then report gets send back to auditor unless marked as final
    2.if final then it goes to project poc
    3.last in queue shows up in their inbox/report page
    */
    submitAll(): void {
        this.submitting = true;
        if ( this.uploadForm.invalid || this.files.length === 0) {
            this._notifyService.showWarning('','Please select a file to upload');
            this.submitting = false;
            return;
        }
        if ( this.docType === 'reports' && this.files.length > 1) {
            this._notifyService.showWarning('','Please select only one report to upload.');
            this.submitting = false;
            return;
        }
        //if report then upload only one file
        if (this.docType === 'reports') {
            this.uploadFile(this.files[0], 0);
        }
        else {
            this.files.forEach((file,index) => {
                this.uploadFile(file, index);
            });
        }
    }

    //1. get presigned url
    uploadFile(file:File, index:number) {
        const fileName = file.name;

        //1. upload file
        // const extArray = file.type.split("/");
        // const extension = extArray[extArray.length - 1];
        const guid = uuid();
        const key = `${this.currentUser.db_name}/projects/${this.projectId}/${this.docType}/${guid}_${fileName}`;
        this._fileUploadService.uploadToS3(file, key)
        .then((response:any) => {
            this.uploadFileData(fileName, file.type, file.size, key, index);
        }).catch((response:any) => {
            this._notifyService.showError('', 'Failed to upload the file.');
            this.submitting = false;
        })
    }

    uploadFileData(fileName:string, fileType:string, fileSize:number, key:string, index:number): void {
        this.alert.message = '';

        if (this.projectId) {
            const formData = this.uploadForm.getRawValue();
            const guid = uuid();
            let data:any = {};
            data.guid = guid,
            data.doc_type = this.docType,
            data.file_name = fileName,
            data.file_type = fileType,
            data.file_size = fileSize,
            data.user_id = this.currentUser.id,
            data.project_guid = this.projectId,
            data.note = formData.note,
            data.control_guid = this.controlId || '';
            data.company_id = this.companyId || '';
            data.whisper = '1';
            data.campaign ='';
            data.final = formData.final || 0;
            data.interaction_id = '1';
            data.fileName = fileName;
            data.contentType = fileType;
            data.key = key;
            data.is_auditor = this.isAuditor ? '1' : '0';
            data.created_by = `${this.currentUser.first_name} ${this.currentUser.last_name}`;

            if (this.docType === 'reports') {
                if (data.final) {
                    //if final then send it to manager
                    const managerContact = this.members.find((x:any) => x.role == "Project Manager");
                    if (managerContact) {
                        data.emailCreatorName = `${managerContact.first_name} ${managerContact.last_name}`;
                        data.emailCreatorEmail = managerContact.email;
                        data.emailCreatorPhone = managerContact.phone;
                    }
                }
                //if queue is empty then this is first upload so get the first from approval
                const projectRoles = this.currentUser.project_and_role?.split(',');
                const projectRole = projectRoles.filter((x:any) => x.split(':')[0] = this.projectId);
                const role = projectRole.find((x:any) => x.split(':')[0] === this.projectId);
                const roleId = role.split(':')[1];
                const currentApproval = this.approvalList.find((x:any) => x.user_type_id == roleId);
                data.approval_id = currentApproval?.id;
                console.log("* ~ uploadFileData ~ this.approvalList:", this.approvalList)
                console.log("0 ~ uploadFileData ~formData.member_id:", formData.member_id )
                console.log("1 ~ uploadFileData ~ this.members:", this.members)
                const sendReportTo = this.members.find((x:any) => x.id == formData.member_id);
                console.log("2 ~ uploadFileData ~ sendReportTo:", sendReportTo)
                data.email = sendReportTo?.email;
                data.next_user_type_id = sendReportTo?.role_type_id;
                data.next_user_id = sendReportTo?.user_id;
            }
             console.log("3 ~ uploadFileData ~ formData:", data)

            this.subs.sink = this._docService.uploadFileData(data, 'evidences/filedata')
            .subscribe((response:any) => {
                if (response.status === "success") {
                    this._notifyService.showSuccess('', `Successfully uploaded the ${fileName}.`);
                    this.uploadForm.reset();
                    this._internalMessageService.sendMessage({name:'upload', data: {success: true} });
                    this.alert.type = 'success';
                    this.alert.message = 'File uploaded successfully';
                    if (this.docType === 'reports') {
                        this.alert.message = 'Report uploaded successfully';
                    }
                    this.files.splice(index, 1);
                }
                else {
                    this._notifyService.showError('', 'Failed to upload the file.');
                    this.alert.type = 'error';
                    this.alert.message = 'Failed to upload the file.';
                }
                this.submitting = false;
            });
        }
        else {
            this.alert.type = 'warning';
            this.alert.message = 'Failed to upload the file.';
            this.submitting = false;
            this._notifyService.showWarning('', 'Project Info is missing, unable to submit the file.');
        }
    }

    discard(): void
    {
        this._matDialogRef.close();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private finalButton() {
        if (this.docType === 'reports' && this.isProjectManager) {
            this.showFinalButton = true;
        }
    }
    private setUpForm() {
        if (this.docType === 'reports') {
            this.uploadForm = this._formBuilder.group({
                note: ['', []],
                member_id: ['', [Validators.required]],
                email: [''],
                final: [0]
            });
        }
        else {
            this.uploadForm = this._formBuilder.group({
                note: ['', []],
                member_id: [''],
                email: [''],
                final: [0]
            });
        }
    }

    private getMembers(projectId: string): void {
        this.subs.sink = this._projectUserService.getProjectUserByProjectId(projectId)
            .pipe(
                map((val: any) => val ? val.data[0] : []),
                tap((members: any[]) => {
                    if (this.isAuditor) {
                        this.members = members.filter((x:Member) => x.role_type_id != userTypes.assessor.id); //remove auditor
                    }
                    this.members = members.filter((x:Member) => x.role_type_id != userTypes.interviewee.id); //remove interviewee
                    // this.members = this.members.map((x:Member) => {
                    //     return {
                    //         ...x, roles: this._helper.removeDups(x.roles)
                    //     }
                    // });
                }),
                //add project poc and manager if exist in approval list
                mergeMap((x:any) => this._approvalQueueService.getApprovalQueueByProjectId(projectId)),
                tap((list: any) => {
                    this.approvalQueueList = list;
                }),
                mergeMap((x:any) => this._approvalService.getCreateApprovals(projectId)),
                map((val: any) => val ? val.data[0] : []),
                tap((list: any[]) => {
                    this.approvalList = list.filter((x:any) => x.included === 1).sort();
                    const currentApproval = this.approvalList[this.approvalList.length - 1];
                    this.approvalList.forEach((x,i) => {
                        this.reportFlow += ` > ${x.name}`;
                    });
                }),
                mergeMap((x:any) => this._userTypesService.getUserTypesByUserId(this.projectId , userTypes.projectManager.id)),
                map((val: any) => val ? val.data[0] : []),
                tap((members: any[]) => {
                    this.members = [...this.members, ...members];
                }),
                mergeMap((x:any) => this._userTypesService.getUserTypesByUserId(this.projectId , userTypes.projectPOC.id)),
                map((val: any) => val ? val.data[0] : []),
                tap((members: any[]) => {
                    this.members = [...this.members, ...members];
                }),
                mergeMap((x:any) => this._userTypesService.getUserTypesByUserId(this.projectId , userTypes.assessor.id)),
                map((val: any) => val ? val.data[0] : [])
            )
            .subscribe((assessor: any) => {
                this.assessor = assessor;
                this.members = this._helper.removeDuplicateObjectByProperty(this.members, 'role');

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    // private loadEvidences(): void {
    //     this.subs.sink = this._docService.
    //     .pipe(
    //         // map((val: any) => val?.data[0] || [])
    //     )
    //     .subscribe((docs: any) => {
    //         if (docs.length > 0) {
    //             this.evidences = docs;
    //             // Mark for check
    //             this._changeDetectorRef.markForCheck();
    //         }
    //     });
    // }

    getCurrentUser(): void {
        this.subs.sink = this._userService.currentUser$
        .pipe(
            map((val: any) => val ?
                (Array.isArray(val) ? val[0] : val) : null)
        )
        .subscribe((x: CurrentUser) => {
            this.currentUser = x;
            this.finalButton();
            this._changeDetectorRef.markForCheck();
        });
    }
}



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, mergeMap, tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';
import { SubSink } from 'subsink';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { CurrentUser } from '../auth/user';
import { UserService } from '../user/user.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private currentUser: CurrentUser = null;
    private subs = new SubSink();

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient
        )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        const nav: Navigation = {} as Navigation;
        nav.default = nav.compact = nav.futuristic = nav.horizontal = this.buildNav();
        return of(nav).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    buildNav(): FuseNavigationItem[] {
        const defaultNav: FuseNavigationItem[] = [];
        const account = {id : 'apps.account',title: 'Account',type : 'basic',icon : 'heroicons_outline:user-circle',link : '/my'};
        //access: auditor, manager
        const inbox = {id : 'apps.inbox',title: 'Inbox',type : 'basic',icon : 'heroicons_outline:inbox',link : '/messages/inbox/all/1',children: [],
            badge: {
                title  : '0',
                classes: 'px-2 bg-pink-600 text-white rounded-full'
            }
        };
        // const app = {id: 'apps.access',title : 'Quick Access', subtitle: '', type  : 'group',icon  : 'heroicons_outline:home',children: []};
        // app.children.push({
        //     id  : 'help-center',
        //     title : 'Help Center',
        //     type  : 'collapsable',
        //     icon  : 'heroicons_outline:support',
        //     link  : '/help-center',
        //     children: [
        //         { id : 'help-center.home', title : 'Home', type  : 'basic', link  : '/help-center', exactMatch: true },
        //         { id : 'help-center.faqs', title: 'FAQs', type : 'basic', link : '/help-center/faqs' },
        //         { id : 'help-center.guides', title: 'Guides', type : 'basic',  link : '/help-center/guides' },
        //         { id : 'help-center.support', title: 'Support', type : 'basic', link : '/help-center/support' }
        //     ]
        // });

        // load projects
        // defaultNav.push(projects as FuseNavigationItem);
        // defaultNav.push(dashboard as FuseNavigationItem);
        defaultNav.push({} as FuseNavigationItem);
        defaultNav.push(account as FuseNavigationItem);
        // defaultNav.push(tprm as FuseNavigationItem);
        defaultNav.push(inbox as FuseNavigationItem);
        // defaultNav.push(app as FuseNavigationItem);

        return defaultNav;
    }

}

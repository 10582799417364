
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import Axios from  'axios-observable';
import { AuthService } from 'app/core/auth/auth.service';
import { ProjectUserService } from 'app/shared/service/project-user.service';

@Injectable({
    providedIn: 'root'
})
export class MediaService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';

    private _media: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _medias: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private _mediaQuestions: BehaviorSubject<any[] | null> = new BehaviorSubject(null);


    /**
     * Constructor
     */
     constructor(private http: HttpClient,
        private _authService: AuthService,
        private _projectUserService: ProjectUserService) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for userengagements
     */
     get media$(): Observable<any>
     {
         return this._media.asObservable();
     }

    /**
     * Getter for course
     */
     get medias$(): Observable<any[]>
     {
         return this._medias.asObservable();
     }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getAll(projectid: string): Observable<any>
    {
        return this.http.get<any[]>(this.apiURL + `medias/${projectid}`).pipe(
            retry(1),
            tap((control: any) => {
                this._medias.next(control);
                return control[0];
            })
        );
    }

    downloadFile(guid: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/download/${guid}` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    getLastSavedQuestion(projectId: string, roleId: number): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/project/${projectId}/role/${roleId}/saved` ).pipe(
            retry(1),
            map((result) => {
                // Update the course
                // this._medias.next(result);

                // Return the course
                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + projectId + '!');
                }

                return of(control);
            })
        );
    }

    /**
     * Get media by project and role
     * But not question
     */
    getMediaByProjectAndRole(projectId: string, roleId: number, questionId:number): Observable<any>
    {
        const newQId = questionId || 1;
        return this.http.get<any>(this.apiURL + `medias/project/${projectId}/role/${roleId}/question/${newQId}` ).pipe(
            retry(1),
            map((result) => {
                this._medias.next(result);

                // Return the course
                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + projectId + '!');
                }

                return of(control);
            })
        );
    }

    getMediaByProjectAndRoleByControlGuid(projectId: string, roleId: number, controlGuid:string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/project/${projectId}/role/${roleId}/question/control/${controlGuid}` ).pipe(
            retry(1),
            map((result) => {
                // Update the course
                this._medias.next(result);

                // Return the course
                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + projectId + '!');
                }

                return of(control);
            })
        );
    }

    getMediaByProject(projectId: string, controlId:string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/project/${projectId}/control/${controlId}` ).pipe(
            retry(1),
            map((result) => {
                this._medias.next(result);

                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + projectId + '!');
                }

                return of(control);
            })
        );
    }

    /**
     * Get media by project and role
     */
     getMediaByProjectAndControl(projectid: string, controlId: string): Observable<any>
     {
         return this.http.get<any>(this.apiURL + `medias/project/${projectid}/control/${controlId}` ).pipe(
            retry(1),
             map((result) => {
                 this._medias.next(result);

                 // Return the course
                 return result;
             }),
             switchMap((control) => {

                 if ( !control )
                 {
                     return throwError('Could not found project with id of ' + projectid + '!');
                 }

                 return of(control);
             })
         );
     }

    // getMediaControlByStandard(standardid: number, projectid:string): Observable<any>
    // {
    //     //console.error('getMediaQuestionByStandardAndRole >>>>>>>>>>>>>>>>', standardid, projectid);
    //     return this.http.get<any>(this.apiURL + `medias/questions/standard/${standardid}/project/${projectid}` ).pipe(
    //         retry(1),
    //         map((val: any) => val.data ? val.data[0] : []),
    //         map((result) => {
    //             console.log('result --------------', result);
    //             // Update the course
    //             this._mediaQuestions.next(result);

    //             // Return the course
    //             return result;
    //         }),
    //         switchMap((control) => {

    //             if ( !control )
    //             {
    //                 return throwError('Could not found project with id of ' + standardid + '!');
    //             }

    //             return of(control);
    //         })
    //     );
    // }


    getMediaQuestionByStandardAndRole(standardid: number, roleid: number, projectid:string): Observable<any>
    {
        // console.error('getMediaQuestionByStandardAndRole >>>>>>>>>>>>>>>>', standardid, roleid, projectid);
        return this.http.get<any>(this.apiURL + `medias/questions/standard/${standardid}/role/${roleid}/project/${projectid}` ).pipe(
            map((val: any) => val.data ? val.data[0] : []),
            map((result) => {
                this._mediaQuestions.next(result);

                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + standardid + '!');
                }

                return of(control);
            })
        );
    }

    getMediaQuestionByStandardAndRoleCampaign(standardid: number, roleid: number, projectid:string, groupId: string): Observable<any>
    {
        // console.error('getMediaQuestionByStandardAndRoleCampaign >>>>>>>>>>>>>>>>', standardid, roleid, projectid), groupId;
        return this.http.get<any>(this.apiURL + `medias/questions/standard/${standardid}/role/${roleid}/project/${projectid}/group/${groupId}` ).pipe(
            map((val: any) => val.data ? val.data[0] : []),
            map((result) => {
                this._mediaQuestions.next(result);

                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + standardid + '!');
                }

                return of(control);
            })
        );
    }

    getMediaTPRMQuestionByStandardAndRole(projectId: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/questions/tprm/${projectId}` ).pipe(
            retry(1),
            map((val: any) => val.data ? val.data[0] : []),
            map((result) => {
                // Update the course
                this._mediaQuestions.next(result);

                // Return the course
                return result;
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found project with id of ' + projectId + '!');
                }

                return of(control);
            })
        );
    }

    getMediaTPRMUserQuestionByProject(projectId: string, userId:number): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/questions/tprm/project/${projectId}/${userId}` ).pipe(
            retry(1),
            map((val: any) => val.data ? val.data[0] : []),
            map((result) => {
                // Update the course
                this._mediaQuestions.next(result);

                // Return the course
                return result;
            }),
            switchMap((control) => {
                if ( !control )
                {
                    return throwError('Could not found project with id of ' + projectId + '!');
                }

                return of(control);
            })
        );
    }

    /**
     * Get media by id
     */
    getById(id: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `medias/${id}` ).pipe(
            retry(1),
            map((control) => {
                this._medias.next(control[0]);

                // Return the course
                return control[0];
            }),
            switchMap((control) => {

                if ( !control )
                {
                    return throwError('Could not found control with id of ' + id + '!');
                }

                return of(control);
            })
        );
    }

    reRunAudio(data:any): Observable<any> {
        return this.http.post<any>(this.apiURL + 'medias/rerunaudio', data)
        .pipe(
            catchError(this.handleError)
        );
    }

    saveWritten(data:any): Observable<any> {
        return this.http.post<any>(this.apiURL + 'medias/savewritten', data)
        .pipe(
            catchError(this.handleError)
        );
    }

    uploadFileData(data: any, uri: string = 'medias/filedata'): Observable<any> {
        return this.http.post(`${this.apiURL}${uri}`, JSON.stringify(data), this.httpOptions).pipe(
            tap((response: any) => {
                //  this._document.next(response);
            })
        );
    }

    // uploadFile(formData: any): Observable<any> {
    //     return Axios.post(`${this.apiURL}medias`, formData, {
    //         headers: {
    //             'Authorization': this._authService.accessToken,
    //             'Content-Type': 'multipart/form-data',
    //             'domain': this._projectUserService.company
    //         }
    //     })
    //     .pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             // this._evidences.next(response);
    //         })
    //     );
    // }

    // HttpClient API put() method => Update assign
    update(id:any, assign:any): Observable<any> {
        return this.http.put<any>(this.apiURL + 'medias/' + id, JSON.stringify(assign), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API delete() method => Delete assign
    delete(guid:string): Observable<any>{
        return this.http.delete<any>(this.apiURL + 'medias/' + guid, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

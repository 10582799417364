import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { IRiskGroup } from '../models/risk.model';

@Injectable({
    providedIn: 'root'
})
export class RiskService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _risk: BehaviorSubject<IRiskGroup | null> = new BehaviorSubject(null);
    // private _pagination: BehaviorSubject<RiskGroupPagination | null> = new BehaviorSubject(null);
    private _risks: BehaviorSubject<IRiskGroup[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for risks
     */
     get riskGroups$(): Observable<IRiskGroup[]>
     {
         return this._risks.asObservable();
     }

    /**
     * Getter for course
     */
     get riskGroup$(): Observable<IRiskGroup>
     {
         return this._risk.asObservable();
     }

    /**
     * Getter for pagination
     */
    // get pagination$(): Observable<RiskGroupPagination>
    // {
    //     return this._pagination.asObservable();
    // }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get categories
     */
    getRiskRecommendations(customerId: any): Observable<any[]>
    {
        return this.http.get<any[]>(`${this.apiURL}risklibraries/customer/${customerId}`).pipe(
            retry(1),
            map((val: any) => val ? val.data[0] : []),
            tap((response: any) => {
                console.info('getRiskRecommendations >>2:', response);
            })
        );
    }

    deleteLibrary(id: number): Observable<any[]>
    {
        return this.http.get<any[]>(`${this.apiURL}risklibraries/${id}`).pipe(
            retry(1),
            tap((response: any) => {
                console.info('deleteLibrary >>2:', response);
            })
        );
    }

    searchRiskRecommendations(customerId: any, search: string): Observable<any[]>
    {
        return this.http.post<any>(`${this.apiURL}risklibraries/search/recommendations`, {customerId, search}, this.httpOptions)
        .pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    getAllRisks(projectId: string)
    {
        return this.http.get(`${this.apiURL}risks/all/project/${projectId}`)
        .pipe(
            retry(1),
            map((val: any) => val?.data[0] || [])
        );
    }

    /**
     * Get risks
     *
     *
     * @param page
     * @param size
     * @param sort
     * @param order
     * @param search
     */
    //  getRisks(projectId: string, page: number = 0, size: number = 10, sort: string = 'id', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
    //     Observable<{ pagination: RiskGroupPagination; risks: IRiskGroup[] }>
    // {
    //     return this.http.get<{ pagination: RiskGroupPagination; risks: IRiskGroup[] }>(`${this.apiURL}risks/project/${projectId}`, {
    //         params: {
    //             page: '' + page,
    //             size: '' + size,
    //             sort,
    //             order,
    //             search
    //         }
    //     })
    //     .pipe(
    //         map((val: any) => ({data: val.data[0] || [], pagination: val.pagination})),
    //         tap((response: any) => {
    //             this._pagination.next(response.pagination);
    //             this._risks.next(response.data);
    //             // this._risks.next(response);
    //         })
    //     );
    // }
    getRisks(projectId: string, page: number = 0, size: number = 10, sort: string = 'id', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
    Observable<{ risks: IRiskGroup[] }>
    {
        return this.http.get<{ risks: IRiskGroup[] }>(`${this.apiURL}risks/project/${projectId}`, {
            params: {
                page: '' + page,
                size: '' + size,
                sort,
                order,
                search
            }
        })
        .pipe(
            map((val: any) => ({data: val.data[0] || [] })),
            tap((response: any) => {
                this._risks.next(response.data);
                // this._risks.next(response);
            })
        );
    }

    /**
     * Get course by id
     */
    getRiskById(id: number): Observable<IRiskGroup>
    {
        return this.http.get<IRiskGroup>(this.apiURL + 'risks/' + id ).pipe(
            retry(1),
            map((risk: any) => {
                // Update the course
                // this._risk.next(risk.data);

                // Return the course
                return risk;
            }),
            switchMap((risk) => {

                if ( !risk )
                {
                    return throwError('Could not found risk with id of ' + id + '!');
                }

                return of(risk);
            })
        );
    }

    getFindings(id: string, name: string): Observable<any>
    {
        return this.http.get<any>(`${this.apiURL}risks/project/${id}/name/${name}`).pipe(
            retry(1),
            map((risk) => {
                // Return the course
                return risk[0];
            }),
            switchMap((risk) => {
                if ( !risk )
                {
                    return throwError('Could not found risk with id of ' + id + '!');
                }
                return of(risk);
            })
        );
    }

    // HttpClient API post() method => Create riskGroup
    createRiskGroup(riskGroup): Observable<any> {
        return this.http.post<IRiskGroup>(this.apiURL + 'risks', JSON.stringify(riskGroup), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    createAutoRiskRecommendation(data): Observable<any> {
        return this.http.post<IRiskGroup>(this.apiURL + 'risks/generate/riskrecommendation', JSON.stringify(data), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    createAutoRiskDescription(data): Observable<any> {
        return this.http.post<IRiskGroup>(this.apiURL + 'risks/generate/riskdescription', JSON.stringify(data), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API put() method => Update riskGroup
    updateRiskGroup(id, riskGroup): Observable<any> {
        return this.http.put<IRiskGroup>(this.apiURL + 'risks/' + id, JSON.stringify(riskGroup), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API delete() method => Delete riskGroup
    deleteRiskGroup(id): Observable<any>{
        return this.http.delete<IRiskGroup>(this.apiURL + 'risks/' + id, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { map, mergeMap, tap, retry, catchError } from 'rxjs/operators';
import { ProjectUserService } from 'app/shared/service/project-user.service';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private userLoggedIn = new Subject<boolean>();
    private _currentUser: ReplaySubject<any> = new ReplaySubject<any>(1);
    userData: any = null;

    apiURL = PROJECT_API + 'api/';

    constructor(
        private _httpClient: HttpClient,
        private productUserService: ProjectUserService,)
    {
        this.userLoggedIn.next(false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get currentUser$(): Observable<any>
    {
        if (this.userData) {
            return this._currentUser.asObservable();
        }
        else {
            return this.get();
        }
    }

    set currentUser(value: any)
    {
        this.userData = value;
        // Store the value
        this._currentUser.next(value);
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): any
    {
        try {
            const e = this.parseJwt(localStorage.getItem('id_token').split('Bearer')[1]);
            return this.productUserService.getUserByEmail(e.sub).pipe(
                tap((user:any) => {
                    this.userData = user;
                    this._currentUser.next(user);
                })
            );
        }
        catch(error: any) {
            return of ();
        }
    }

    // getUsers(): Observable<any>
    // {
    //     const e = this.parseJwt(localStorage.getItem('id_token').split('Bearer')[1]);
    //     return this.productUserService.getUserByEmail(e.sub);
    // }

    getUsers(): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `users`)
        .pipe(
            map((x: any) => x?.data[0]),
            tap((users) => {
                // this._user.next(users);
            })
        );
    }

    getUserByUid(uid:string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `users/${uid}`)
        .pipe(
            map((x: any) => x?.data[0]),
            tap((users) => {
            })
        );
    }

    getUserProjectByRole(roleTypeId, uid): Observable<any>
    {
        return this._httpClient.get(this.apiURL + `users/${uid}/role/${roleTypeId}`)
        .pipe(retry(1));
    }

    /**
     * Get users by id
     */
    getProjectUserByProjectId(id: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `users/user/project/${id}`)
        .pipe(
            retry(1),
            map((x: any) => x?.data[0]),
            tap((users) => {
                this._currentUser.next(users);
            })
        );
    }

    /**
     * Create user
     */
    createUser(user: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `users`, user)
            .pipe(
                tap((result: any) => {

                    this._currentUser.next(result);
                })
            );
    }

    /**
     * Update the user
     *
     * @param user
     */
    // update(user: any): Observable<any>
    // {
    //     // return this._httpClient.patch<User>('api/common/user', {user}).pipe(
    //     //     map((response) => {
    //     //         this._user.next(response);
    //     //     })
    //     // );
    //     return of();
    // }

    // deleteUser(id: number): Observable<any>
    // {
    //     return this._httpClient.delete(this.apiURL + `users/${id}`)
    //         .pipe(
    //             retry(1),
    //             tap((result: any) => {
    //                 console.log('deleteUser &&&&&&&&&& ', result);

    //                 // this._user.next(result);
    //             })
    //         );
    // }

    changePassword(user: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `auth/changePassword`, user)
        .pipe(
            catchError(this.handleError)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    // Error handling
    private handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

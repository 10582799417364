import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap, take, filter } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
import { ProjectUserService } from 'app/shared/service/project-user.service';
import Axios from  'axios-observable';

@Injectable({
    providedIn: 'root'
})
export class ReportCoverService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _report: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _authService: AuthService,
        private _projectUserService: ProjectUserService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for course
     */
     get report$(): Observable<any>
     {
         return this._report.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get categories
     */
    getReportCover(id: string): Observable<any[]>
    {
        return this._httpClient.get<any[]>(this.apiURL + 'reportcovers/' + id ).pipe(
            retry(1),
            tap((response: any) => {
                this._report.next(response);
            })
        );
    }

    getReportInfo(id: string): Observable<any[]>
    {
        return this._httpClient.get<any[]>(this.apiURL + `reportcovers/info/${id}` ).pipe(
            retry(1),
            tap((response: any) => {
                // this._report.next(response);
            })
        );
    }

    // submit(data: any): Observable<any>
    // {
    //     return this._httpClient.post(this.apiURL + 'reports', JSON.stringify(data), this.httpOptions)
    //     .pipe(
    //         tap((response: any) => {
    //             console.log('submit:', response);
    //         })
    //     );
    // }

    createReportCover(data: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + 'reportcovers', data).pipe(
            retry(1),
            map((newInterview) => {

                // Update the reports with the new report
                // this._report.next([newInterview]);

                // Return the new report
                return newInterview;
            })
        );
    }

    // createReportCoverAxios(data: any): Observable<any>
    // {
    //     return Axios.post(`${this.apiURL}reportcovers`, data, {
    //         headers: {
    //             'Authorization': this._authService.accessToken,
    //             'Content-Type': 'multipart/form-data',
    //             'domain': this._projectUserService.company
    //         }
    //     })
    //     .pipe(
    //         tap((response: any) => {
    //             console.log("🚀 ~ tap ~ response:", response)
    //         })
    //     );
    // }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

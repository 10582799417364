<div class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow" *ngIf="showNote">
    <div
        class="flex flex-col w-full sm:w-96 h-full transform transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{'-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow': opened, 'translate-x-0': !opened}">

        <!-- Header -->
        <div class="quick-note-header flex flex-0 items-center justify-start cursor-pointer bg" (click)="toggle()">

            <!-- Toggle -->
            <ng-container *ngIf="!opened || (opened)">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [svgIcon]="'heroicons_outline:pencil-alt'"></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">Role - Interview Note</div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>
<!--
            <ng-container *ngIf="opened">
                <div class="flex flex-auto items-center ml-3">
                    <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                        <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                            {{note.contact.name?.charAt(0)}}
                        </div>
                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">{{note.contact.name}}</div>
                    <button
                        class="ml-auto mr-4"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container> -->
        </div>

        <!-- Content -->
        <div class="flex flex-auto border-t overflow-hidden">
            <textarea class="textarea"></textarea>
        </div>
    </div>
</div>

<div class="relative flex flex-col flex-auto w-full ">
    <!-- questions -->
    <div class="flex flex-col items-center">
        <div class="w-full max-w-7xl">
            <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-4 border-b bg-card dark:bg-transparent">
                <div class="flex flex-shrink-0 mt-1 sm:mt-0 sm:ml-4 justify-end">
                    <!-- Title -->
                    <div class="">
                        <span class="text-2xl md:text-1xl tracking-tight leading-5 sm:leading-5 truncate">
                            TPRM Assessment Questions
                        </span>
                    </div>
                </div>
                <div class="flex items-center">
                    <button mat-icon-button [matTooltip]="'Close'" (click)="discard()" >
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="flex flex-col m-2 p-2">
                <ng-container *ngFor="let question of questions;let i = index">
                    <div class="flex flex-col mt-2">
                        <div class="text-xl font-semibold py-4" *ngIf="question.section">
                            {{question.section}}
                        </div>
                        <div class="text-xl">
                            {{i+1}}. {{question.question}}
                        </div>
                    </div>
                    <div class="w-full">
                        <div>{{question.answer}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

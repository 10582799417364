
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUser } from 'app/core/auth/user';
import { UserService } from 'app/core/user/user.service';
// import { MessagesService } from 'app/layout/common/messages/messages.service';
import { EvidenceRequestService } from 'app/shared/service/evidence-request.service';
import { MailboxService } from 'app/shared/service/mailbox.service';
import { RoleService } from 'app/shared/service/role.service';
import { base_component } from 'app/shared/components/base_component';
import { HelperService } from 'app/shared/service/helper.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { map } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'app-request-message',
    templateUrl: './request-message.component.html',
    styleUrls: ['./request-message.component.scss']
})
export class RequestMessageComponent extends base_component implements OnInit, OnDestroy {

    requestForm: FormGroup;
    projetPOCemail: string = '';
    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: { controlGuid: string, projectGuid: string, projectName: string, controlIdName: string },
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _renderer2: Renderer2,
        private _router: Router,
        // private _interviewService: InterviewService,
        private _evidenceRequestService: EvidenceRequestService,
        private _helper: HelperService,
        private _viewContainerRef: ViewContainerRef,
        // private _messageService: MessagesService,
        private _mailboxService: MailboxService,
        private _roleService: RoleService,
        private _matDialog: MatDialog,
    )
    {
        super(_notifyService, _userService);
    }

    ngOnInit(): void {

        this.getCurrentUser();
        this.setupForm();
        this.getVendorPOC();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    close(): void {
        this._matDialog.closeAll();
    }

    submit(): void {
        if ( this.requestForm.invalid ) { return; }
        // Get the contact object
        const formData = this.requestForm.getRawValue();
        var date = new Date();
        let msg = {
            guid: uuid(),
            title: `Message From the Assessor - TPRM Project, ${this._data.projectName}`,
            link: '',
            content: formData.note,
            icon: 'heroicons_outline:chat-alt',
            userouter: '',
            image: '',
            message_type_id: 4, //note
            parent_id: 0,
            labels: 'Request Message',
            date: date.toDateString(),
            subject: `Message From the Assessor - Control, ${this._data.controlIdName}`,
            toUser_id: 0,
            project_id: formData.project_guid,
            control_id: formData.control_guid,
            unread: 1,
            important: 1,
            created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            user_id: this.currentUser.id
        }
        //message
        this.subs.sink = this._mailboxService.create(msg)
        .subscribe((x:any) => {
            if (x.status === 'success') {
                this._notifyService.showSuccess('', 'Interview request sent');
            }
            else {
                this._notifyService.showError('', 'failed to send a interview request');
            }
        });
        //todo: use this?
        //send to request list
        this.subs.sink = this._evidenceRequestService.createRequest(formData)
        .subscribe((x:any) => {
            if (x.status === 'success') {
                this._notifyService.showSuccess('', 'Interview request sent');
            }
            else {
                this._notifyService.showError('', 'failed to send a interview request');
            }
        });
    }

    private getVendorPOC(): void {
        this.subs.sink = this._roleService.getVendorPOC(this._data.projectGuid)
        .pipe(
            map((val: any) => val?.data[0] || [])
        )
        .subscribe((arg: any) => {
            if (arg?.length > 0) {
                this.projetPOCemail = arg[0].email;
            }
            else {
                this._notifyService.showError('','Supplier POC is missing, Please contact your Manager');
            }
            this._changeDetectorRef.markForCheck();
        });
    }

    private setupForm() {
        this.requestForm = this._formBuilder.group({
            note: [''],
            request_type: [0, [Validators.required, Validators.min(1)]],
        });
    }
}

<div class="" *ngFor="let auditInquiry of auditInquiries" >
    <div class="mt-1 text-md leading-2 font-bold text-secondary">
        <div class="flex">
            <div class="w-4/5 pr-4">
                {{auditInquiry.control_id_name}}: {{auditInquiry.content}}
            </div>
            <div class="w-1/5">
                <mat-slide-toggle ngModel="auditInquiry.response ? true : false" (change)="toggle(auditInquiry, $event)"></mat-slide-toggle>
            </div>
        </div>
    </div>
</div>

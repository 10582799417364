/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, retry, tap, throwError } from 'rxjs';
import { PROJECT_API } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TprmResponseService {
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _document: BehaviorSubject<Document | null> = new BehaviorSubject(null);

    constructor(private http: HttpClient) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Documents
     */
     getResponse(projectId: string, controlId:string): Observable<any>
     {
         return this.http.get<any>(this.apiURL + `tprmresponses/${projectId}/${controlId}` ).pipe(
            retry(1),
             tap((response: any) => {
                 this._document.next(response);
             })
         );
     }

     createResponse(response:any): Observable<any> {
        return this.http.post<any>(this.apiURL + 'tprmresponses', JSON.stringify(response), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

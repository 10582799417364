
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import Axios from  'axios-observable';
import { AuthService } from 'app/core/auth/auth.service';
import { ProjectUserService } from 'app/shared/service/project-user.service';

@Injectable({
    providedIn: 'root'
})
export class AudioTestService
{
    //Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';

    private _media: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _medias: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private _mediaQuestions: BehaviorSubject<any[] | null> = new BehaviorSubject(null);


    /**
     * Constructor
     */
     constructor(private _httpClient: HttpClient,
        private _authService: AuthService,
        private _projectUserService: ProjectUserService) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for userengagements
     */
     get media$(): Observable<any>
     {
         return this._media.asObservable();
     }

    /**
     * Getter for course
     */
     get medias$(): Observable<any[]>
     {
         return this._medias.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    uploadFileData(formData: any): Observable<any> {
        return this._httpClient.post(this.apiURL + 'audiotest', JSON.stringify(formData), this.httpOptions)
        .pipe(
            tap((response: any) => {
                console.log("🚀 ~ tap ~ response:", response)
            }),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

<table mat-table
   [dataSource]="dataSource"
   cdkDropListOrientation="horizontal" >

   <ng-container matColumnDef="preview">
        <th mat-header-cell cdkDrag *matHeaderCellDef> preview </th>
        <td mat-cell *matCellDef="let element">
            <img [src]="sanitizeImageUrl(element.preview)" class="img" />
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell cdkDrag *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell cdkDrag *matHeaderCellDef> type </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="size">
        <th mat-header-cell cdkDrag *matHeaderCellDef> size </th>
        <td mat-cell *matCellDef="let element"> {{element.size}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { IRoles } from '../../modules/request-interview/interview/interview.types';

@Injectable({
    providedIn: 'root'
})
export class RoleService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _roles: BehaviorSubject<IRoles[] | null> = new BehaviorSubject(null);
    private _role: BehaviorSubject<IRoles | null> = new BehaviorSubject(null);
    private _roleId: BehaviorSubject<number> = new BehaviorSubject(null);
    private _responsibilities: BehaviorSubject<any | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for roles
     */
     get roles$(): Observable<IRoles[]>
     {
         return this._roles.asObservable();
     }
     get role$(): Observable<IRoles>
     {
         return this._role.asObservable();
     }
     get roleId$(): Observable<number>
     {
         return this._roleId.asObservable();
     }
     get responsibilities$(): Observable<IRoles>
     {
         return this._responsibilities.asObservable();
     }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getExistingInterviewees(): Observable<IRoles[]>
    {
        return this.http.get<IRoles[]>(this.apiURL + `roles/allinterviewees` ).pipe(
            map((val: any) => val?.data[0] || []),
            tap((response: any) => {
                this._roles.next(response);
            })
        );
    }

    /**
     * Get categories
     */
    getRoles(projectId: string): Observable<IRoles[]>
    {
        return this.http.get<IRoles[]>(this.apiURL + `roles/${projectId}` ).pipe(
            map((val: any) => val?.data[0] || []),
            tap((response: any) => {
                this._roles.next(response);
            })
        );
    }

    getRole(roleId: string, projectId: string): Observable<any[]>
    {
        return this.http.get<IRoles>(this.apiURL + `roles/role/${roleId}/${projectId}` ).pipe(
            map((val: any) => val?.data[0] || []),
            tap((response: any) => {
                this._role.next(response);
                this._responsibilities.next(response);
                this._roleId.next(+roleId);
            })
        );
    }

    getCompletedRoles(projectId: any): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `roles/completed/${projectId}` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    getProjectPOC(projectId: any): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `roles/${projectId}/projectpoc` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    getVendorPOC(projectId: any): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `roles/${projectId}/vendorpoc` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    createRole(employee:any): Observable<any> {
        return this.http.post<any>(this.apiURL + 'roles', JSON.stringify(employee), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    deleteRole(projectId: string, roleId: number, assetId: number): Observable<boolean>
    {
        return this.http.delete(this.apiURL + `roles/${projectId}/${roleId}/${assetId}`)
        .pipe(
            tap((result: any) => {
                // Update the assigninterviewers
                // this._roles.next(result);
            })
        );
    }

    //usp_insert_ProjectCompleted
    markAsCompleted(isCompleted: string, data: any): Observable<any>
    {
        return this.http.post<any>(this.apiURL + `roles/engagement/completed/all/${isCompleted}`, data)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

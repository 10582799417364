
    <div class="flex flex-col flex-auto md:w-160 md:min-w-160 h-full">
        <!-- <div class="flex items-center justify-end">
            <button mat-icon-button [matTooltip]="'Close'" (click)="close()" >
                <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div> -->

        <!-- Title -->
        <div class="mt-8 text-2xl font-extrabold tracking-tight leading-tight items-center text-center">
            Delegate Control #{{selectedControl?.control_id}}
        </div>

        <mat-tab-group class="mt-4" (selectedIndexChange)="selectedIndexChange($event)">
            <mat-tab label="Select from Project Users">
                <ng-container *ngIf="members?.length; else noMembers">
                    <!-- assign form -->
                    <form [formGroup]="reassignForm" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
                        <div class=" border-t">
                            <h2 class="text-1xl mt-8">Select a User</h2>
                            <mat-form-field class="w-full">
                                <mat-select [value]="0" [formControlName]="'assign_user_id'"
                                (selectionChange)="memberSelected($event)" >
                                    <mat-option [value]="0">-- Not Selected --</mat-option>
                                    <ng-container *ngFor="let member of members">
                                        <mat-option [value]="member.id">{{member.first_name}} {{member.last_name}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Actions -->
                        <div class="flex items-center mt-4 ">
                            <!-- Cancel -->
                            <button
                                class="ml-auto"
                                mat-button
                                [matTooltip]="'Cancel'"
                                (click)="close()">
                                Close
                            </button>
                            <!-- Submit button -->
                            <button class="fuse-mat-button-large" mat-flat-button
                                [color]="'primary'"
                                [disabled]="!reassignForm.valid"
                                (click)="reassignFormSubmit()">
                                <span *ngIf="!reassignForm.disabled">
                                    Submit
                                </span>
                                <mat-progress-spinner
                                    *ngIf="reassignForm.disabled"
                                    [diameter]="24"
                                    [mode]="'indeterminate'"></mat-progress-spinner>
                            </button>
                        </div>
                    </form>
                </ng-container>
                <!-- No members -->
                <ng-template #noMembers>
                    <div class="flex flex-col max-h-64 py-2 border-t overflow-y-auto ml-2 mr-2 mt-2 mt-10">
                        <fuse-alert [type]="'info'">
                            <span fuseAlertTitle>No Members Found!</span>
                        </fuse-alert>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Invite a new Project User">

                <form [formGroup]="vendorUserForm" class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
                    <div class="flex flex-col gt-xs:flex-row border-t">
                        <h2 class="text-1xl mt-8">Enter a Supplier User Information</h2>
                        <div class="flex mt-4">
                            <mat-form-field class=" w-1/3 pr-2" >
                                <mat-label>User Title</mat-label>
                                <input matInput formControlName="title" />
                            </mat-form-field>
                            <mat-form-field class=" w-1/3 pr-2">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="first_name" />
                            </mat-form-field>
                            <mat-form-field class=" w-1/3 pr-2">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="last_name" />
                            </mat-form-field>
                        </div>
                        <div class="flex mt-4">
                            <!-- * POC Email -->
                            <mat-form-field class=" w-1/3 pr-2">
                                <mat-label>Email Address</mat-label>
                                <input matInput formControlName="email" />
                            </mat-form-field>
                            <!-- * POC Phone -->
                            <mat-form-field class=" w-1/3 pr-2">
                                <mat-label>Phone Number</mat-label>
                                <input matInput formControlName="phone" placeholder="xxx-xxx-xxxx" />
                            </mat-form-field>
                            <!-- * POC Fax -->
                            <mat-form-field class=" w-1/3 pr-2">
                                <mat-label>Fax Number</mat-label>
                                <input matInput formControlName="fax" placeholder="xxx-xxx-xxxx" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Actions -->
                    <div class="flex items-center mt-4 ">
                        <!-- Cancel -->
                        <button
                            class="ml-auto"
                            mat-button
                            [matTooltip]="'Cancel'"
                            (click)="close()">
                            Close
                        </button>
                        <!-- Submit button -->
                        <button class="fuse-mat-button-large" mat-flat-button
                            [color]="'primary'"
                            [disabled]="!vendorUserForm.valid"
                            (click)="projectUserSubmit()">
                            <span *ngIf="!vendorUserForm.disabled">
                                Submit
                            </span>
                            <mat-progress-spinner
                                *ngIf="vendorUserForm.disabled"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                        </button>
                    </div>
                </form>

            </mat-tab>
        </mat-tab-group>

        <!-- Alert -->
        <fuse-alert class="mt-4" *ngIf="showAlert"  [appearance]="'outline'" [showIcon]="false" [type]="alert.type" >
            {{alert.message}}
       </fuse-alert>
    </div>


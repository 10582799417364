import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap, take, filter } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuditInquiryService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _auditInquiry: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for auditInquiry
     */
     get auditInquiries$(): Observable<any[]>
     {
         return this._auditInquiry.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get categories
     */
    getAuditInquiry(projectGuid: string, controlId: number): Observable<any[]>
    {
        return this._httpClient.get<any[]>(this.apiURL + `auditinquiry/${projectGuid}/${controlId}`).pipe(
            retry(1),
            tap((response: any) => {
                // //console.log('getauditInquiry:', response);
                this._auditInquiry.next(response);
            })
        );
    }

    insertAuditInquiryResponse(data: any): Observable<any>
    {
        return this._httpClient.post(this.apiURL + 'auditinquiry/response', JSON.stringify(data), this.httpOptions)
        .pipe(
            retry(1),
            tap((response: any) => {
                // //console.log('submit:', response);
            })
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}


<form class="flex flex-col md:w-200 flex-auto" [formGroup]="uploadForm">
    <!-- <div>projectId: {{projectId}} | controlId: {{controlId}}</div> -->
    <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>Drop or Select Files</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let file of files" [removable]="true" (removed)="onRemove($event)" >
            <ngx-dropzone-label >{{ file?.name }} ({{ file?.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
    <mat-expansion-panel hideToggle *ngIf="showNote" [expanded]="expandNote">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="mat-icon-rtl-mirror mr-2" svgIcon="note_add"></mat-icon> Add Notes
          </mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
            <mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full">
                <mat-label>Evidence Notes</mat-label>
                <mat-icon
                    matPrefix
                    class="hidden sm:flex icon-size-5"
                    [svgIcon]="'heroicons_solid:menu-alt-2'"></mat-icon>
                <textarea
                    matInput
                    [formControlName]="'note'"
                    [placeholder]="'Note'"
                    [rows]="10"
                    [spellcheck]="false"
                    matTextareaAutosize></textarea>
            </mat-form-field>
      </mat-expansion-panel>
      <ng-container *ngIf="reportFlow && showFlow">
        <div class="mt-4">
            <mat-label>Report Review Flow</mat-label>
            <fuse-alert [showIcon]="false" [appearance]="'fill'" [type]="'accent'" >
                <!-- <div class="mt-2 mb-2">Send Report to: {{nextQueueUser.name}}</div> -->
                <div class="border-b text-lg" [innerHTML]="reportFlow"></div>
                <mat-form-field class="mt-4" style="min-width:500px" >
                    <mat-label class="text-gray-50">Select a next report reviewer</mat-label>
                    <mat-select [formControlName]="'member_id'">
                        <mat-option [value]="">-- Not Selected --</mat-option>
                        <ng-container *ngFor="let member of members">
                            <mat-option [value]="member.id">({{member.role}}) {{member.first_name}} {{member.last_name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </fuse-alert>
        </div>
      </ng-container>
      <div class="mt-3" *ngIf="showFinalButton">
        <mat-checkbox [formControlName]="'final'" >
           <span>Mark Report as Final?</span>
        </mat-checkbox>
      </div>
    <!-- Alert -->
    <fuse-alert class="mt-4 -mb-2" *ngIf="alert.message" [appearance]="'outline'" [showIcon]="false" [type]="alert.type" >
        {{alert.message}}
    </fuse-alert>
    <!-- Actions -->
    <div class="flex items-center justify-center mt-2 -mx-6 sm:-mx-12 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7">
        <button class="ml-2" mat-flat-button [color]="'primary'"
            [disabled]="uploadForm.invalid || submitting || files.length === 0" (click)="getNextUserAndSubmit()">
            <span class="mr-2">Submit</span> <span *ngIf="submitting">
                <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </span>
        </button>
    </div>
    <div class="flex items-center justify-end w-full px-2 py-2 mt-2" *ngIf="showClose">
        <button class="ml-auto sm:ml-0" mat-stroked-button (click)="discard()">
            Close
        </button>
    </div>
</form>


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry, tap, throwError } from 'rxjs';
import { PROJECT_API } from 'environments/environment';
import { SubSink } from 'subsink';

// * Http Options
const httpOptions = {
    headers: new HttpHeaders({
        accept: 'application/json',
        'content-type': 'application/json',
    }),
};

const apiURL = PROJECT_API + 'api/';

@Injectable({
    providedIn: 'root',
})
export class ReportService {

    public subs = new SubSink();

    constructor(
        private http: HttpClient
        ) { }

    public getReportInformation(id: string): any {
        // var reportInformation = {
        //     companyName: jsonData.report.company.name,
        //     companyLogo: jsonData.report.company.logo,
        //     reportDate: getTodayDate(),
        //     auditorEmail: jsonData.report.auditor.email,
        //     auditorName: jsonData.report.auditor.name,
        //     systemName: jsonData.report.system.name,
        //     teamName: jsonData.report.teamInformation.teamName,
        // };
        // return reportInformation;
        return this.http
            .get<any>(apiURL + 'reports/info/' + id)
            .pipe(
                retry(1),
                tap((response: any) => {
                    // this._document.next(response);
                })
            );
    }

    public getdetailedFindings(id: string): Observable<any> {
        return this.http
            .get<any>(apiURL + 'reports/detailedfindings/' + id)
            .pipe(
                retry(1),
                tap((response: any) => {
                    // this._document.next(response);
                })
            );
    }

    public documentationReview(id: string): Observable<any> {
        return this.http
            .get<any>(apiURL + 'reports/documentationReview/' + id)
            .pipe(
                retry(1),
                tap((response: any) => {
                    // this._document.next(response);
                })
            );
    }

    public getAssessments(id: string): Observable<any> {
        return this.http
            .get<any>(apiURL + 'reports/assessments/' + id)
            .pipe(
                retry(1),
                tap((response: any) => {
                    // this._document.next(response);
                })
            );
    }

    // public getFileResourceUri(path: string): Observable<any> {
    //     return this.http
    //         .get<any>(apiURL + `reports/file/uri?=` + path)
    //         .pipe(
    //             retry(1),
    //             tap((response: any) => {
    //                 // this._document.next(response);
    //             })
    //         );
    // }

    public getReportFilePath(id: string): Observable<any> {
        return this.http
            .get<any>(apiURL + `reports/${id}/blob/uri`)
            .pipe(
                retry(1),
                tap((response: any) => {
                    // this._document.next(response);
                })
            );
    }

    //gap:1,risk2,cmmc:3
    public getReportFile(standardId:number, assessmentType:number) {
        let doc = `${standardId}-${assessmentType}`;
        console.log("🚀 ~ ReportService ~ getReportFile ~ doc:", doc)
        switch (doc) {
            case '1-1':
                return 'HIPAA Security Gap Report';
            case '1-2':
                return '';
            // case '13':  //remove 13
            case '2-2': // TPRM
                return 'Supplier Risk Report';
            case '2-1':
                return 'HIPAA Privacy Gap Report';
            case '3-1':
                return 'NIST CSF Gap Report';
            case '3-2':
                return 'NIST CSF Risk Report';
            case '3-3':
                return 'NIST CSF Risk and Maturity Report';
            case '4-1':
                return 'NIST 800-53v5 Gap Report';
            case '4-2':
                return 'NIST 800-53v5 Risk Report';
            case '4-3':
                return 'NIST 800-53v5 Risk and Maturity Report';
            case '5-1':
                return 'NYDFS Gap Report';
            case '6-1':
                return 'TSA Pipeline Gap Report';
            case '6-2':
                return 'TSA Pipeline Gap Report';
            case '8-1':
                return 'NIST 800-53v4 Gap Report';
            case '8-2':
                return 'NIST 800-53v4 Risk Report';
            case '8-3':
            case '11-3':
                return 'NIST 800-171 Risk and Maturity Report';
            case '11-4':
                return 'CMMC Pre-Assessment Gap Report';
            case '12-1':
                return 'CSA Security Gap Report';
            case '12-2':
                return 'CSA Security Risk Report';
            case '14-1':
                return 'Healthcare Safer Guides Gap Report';
            case '15-1':
                return 'HICP Security Gap Report';
            case '15-2':
                return 'HICP Security Risk Report';
            case '16-1':
                return 'CIS Gap Report';
            case '16-2':
                return 'CIS Risk Report';
            case '17-1':
                return 'SAQ D Report';
            case '22-1':
                return 'FedRamp Moderate Gap Report'
            case '24-1':
                return 'SOC 2 Report'
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { IVendor } from '../model/vendor.types';

@Injectable({
    providedIn: 'root'
})
export class VendorService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    apiURL = PROJECT_API + 'api/';

    // private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    private _vendors: BehaviorSubject<IVendor[] | null> = new BehaviorSubject<IVendor[] | null>(null);
    private _vendor: BehaviorSubject<IVendor | null> = new BehaviorSubject<IVendor | null>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    // get companies$(): Observable<any>
    // {
    //     return this._data.asObservable();
    // }

    get vendors$(): Observable<IVendor[] | null>
    {
        return this._vendors.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    //

    getVendorPOC(id: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + 'tprmcompanies/vendorpoc/project/' + id).pipe(
            tap((vendor: any) => {
                this._vendor.next(vendor);
                return vendor[0];
            })
        );
    }

    getVendorPOCs(id: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + 'tprmcompanies/vendorpoc/vendor/' + id).pipe(
            // map((val: any) => val?.data[0] || []),
            tap((vendor: any) => {
                this._vendor.next(vendor);
                return vendor[0];
            })
        );
    }

    //get all vendors or search
    // getVendorSearch(search:string ='', sort:string ='', order:string ='', size:number = 100, page:number = 0): Observable<any>
    // {
    //     return this.http.get<any>(this.apiURL + `vendors?search='${search}'&sort='${sort}'&order='${order}'&size=${size}&page=${page}`)
    //     .pipe(
    //         map((result) => {
    //                return result;
    //         }),
    //         switchMap((data) => {
    //             if ( !data )
    //             {
    //                 return throwError('vendors not found');
    //             }
    //             return of(data);
    //         })
    //     );
    // }

     getById(id: string): Observable<any>
     {
         // return this._httpClient.get<Vendor>('api/apps/risk/' + toNumberId, {}).pipe(
         return this.http.get<any>(this.apiURL + 'vendors/' + id ).pipe(
             map((data:any) => {
                    return data;
             }),
             switchMap((data) => {

                 if ( !data )
                 {
                     return throwError('1 Could not found data with id of ' + id + '!');
                 }

                 return of(data);
             })
         );
     }

    //  // thirdparty_company
    //  createVendor(vendor): Observable<any> {
    //      return this.http.post<any>(this.apiURL + 'vendors', JSON.stringify(vendor), this.httpOptions)
    //      .pipe(
    //          retry(1),
    //          catchError(this.handleError)
    //      );
    //  }

    //  // HttpClient API put() method => Update vendor
    //  updateVendor(id, vendor): Observable<any> {
    //      return this.http.put<any>(this.apiURL + 'vendors/' + id, JSON.stringify(vendor), this.httpOptions)
    //      .pipe(
    //          retry(1),
    //          catchError(this.handleError)
    //      );
    //  }

    //  // HttpClient API delete() method => Delete vendor
    //  deleteVendor(id): Observable<any>{
    //      return this.http.delete<any>(this.apiURL + 'vendors/' + id, this.httpOptions)
    //      .pipe(
    //          retry(1),
    //          catchError(this.handleError)
    //      );
    //  }

     // Error handling
     handleError(error:any): any {
         let errorMessage = '';
         if(error.error instanceof ErrorEvent) {
             // Get client-side error
             errorMessage = error.error.message;
         } else {
             // Get server-side error
             errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
         }
         window.alert(errorMessage);

         return throwError(errorMessage);
     }
}

<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="relative pt-8 pb-22 px-4 sm:pt-20 sm:pb-20 sm:px-16 overflow-hidden bg-gray-800 dark:bg-gray-900 dark">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none"
             viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
            <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- @formatter:on -->
        <div class="z-10 relative flex flex-col items-center">
            <h2 class="text-xl text-2xl font-semibold">AUDIO TEST</h2>
            <div class="mt-1 text-2xl mt-10 sm:text-5xl font-extrabold tracking-tight leading-tight text-center">
                 Let's test your audio before you start
            </div>
        </div>
    </div>

    <div class="flex flex-col items-center pb-4 px-4 sm:pb-4 sm:px-4">
      <div class="grid grid-cols-1 md:grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 w-full max-w-sm md:max-w-4xl -mt-10 sm:-mt-10">
            <div class="relative flex flex-col rounded-2xl shadow hover:shadow-lg overflow-hidden bg-card">
                <div class="flex flex-col flex-auto w-full p-4">
                    <div class="mt-1 text-2xl py-4 px-5 rounded-lg bg-gray-50 dark:bg-gray-200 dark:text-gray-800">
                        We will test here to see if your audio is enabled and ready to go.
                        <div class="mt-1 py-4 px-5 rounded-lg bg-amber-100 dark:bg-amber-200 dark:text-amber-800">
                            <ul class="list-decimal ml-4">
                                <li>Begin by clicking on "Start Recording".</li>
                                <li>Once you've finished speaking, click on "Finish Recording".</li>
                                <li>Click on "Submit" to test your audio and ensure readiness to proceed.</li>
                                <li>Wait for your recorded audio to be transcribed into text.</li>
                                <li>If the transcribed text is satisfactory (even if not perfect), click "YES" to commence your interview.</li>
                            </ul>
                        </div>
                    </div>
                    <ng-container >
                        <div class="flex items-center w-full divide-x mt-4">
                            <div class="flex flex-auto items-center justify-center py-4 hover:bg-hover" >
                                <button class="start-button" mat-flat-button *ngIf="!audioStarted && !audioBlobUrl && audioStatus !== 'pause'" (click)="startAudioRecording()">
                                        <mat-icon class="icon-size-7 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:microphone'"></mat-icon>
                                        <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Start Recording</span>
                                </button>
                                <button class="stop-button" mat-flat-button *ngIf="audioStatus === 'start'"
                                    (click)="pauseAudioRecording()">
                                    <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:pause'"></mat-icon>
                                    <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Pause</span>
                                </button>
                                <button class="stop-button" mat-flat-button *ngIf="audioStatus === 'start'"
                                    (click)="resumeAudioRecording()">
                                    <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:microphone'"></mat-icon>
                                    <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Resume</span>
                                </button>
                                <button class="cancel-button" mat-flat-button *ngIf="audioBlobUrl"
                                    (click)="clearAudioRecordedData()">
                                    <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Re-record</span>
                                </button>
                                <button class="stop-button" mat-flat-button *ngIf="!audioBlobUrl"
                                    (click)="stopAudioRecording()">
                                    <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:stop'"></mat-icon>
                                    <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Finish Recording</span>
                                </button>
                            </div>
                        </div>
                        <div class="flex items-center w-full divide-x mt-2">
                            <div id="audioTimer" class="timer flex items-center w-full justify-center text-3xl" *ngIf="isAudioRecording && !audioBlobUrl">
                                {{ audioRecordedTime }}
                            </div>
                        </div>
                        <div class="flex items-center w-full divide-x mt-2">
                            <audio #audioPlayer class="full-width mt-2 w-full" controls hidden>
                                <source *ngIf="audioBlobUrl" [src]="audioBlobUrl" type="audio/mp3">
                            </audio>
                        </div>
                        <div class="flex items-center w-full divide-x mt-2">
                            <div class="flex flex-auto items-center justify-center py-4 hover:bg-hover cursor-pointer"
                                    *ngIf="audioBlobUrl" (click)="submitAudio()">
                                <button class="cancel-button">
                                    <span class="font-semibold text-2xl mr-0 text-primary dark:text-primary-400">Submit Recording</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="returnedContent">
                        <div class="font-medium" >
                            <div class="mt-4">
                                <span class="text-2xl">This is what you said</span>
                                <div class="mt-1 py-4 px-5 rounded-lg bg-amber-100 dark:bg-amber-200 dark:text-amber-800">
                                    "{{returnedContent}}"
                                </div>
                            </div>
                            <div class="mt-4 py-4 px-5 rounded-lg bg-gray-50 dark:bg-gray-200 dark:text-gray-800" >
                                <div class="text-2xl text-center">Does the content match your recording?</div>
                                <div class="flex py-4 px-4 text-2xl">
                                    <button [ngClass]="'media-option-button'" (click)="matchRecording = 'yes'" class="rounded-full w-full">YES</button>
                                    <button [ngClass]="'media-option-button'" (click)="matchRecording = 'no'" class="rounded-full w-full">NO</button>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center text-2xl py-4 px-8 text-primary-500 dark:text-primary-400" *ngIf="matchRecording === 'yes'">
                            <div class="flex py-4 px-8">
                                <button class="text-2xl" (click)="okToStart()" class="rounded-full w-full">Great!, Start Audio Interview</button>
                            </div>
                        </div>
                        <div class="flex items-center justify-center text-2xl py-4 px-8 text-warn-500 dark:text-warn-400" *ngIf="matchRecording === 'no'">
                            <div class="flex py-4 px-8 ">
                                Please check your audio setting and test again!
                            </div>
                        </div>
                    </ng-container>
                    <div class="flex items-center justify-center text-2xl py-4 px-8 text-primary-500 dark:text-primary-400 border-t" >
                        <div class="flex py-4 px-8" *ngIf="project">
                            <button class="text-2xl" (click)="okToStart()" class="rounded-full w-full">SKIP (I've done this already.)</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







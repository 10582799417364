
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, IsActiveMatchOptions, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { CurrentUser } from 'app/core/auth/user';
import { UserService } from 'app/core/user/user.service';
import { InternalMessageService } from 'app/layout/layout.service';
import { EngagementService } from 'app/shared/service/engagement.service';
import { Engagement } from 'app/shared/model/engagement.types';
import { IStatus } from 'app/shared/model/status';
import { HelperService } from 'app/shared/service/helper.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { map, tap } from 'rxjs';
import { base_component } from '../base_component';
import { validate as isValidUUID } from 'uuid';
import { VendorService } from 'app/shared/service/vendor.service';
import { EmailService } from 'app/shared/service/email.service';

@Component({
    selector: 'app-project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['./project-status.component.scss'],
    animations   : fuseAnimations
})
export class ProjectStatusComponent extends base_component implements OnInit, OnDestroy
{
    // @ViewChild('kickoffForm') kickoffForm: NgForm;
    formFieldHelpers: string[] = [''];
    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    isActiveMatchOptions: IsActiveMatchOptions;
    // kickoffForm: FormGroup;
    showAlert: boolean = false;
    status: IStatus | undefined;
    statusId: number = 1;
    projectId: string = '';
    vendorId: string = '';
    statusList: IStatus[] = [];
    entity: Engagement = null;
    projectState: string = '';
    submitted: boolean = false;
    currentUser: CurrentUser;

    /**
     * Constructor
     */
    constructor(
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _route: ActivatedRoute,
        private _engagementService: EngagementService,
        private _vendorService: VendorService,
        private _helper: HelperService,
        private _router: Router,
        private _internalMessageService: InternalMessageService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _emailService: EmailService
    )
    {
        super(_notifyService, _userService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ GET methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.getCurrentUser();
        this.getMessages();

        this.projectState = this._router.url.endsWith('kickoff') ? 'Start Project': '';
        const tprm = this._router.url.includes('tprm');
        if (tprm) {
            this.vendorId = this._helper.getValidId(this._route);
            this.getVendors(this.vendorId);
        }
        else {
            this.projectId = this._helper.getValueFromUrl(this._router.url, 'projects');
            if (!isValidUUID(this.projectId)) {
                this.projectId = this._helper.getValueFromUrl(this._router.url, 'project');
            }
            if (!isValidUUID(this.projectId)) {
                this.projectId = this._helper.getValueFromUrl(this._router.url, 'workflow');
            }
            this.getEngagement(this.projectId);
        }
    }

    /**
     * On destroy
     */
     ngOnDestroy(): void
     {
         // Unsubscribe from all subscriptions
         this.subs.unsubscribe();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     */
    submit(): void
    {
        if (this.isAssessor || this.isProjectManager) {
            this.updateStatus();
        }
        else {
            this._notifyService.showError('','Only a manager and an auditor can start the project');
        }
    }

    private updateStatus() {
        this.statusId = 2;
        const data = {
            projectId: this.projectId,
            statusId: this.statusId,
            projectName: this.entity.name,
            status: this._helper.getStatus(this.statusId).code,
            user_id: this.currentUser.id,
            created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            updated_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            emailCreatorName: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            emailCreatorEmail: this.currentUser.email,
            emailCreatorPhone: this.currentUser.phone
        };

        this.subs.sink = this._engagementService.updateStatus(data)
        .pipe(
            // map((val: any) => val ? val[0] : [])
        )
        .subscribe((result: any) => {
            if (result?.status === 'success') {
                this._notifyService.showSuccess('', 'Project status updated successfully.');
                this.showAlert = true;
                this.alert.message = 'Project status updated successfully.';
                this.submitted = true;
                // this.getProjectPoc();
                this.getEngagement(this.projectId, true);

                // Mark for check
                this._changeDetectorRef.markForCheck();
            }
            else {
                this._notifyService.showError('', 'Failed to update project status.');
            }
        });
    }

    routeToProject(): void {
        // if (this.currentUser.roles.includes())
        const isAssessor = this.currentUser.roles.find((x:any) => x.name === 'Assessor');
        if (isAssessor) {
            this._router.navigate(['/projects/workflow', this.projectId, 'interview']);
        }
        else {
            //project poc
            this._router.navigate(['/dashboard/projectmanager/projects']);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // this._internalMessageService.sendMessage({name:'kickoff', data: {engagement: engagement} });
    private getMessages(): void {
        // const id = +this.helper.getValidId(this.route);
        this.subs.sink = this._internalMessageService.getMessage()
        .subscribe((arg: any) => {
            if (arg?.name === 'engagement') {
                this.entity = arg.data.engagement;

                this._changeDetectorRef.markForCheck();
            }
        });
    }

    private getVendors(id: string): void {
        this.subs.sink = this._vendorService.getById(id)
        .subscribe((suppliers: any[]) => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    private getEngagement(id: string, sendMsg: boolean = false): void {
        this.subs.sink = this._engagementService.getEngagementById(id)
            .pipe(
                map((val: any) => val ? val[0] : [])
            )
            .subscribe((engagement: any) => {
                if (engagement) {
                    this.loadProject(engagement);
                    if (sendMsg) {
                        //project poc & assessor
                        this._emailService.sendProjectActiveMsg(engagement);
                    }
                }
            });
    }

    private loadProject(engagement: Engagement): void {
        this.statusList = this._helper.loadStatus(engagement?.name);
        this.entity = engagement;

        this.statusId = engagement.status_id;
        this.status = this.statusList.find((x:any) => x.id === this.statusId);
        if (this.statusId === 0) {
            this.status = { id: 0, code: 'unknown', color: 'red', title: 'Unknown', action: 'unknown', message: 'Project status is unknown.', description:'' };
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    public getCurrentUser(): void {
        this.subs.sink = this._userService.currentUser$
        .pipe(
            map((val: any) => val ?
                (Array.isArray(val) ? val[0] : val) : null)
        )
        .subscribe((x: CurrentUser) => {
            this.currentUser = x;
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { Category, Course, Engagement, Interview, Status } from 'app/shared/model/engagement.types';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EngagementService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _interviews: BehaviorSubject<Interview[] | null> = new BehaviorSubject<Interview[] | null>(null);
    private _engagement: BehaviorSubject<Engagement | null> = new BehaviorSubject<Engagement | null>(null);
    private _engagements: BehaviorSubject<Engagement[] | null> = new BehaviorSubject<Engagement[] | null>(null);
    private roleCompleted: BehaviorSubject<any[] | null> = new BehaviorSubject<any[] | null>(null);
    private controlCompleted: BehaviorSubject<any[] | null> = new BehaviorSubject<any[] | null>(null);
    private engagementInserted: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for engagements
     */
     get engagements$(): Observable<Engagement[] | null>
     {
         return this._engagements.asObservable();
     }

     get engagement$(): Observable<Engagement | null>
     {
         return this._engagement.asObservable();
     }

    get interviews$(): Observable<Interview[] | null>
    {
        return this._interviews.asObservable();
    }

     get roleCompleted$(): Observable<any>
     {
         return this.roleCompleted.asObservable();
     }

    get engagementInserted$(): Observable<any>
    {
        return this.engagementInserted.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get statuses
     */
    // getStatuses(): Observable<Status[]>
    // {
    //     // return this.http.get<Status[]>(this.apiURL + 'apps/dashboard/statuses').pipe(
    //     return this.http.get<Status[]>('api/apps/dashboard/statuses').pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             ////console.log('getStatuses:', response);
    //             this._interviews.next(response);
    //         })
    //     );
    // }

    /**
     * Get projects
     */
    getEngagements(userId: number): Observable<Engagement[]>
    {
        return this.http.get<Engagement[]>(this.apiURL + `engagements/${userId}`).pipe(
            retry(1),
            tap((response: any) => {
                // console.log('getEngagements service2:', response);
                this._engagements.next(response);
            })
        );
    }

    getAdminEngagements(userId: number): Observable<Engagement[]>
    {
        return this.http.get<Engagement[]>(this.apiURL + `engagements/a/${userId}`).pipe(
            retry(1),
            tap((response: any) => {
                // console.log('getEngagements service2:', response);
                this._engagements.next(response);
            })
        );
    }

    getAssignedEngagements(userId: number): Observable<Engagement[]>
    {
        return this.http.get<Engagement[]>(this.apiURL + `engagements/assigned/${userId}`).pipe(
            retry(1),
            tap((response: any) => {
                // console.log('getEngagements service2:', response);
                this._engagements.next(response);
            })
        );
    }

    getEngagementDashboard(): Observable<any>
    {
        return this.http.get<Engagement[]>(this.apiURL + `dashboard/manager/stats`).pipe(
            retry(1),
            tap((response: any) => {
                // this._engagements.next(response);
            })
        );
    }

    // getEngagementsByProjectPOC(userId: number): Observable<Engagement[]>
    // {
    //     return this.http.get<Engagement[]>(this.apiURL + `engagements/roles//${userId}`).pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             this._engagements.next(response);
    //         })
    //     );
    // }

    getEngagementsByAuditor(userId: number): Observable<Engagement[]>
    {
        return this.http.get<Engagement[]>(this.apiURL + `engagements/audit/${userId}`).pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
    }

    /**
     * Get projects by type
     */
     getEngagementsByType(paramType: string, page: any = null): Observable<Engagement[]>
     {
        //  return this.http.get<Engagement[]>(this.apiURL + `engagements/type/${paramType}`).pipe(
        //      tap((response: any) => {
        //          this._engagements.next(response);
        //      })
        //  );
        return this.http.get<Engagement[]>(this.apiURL + 'engagements').pipe(
            retry(1),
            tap((response: any) => {
                this._engagements.next(response);
            })
        );
     }

    /**
     * Get project by id
     */
    getEngagementById(id: string): Observable<Engagement>
    {
        return this.http.get<Engagement>(this.apiURL + 'engagements/project/' + id ).pipe(
            retry(1),
            map((engagement: any) => {
                // Update the course
                this._engagement.next(engagement.data[0]);
                // Return the course
                return engagement.data[0];
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagement with id of ' + id + '!');
                }
                return of(engagement);
            })
        );
    }

    setPocToProject(project:any): Observable<any> {
        return this.http.post<any>(this.apiURL + 'engagements/project/poc', JSON.stringify(project), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    ///roles/:type/:userid
    getAssignedEngagementsByRole(role: number, userid: number): Observable<Engagement>
    {
        //console.log("🚀 ~ file: engagement.service.ts:  ~ role:", role, userid)
        return this.http.get<Engagement>(this.apiURL + `engagements/roles/${role}/${userid}` ).pipe(
            retry(1),
            map((x: any) => x.data ? x.data[0] : []),
            map((engagements: any) => {
                // //console.log('>>>> getEngagementById', engagements );
                // Update the course
                this._engagements.next(engagements );
                // Return the course
                return engagements;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagement with role of ' + role + '! 4');
                }
                return of(engagement);
            })
        );
    }

    search(query: string): Observable<Engagement[]>
    {
        return this.http.get<Engagement[]>(this.apiURL + `engagements/search/${query}` ).pipe(
            retry(1),
            map((result: any[]) => {
                //console.log('>>>> search', result);

                // Return the course
                return result;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found projects!');
                }
                return of(engagement);
            })
        );
    }

    //get all completed count
    getCompletedControlCount(id: string): Observable<any>
    {
         return this.http.get<Engagement>(this.apiURL + `engagements/${id}/completed` ).pipe(
            retry(1),
             map((val: any) => val?.data[0] || []),
             map((completed: any) => {
                 //console.log('>>>> getProjectControlCompleted', completed);
                 // Update the course
                 this.controlCompleted.next(completed);
                 // Return the course
                 return completed;
             }),
             switchMap((data) => {
                 if ( !data )
                 {
                     return throwError('Could not found roles with id of ' + id + '! 5');
                 }
                 return of(data);
             })
         );
     }

     getRoleCompleted(id: string, roleId: string): Observable<any>
     {
         return this.http.get<Engagement>(this.apiURL + `engagements/${id}/role/${roleId}/completed`).pipe(
            retry(1),
             map((val: any) => val?.data[0] || []),
             map((completed: any) => {
                 // Update the role
                 this.roleCompleted.next(completed);
                 // Return the role
                 return completed;
             }),
             switchMap((data) => {
                 if ( !data )
                 {
                     return throwError('Could not found roles with id of ' + id + '! 5');
                 }
                 return of(data);
             })
         );
     }

    createCompleted(data: any): Observable<any>
    {
        return this.http.post<Engagement>(this.apiURL + `engagements/${data.engagement_id}/completed`, data)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    createEngagement(data: any): Observable<any>
    {
        return this.http.post(this.apiURL + 'engagements', data)
            .pipe(
                retry(1),
                tap((result: any) => {
                    //console.log('postEngagement:', result);
                    this.engagementInserted.next(result);
                }),
                catchError(this.handleError)
            );
    }
    // HttpClient API post() method => Create engagement
    // createEngagement(engagement): Observable<any> {
    //     return this.http.post<Engagement>(this.apiURL + 'engagements', JSON.stringify(engagement), this.httpOptions)
    //     .pipe(
    //         retry(1),
    //         catchError(this.handleError)
    //     );
    // }

    updateStatus(data: any): Observable<any>
    {
        //console.log('updateStatus', data);
        return this.http.post(`${this.apiURL}engagements/status`, JSON.stringify(data), this.httpOptions)
            .pipe(
                retry(1),
                tap((result: any) => {
                    //console.log('postEngagement:', result);
                    // this.engagementInserted.next(result);
                }),
                catchError(this.handleError)
            );
    }

    // HttpClient API put() method => Update engagement
    updateEngagement(id:any, engagement:any): Observable<any> {
        return this.http.put<Engagement>(`${this.apiURL}engagements/${id}`, JSON.stringify(engagement), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API delete() method => Delete engagement
    deleteEngagement(guid:string): Observable<any>{
        return this.http.delete<Engagement>(`${this.apiURL}engagements/${guid}`, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { Member } from '../model/user.types';
import { ProjectUser } from 'app/shared/model/project-user.types';

@Injectable({
    providedIn: 'root'
})
export class ProjectUserService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _users: BehaviorSubject<Member[] | null> = new BehaviorSubject<Member[] | null>(null);
    private _members: BehaviorSubject<Member[] | null> = new BehaviorSubject<Member[] | null>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for users
     */
    get users$(): Observable<Member[] | null>
    {
        return this._users.asObservable();
    }
    get members$(): Observable<Member[] | null>
    {
        return this._members.asObservable();
    }
    // set company(guid: string)
    // {
    //     localStorage.setItem('domain', guid);
    // }
    // get company(): string
    // {
    //     return localStorage.getItem('domain') ?? '';
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // getClientIP(): any {
    //     return this.http.get<any>('https://geolocation-db.com/json/', { headers: null })
    //     .pipe(
    //         catchError(err => {
    //             return throwError(err);
    //         }),
    //         tap(response => {
    //             // console.log(response.IPv4);
    //             return response.IPv4;
    //         })
    //     )
    // }

    /**
     * Get getUsersByCompany
     */
    getUsers(): Observable<Member[]>
    {
        //console.log('getUsers service1:');
        return this.http.get<Member[]>(this.apiURL + 'users')
        .pipe(
            retry(1),
            tap((response: any) => {
                //console.log('getUsers service2:', response);
                this._users.next(response);
            })
        );
    }

    getUsersAndProjectUsers(id: string): Observable<Member[]>
    {
        return this.http.get<any[]>(this.apiURL + `users/company/project/${id}`)
        .pipe(
            retry(1),
            // map((val: any) => val?.data[0] || []),
            tap((response: any) => {
                this._users.next(response[0]);
            })
        );
    }

    /**
     * Get ProjectUser by id
     */
    getAssignedProjectByUserId(userid: number): Observable<any>
    {
        return this.http.get<ProjectUser>(this.apiURL + `projectusers/assigned/user/${userid}`)
        .pipe(
            map((x: any) => x?.data[0]),
            tap((users) => {
                this._users.next(users);
            })
        );
    }

    /**
     * Get getUsersByCompany
     */
     getUsersByCompany(conmpanyGuid: string): Observable<Member[]>
     {
         return this.http.get<Member[]>(this.apiURL + 'users/company/' + conmpanyGuid)
         .pipe(
             map((val: any) => val?.data[0] || []),
             tap((response: any) => {
                 this._members.next(response);
             })
         );
     }

     getUserByEmailByDomain(email: string, subdomain:string): Observable<Member>
     {
         return this.http.get<Member>(`${this.apiURL}users/${email}/domain/${subdomain}`)
         .pipe(
             map((val: any) => val?.data ? val?.data[0] : []),
             tap((response: any) => {
                const user = response[0];
                if (user) {
                    const list = user.roles?.split(',');
                    user.roles = [];
                    if (list) {
                        const roles = list.map((role:any) => { return {id: +role.split(':')[0], name: role.split(':')[1] }});
                        user.roles = roles;
                    }

                    this._users.next(user);
                }
             }),
         );
     }

     getUserByEmail(email: string): Observable<Member>
     {
         return this.http.get<Member>(`${this.apiURL}users/${email}`)
         .pipe(
             map((val: any) => val?.data ? val?.data[0] : []),
             tap((response: any) => {
                const user = response[0];
                if (user) {
                    const list = user.roles?.split(',');
                    user.roles = [];
                    if (list) {
                        const roles = list.map((role:any) => { return {id: +role.split(':')[0], name: role.split(':')[1] }});
                        user.roles = roles;
                    }

                    this._users.next(user);
                }
             }),
         );
     }

    //  getMemberByUID(uid: string): Observable<Member[]>
    //  {
    //     // console.log('getUsersByUID(uid--------------', uid);
    //      return this.http.get<Member[]>(this.apiURL + 'users/member/' + uid)
    //      .pipe(
    //          retry(1),
    //          map((val: any) => val?.data ? val?.data[0] : []),
    //          tap((response: any) => {
    //             const user = response[0];
    //             if (user) {
    //                 const list = user.roles?.split(',');
    //                 user.roles = [];
    //                 if (list) {
    //                     const roles = list.map((role) => { return {id: +role.split(':')[0], name: role.split(':')[1] }});
    //                     user.roles = roles;
    //                 }
    //             }
    //          }),
    //      );
    //  }

    //  getUsersDomain(email: string): Observable<any>
    //  {
    //      return this.http.get<any>(`${this.apiURL}users/${email}/domain`).pipe(
    //          map((val: any) => val?.data[0] || []),
    //          tap((response: any) => {
    //             const user = response[0];
    //             if (user) {
    //                 this.company = user.company_guid;
    //                 // this._users.next(user);
    //             }
    //          }),
    //      );
    //  }

    /**
     * Get categories
     */
    getUsersRoles(uid: string): Observable<any[]>
    {
        return this.http.get<any[]>(`${this.apiURL}users/roles/${uid}`).pipe(
            retry(1),
            tap((response: any) => {
                this._users.next(response);
            })
        );
    }

    /**
     * Get categories
     */
    getUsersByEngagement(projectId: string): Observable<Member[]>
    {
        return this.http.get<any[]>(this.apiURL + 'users/project/' + projectId)
        .pipe(
            tap((response: any) => {
                console.log("🚀 ~ tap ~ response:", response)
                this._users.next(response);
            })
        );
    }

    getProjectUserByProjectId(id: string): Observable<any>
    {
        return this.http.get<ProjectUser>(this.apiURL + `projectusers/project/${id}`)
        .pipe(
            // map((x: any) => x?.data[0]),
            // tap((users) => {
            //     this._users.next(users);
            // })
        );
    }

    // getProjectUserByProjectIdforContact(id: string): Observable<any>
    // {
    //     return this.http.get<ProjectUser>(this.apiURL + `projectusers/project/${id}`)
    //     .pipe(
    //         map((x: any) => x?.data[0]),
    //         tap((users) => {
    //             this._users.next(users);
    //         })
    //     );
    // }

    getContactsForUser(userid: number): Observable<any>
    {
        return this.http.get<ProjectUser>(this.apiURL + `projectusers/contacts/${userid}`)
        .pipe(
            map((x: any) => x?.data[0]),
            tap((users) => {
                this._users.next(users);
            })
        );
    }

    /**
     * Create ProjectUser
     */
    createProjectUser(ProjectUser: any): Observable<ProjectUser>
    {
        return this.http.post<ProjectUser>(this.apiURL + `projectusers`, ProjectUser)
            .pipe(
                retry(1),
                tap((result: any) => {
                    this._users.next([result]);
                })
            );
    }

    // HttpClient API post() method => Create employee
    createUser(employee:any): Observable<any> {
        return this.http.post<Member>(this.apiURL + 'users', JSON.stringify(employee), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API put() method => Update employee
    updateUser(id:any, employee:any): Observable<any> {
        return this.http.put<Member>(this.apiURL + 'users/' + id, JSON.stringify(employee), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API delete() method => Delete employee
    deleteUser(id:number): Observable<any>{
        return this.http.delete<Member>(this.apiURL + `projectusers/${id}`, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // deleteUserWithProject(userId:string, projectGuid:string): Observable<any>{
    //     return this.http.delete<Member>(this.apiURL + `projectusers/${projectGuid}/${userId}`, this.httpOptions)
    //     .pipe(
    //         retry(1),
    //         catchError(this.handleError)
    //     );
    // }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

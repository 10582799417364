import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, mergeMap, Observable, of, retry, switchMap, take, tap, throwError } from 'rxjs';
import { Mail, MailCategory, MailFilter, MailFolder, MailLabel } from '../../modules/mailbox/mailbox.types';
import { PROJECT_API } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';
import { Message } from 'app/layout/common/messages/messages.types';

@Injectable({
    providedIn: 'root'
})
export class MailboxService
{
    selectedMailChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    private _category: BehaviorSubject<MailCategory | null> = new BehaviorSubject<MailCategory | null>(null);
    private _filters: BehaviorSubject<MailFilter[] | null> = new BehaviorSubject<MailFilter[] | null>(null);
    private _folders: BehaviorSubject<MailFolder[] | null> = new BehaviorSubject<MailFolder[] | null>(null);
    private _mails: BehaviorSubject<Mail[] | null> = new BehaviorSubject<Mail[] | null>(null);
    private _mailsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _mail: BehaviorSubject<Mail | null> = new BehaviorSubject<Mail | null>(null);
    private _pagination: BehaviorSubject<any> = new BehaviorSubject(null);

    apiURL = PROJECT_API + 'api/';
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        public _userService: UserService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for category
     */
    get category$(): Observable<MailCategory | null>
    {
        return this._category.asObservable();
    }

    /**
     * Getter for filters
     */
    get filters$(): Observable<MailFilter[] | null>
    {
        return this._filters.asObservable();
    }

    /**
     * Getter for folders
     */
    get folders$(): Observable<MailFolder[] | null>
    {
        return this._folders.asObservable();
    }

    /**
     * Getter for labels
     */
    // get labels$(): Observable<MailLabel[]>
    // {
    //     return this._labels.asObservable();
    // }

    /**
     * Getter for mails
     */
    get mails$(): Observable<Mail[] | null>
    {
        return this._mails.asObservable();
    }

    /**
     * Getter for mails loading
     */
    get mailsLoading$(): Observable<boolean>
    {
        return this._mailsLoading.asObservable();
    }

    /**
     * Getter for mail
     */
    get mail$(): Observable<Mail | null>
    {
        return this._mail.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<any>
    {
        return this._pagination.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get filters
     */
    // getFilters(): Observable<any>
    // {
    //     return this._httpClient.get<MailFilter[]>('api/apps/mailbox/filters').pipe(
    //         tap((response: any) => {
    //             this._filters.next(response);
    //         })
    //     );
    // }

    /**
     * Get folders
     */
    getFolders(): Observable<any>
    {
        const folders = [{
            id   : '7c004a19-4506-48ef-93ab-f16381302e3b',
            title: 'Inbox',
            slug : 'inbox',
            icon : 'heroicons_outline:inbox'
        }];
        this._folders.next(folders);
        return of(folders);
    }

    /**
     * Get labels
     */
    // getLabels(): Observable<any>
    // {
    //     return this._httpClient.get<MailLabel[]>('api/apps/mailbox/labels').pipe(
    //         tap((response: any) => {
    //             this._labels.next(response);
    //         })
    //     );
    // }

    /**
     * Get mails by filter
     */
    // getMailsByFilter(filter: string, page: string = '1'): Observable<any>
    // {
    //     // Execute the mails loading with true
    //     this._mailsLoading.next(true);

    //     return this._httpClient.get<Mail[]>('api/apps/mailbox/mails', {
    //         params: {
    //             filter,
    //             page
    //         }
    //     }).pipe(
    //         tap((response: any) => {
    //             this._category.next({
    //                 type: 'filter',
    //                 name: filter
    //             });
    //             this._mails.next(response.mails);
    //             this._pagination.next(response.pagination);
    //             this._mailsLoading.next(false);
    //         }),
    //         switchMap((response) => {

    //             if ( response.mails === null )
    //             {
    //                 return throwError({
    //                     message   : 'Requested page is not available!',
    //                     pagination: response.pagination
    //                 });
    //             }

    //             return of(response);
    //         })
    //     );
    // }

    /**
     * Get mails by folder
     */
    // getMailsByFolder(folder: string, page: string = '1'): Observable<any>
    // {
    //     // Execute the mails loading with true
    //     this._mailsLoading.next(true);

    //     return this._httpClient.get<Mail[]>('api/apps/mailbox/mails', {
    //         params: {
    //             folder,
    //             page
    //         }
    //     }).pipe(
    //         tap((response: any) => {
    //             this._category.next({
    //                 type: 'folder',
    //                 name: folder
    //             });
    //             this._mails.next(response.mails);
    //             this._pagination.next(response.pagination);
    //             this._mailsLoading.next(false);
    //         }),
    //         switchMap((response) => {

    //             if ( response.mails === null )
    //             {
    //                 return throwError({
    //                     message   : 'Requested page is not available!',
    //                     pagination: response.pagination
    //                 });
    //             }

    //             return of(response);
    //         })
    //     );
    // }

    /**
     * Get mails by label
     */
    // getMailsByLabel(label: string, page: string = '1'): Observable<any>
    // {
    //     // Execute the mails loading with true
    //     this._mailsLoading.next(true);

    //     return this._httpClient.get<Mail[]>('api/apps/mailbox/mails', {
    //         params: {
    //             label,
    //             page
    //         }
    //     }).pipe(
    //         tap((response: any) => {
    //             this._category.next({
    //                 type: 'label',
    //                 name: label
    //             });
    //             this._mails.next(response.mails);
    //             this._pagination.next(response.pagination);
    //             this._mailsLoading.next(false);
    //         }),
    //         switchMap((response) => {

    //             if ( response.mails === null )
    //             {
    //                 return throwError({
    //                     message   : 'Requested page is not available!',
    //                     pagination: response.pagination
    //                 });
    //             }

    //             return of(response);
    //         })
    //     );
    // }

    /**
     * Get all active project messages
     */
    getAllMessages(): Observable<any[]>
    {
        return this._userService.currentUser$
            .pipe(
                map((val: any) => val ? (Array.isArray(val) ? val[0] : val) : val),
                // tap((x:any) => console.log('**********************getAllMessages2:', `messages/users/${x.id}`)),
                mergeMap((x:any) => this._httpClient.get<any[]>(this.apiURL + `messages/users/${x.id}`).pipe(
                    tap((result: any) => {
                        console.log("getAllMessages ~ result:", result)
                        const messages = result ? result.data[0] : [];
                        this._mails.next(messages);
                    })
                )
            )
        );
    }

    /**
     * Get all messages by Project Id
     */
    getMessagesByProjectId(projectId: string): Observable<any[]>
    {
        if (projectId) {
            return this._userService.currentUser$
                .pipe(
                    tap((x:any) => console.log('getMessagesByProjectId:', x)),
                    map((val: any) => val ? (Array.isArray(val) ? val[0] : val) : val),
                    mergeMap((x:any) => this._httpClient.get<any[]>(this.apiURL + `messages/projects/${projectId}/user/${x.id}`).pipe(
                        tap((result: any) => {
                            console.log("🚀 ~ tap ~ result:", result)
                            const messages = result && result.data ? result.data[0] : [];
                            if (messages) {
                                console.log("🚀 ~ tap ~ result messages:", messages)
                                this._mails.next(messages);
                            }
                        })
                    )
                )
            );
        }
        // else {
        //     return this.getAllMessages();
        // }
    }

    getMessagesByUserId(projectId: string): Observable<any[]>
    {
        if (projectId) {
            return this._userService.currentUser$
                .pipe(
                    map((val: any) => val ? (Array.isArray(val) ? val[0] : val) : val),
                    mergeMap((x:any) => this._httpClient.get<any>(this.apiURL + `messages/users/${x.id}`).pipe(
                        map((val: any) =>  val?.data ? val?.data[0] : []),
                        tap((result: any) => {
                            const messages = result ? result[0]: null;
                            // console.log("🚀 ~ getMessagesByUserId ~ result >>>>>>>> ", result, messages)
                            this._mails.next(result);
                        })
                    )
                )
            );
        }
        else {
            return this.getAllMessages();
        }
    }

    /**
     * Get mail by id
     */
    getMailById(id: string): Observable<any>
    {
        return this._mails.pipe(
            take(1),
            map((mails) => {

                // Find the mail
                const mail = mails?.find(item => item.id == id) || null;
                // Update the mail
                if (mail) {
                    this._mail.next(mail);
                }

                // Return the mail
                return mail;
            }),
            // switchMap((mail) => {

            //     if ( !mail )
            //     {
            //         return throwError('Could not found mail with id of ' + id + '!');
            //     }

            //     return of(mail);
            // })
        );
    }

    // updateMail(id: string, mail: Mail): Observable<any>
    // {
    //     return this._httpClient.patch('api/apps/mailbox/mail', {
    //         id,
    //         mail
    //     }).pipe(
    //         tap(() => {

    //             // Re-fetch the folders on mail update
    //             // to get the updated counts on the sidebar
    //             this.getFolders().subscribe();
    //         })
    //     );
    // }

    /**
     * Reset the current mail
     */
    resetMail(): Observable<boolean>
    {
        return of(true).pipe(
            take(1),
            tap(() => {
                this._mail.next(null);
            })
        );
    }

    /**
     * Add label
     *
     * @param label
     */
    // addLabel(label: MailLabel): Observable<any>
    // {
    //     return this.labels$.pipe(
    //         take(1),
    //         switchMap(labels => this._httpClient.post<MailLabel>('api/apps/mailbox/label', {label}).pipe(
    //             map((newLabel) => {

    //                 // Update the labels with the new label
    //                 this._labels.next([...labels, newLabel]);

    //                 // Return the new label
    //                 return newLabel;
    //             })
    //         ))
    //     );
    // }

    /**
     * Update label
     *
     * @param id
     * @param label
     */
    // updateLabel(id: string, label: MailLabel): Observable<any>
    // {
    //     return this.labels$.pipe(
    //         take(1),
    //         switchMap(labels => this._httpClient.patch<MailLabel>('api/apps/mailbox/label', {
    //             id,
    //             label
    //         }).pipe(
    //             map((updatedLabel: any) => {

    //                 // Find the index of the updated label within the labels
    //                 const index = labels.findIndex(item => item.id === id);

    //                 // Update the label
    //                 labels[index] = updatedLabel;

    //                 // Update the labels
    //                 this._labels.next(labels);

    //                 // Return the updated label
    //                 return updatedLabel;
    //             })
    //         ))
    //     );
    // }

    /**
     * Delete label
     *
     * @param id
     */
    // deleteLabel(id: string): Observable<any>
    // {
    //     return this.labels$.pipe(
    //         take(1),
    //         switchMap(labels => this._httpClient.delete('api/apps/mailbox/label', {params: {id}}).pipe(
    //             map((isDeleted: any) => {

    //                 // Find the index of the deleted label within the labels
    //                 const index = labels.findIndex(item => item.id === id);

    //                 // Delete the label
    //                 labels.splice(index, 1);

    //                 // Update the labels
    //                 this._labels.next(labels);

    //                 // Return the deleted status
    //                 return isDeleted;
    //             })
    //         ))
    //     );
    // }


    /**
     * Get all active project messages
     */
    getAll(userId: number = 0): Observable<Message[]>
    {
        try {
            if (userId === 0) {
                userId = this._userService?.userData?.id;
            }
            if (!userId || userId === 0) {
                // this._mails.next([]);
                return of([]);
            }
            return this._httpClient.get<Message[]>(this.apiURL + `messages/users/${userId}`)
            .pipe(
                tap((result: any) => {
                    if (result.data) {
                        let messages = result ? result.data[0] : [];
                        //map tinyint to boolean
                        if (messages?.length) {
                            messages.map((x:any) => x.unread = (x.unread === 1 || x.unread === true));
                            this._mails.next(messages);
                        }
                    }
                })
            );
        }
        catch(err) {
            return of([]);
        }
    }

    getUnreadCount(): Observable<Message[]>
    {
        return this._httpClient.get<any>(this.apiURL + 'messages/getUnreadCount').pipe(
            tap((result: any) => {
                if (result.data) {
                    const messages = result ? result.data[0] : [];
                    this._mails.next(messages);
                }
                else {
                    this._mails.next([]);
                }
            })
        );
    }

    /**
     * Get all messages by Project Id
     */
    // getMessagesByProjectId(projectId: string): Observable<Message[]>
    // {
    //     console.log('getMessagesByProjectId - projectId >>>>', projectId)
    //     if (projectId) {
    //         return this._httpClient.get<Message>(this.apiURL + 'messages/projects/' + projectId).pipe(
    //             retry(1),
    //             tap((result: any) => {
    //                 console.log('getMessagesByProjectId - result >>>>', result)
    //                 const messages = result ? result[0]: null;
    //                 this._mails.next(messages);
    //             })
    //         );
    //     }
    //     else {
    //         // return this.getAll();
    //     }
    // }

    getMessagesByControlId(projectId: string, controlId:string): Observable<Message[]>
    {
        return this._httpClient.get<Message>(this.apiURL + `messages/projects/${projectId}/${controlId}`).pipe(
            retry(1),
            tap((result: any) => {
                // const messages = result ? result[0]: null;
                // this._mails.next(messages);
            })
        );
    }

    /**
     * Create a message
     *
     * @param message
     */
    create(message: any): Observable<any>
    {
        return this.mails$.pipe(
            retry(1),
            take(1),
            switchMap(messages => this._httpClient.post<any>(this.apiURL + 'messages', {message}).pipe(
                map((newMessage) => {
                    // Update the messages with the new message
                    if (messages) {
                        this._mails.next([...messages, newMessage]);
                    }
                    else {
                        this._mails.next([newMessage]);
                    }
                    // Return the new message from observable
                    return newMessage;
                })
            ))
        );
    }

    /**
     * Update the message
     *
     * @param id
     * @param message
     */
    update(message: any): Observable<Message>
    {
        return this.mails$.pipe(
            take(1),
            switchMap(messages => this._httpClient.put<Message>(this.apiURL + `messages/${message.id}`, {
                message
            }).pipe(
                map((updatedMessage: any) => {
                    if (messages) {
                        // Find the index of the updated message
                        const index = messages.findIndex(item => item.id === message.id);

                        if (updatedMessage?.status ==='success') {
                            // Update the message
                            messages[index] = message;

                            // Update the messages
                            this._mails.next(messages);

                            // Return the updated message
                            return message;
                        }
                        else {
                            //undo - didn't work
                            message.unread = true;
                            return message;
                        }
                    }
                    else {
                        return null;
                    }
                })
            ))
        );
    }

    /**
     * Delete the message
     *
     * @param id
     */
    delete(id: string): Observable<boolean>
    {
        return this.mails$.pipe(
            switchMap(messages => this._httpClient.delete<boolean>(this.apiURL + 'messages', {params: {id}}).pipe(
                retry(1),
                map((isDeleted: any) => {
                    if (messages) {
                        // Find the index of the deleted message
                        const index = messages.findIndex(item => item.id === id);

                        // Delete the message
                        messages.splice(index, 1);

                        // Update the messages
                        this._mails.next(messages);
                    }

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Mark all messages as read
     */
    markAllAsRead(): Observable<boolean>
    {
        return this.mails$.pipe(
            take(1),
            switchMap(messages => this._httpClient.get<boolean>(this.apiURL + 'messages/mark-all-as-read').pipe(
                retry(1),
                map((isUpdated: boolean) => {

                    // Go through all messages and set them as read
                    messages.forEach((message, index) => {
                        messages[index].unread = false;
                    });

                    // Update the messages
                    this._mails.next(messages);

                    // Return the updated status
                    return isUpdated;
                })
            ))
        );
    }

}

<ng-container *ngIf="questionsStatus === questionsStatusEnum.lastQuestion">
    <h1 class="font-semibold text-3xl uppercase" style="text-align: center;">{{interviewType}} Interview</h1>
    <div class="flex flex-col flex-auto items-center bg-card shadow rounded-2xl overflow-hidden m-4">
        <div class="flex flex-col flex-auto w-full p-4">
            <div class="text-3xl text-center m-20">
                <h1>Last question answered but there are some questions still unanswered.</h1>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="questionsStatus === questionsStatusEnum.done">
    <h1 class="font-semibold text-3xl uppercase" style="text-align: center;">{{interviewType}} Interview</h1>
    <div class="flex flex-col flex-auto items-center bg-card shadow rounded-2xl overflow-hidden m-4">
        <div class="flex flex-col flex-auto w-full p-4">
            <div class="text-3xl text-center m-20">
                <h1>All done, thank you!</h1>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="questionsStatus === questionsStatusEnum.notDone">

    <fuse-alert class="mt-1 mb-4 m-4" [appearance]="'outline'" [showIcon]="false" [type]="'accent'" >
        <div class="sm:text-1xl tracking-tight text-secondary">
            <ul>
                <li>1. When you are ready to answer the question below click on <span class="font-semibold text-lg mr-0 text-primary-400">Start Recording</span>.</li>
                <li>2. When you are done, click on <span class="font-semibold text-lg mr-0 text-primary-400">Finish Recording</span>.</li>
                <li>3. Play recorded media to check, you can re-record by clicking <span class="font-semibold text-lg mr-0 text-primary-400">Re-record Recording</span>.</li>
                <li>4. Make sure to click <span class="font-semibold text-lg mr-0 text-primary-400" >Submit Recording</span> to submit.</li>
                <li>5. Please read the question careful {{selectedQuestion?.example ? 'including example' : ''}} before answering.</li>
                <li>* Please <span class="font-semibold text-lg mr-0 text-green-600">Pause</span> the audio between questions and keep it under <span class="font-semibold text-lg mr-0 text-orange-600">2 minutes!</span></li>
            </ul>
        </div>
    </fuse-alert>
    <h1 class="font-semibold text-3xl uppercase" style="text-align: center;">{{interviewType}} Interview</h1>
    <div class="flex flex-col flex-auto items-center bg-card shadow rounded-2xl overflow-hidden m-4">
        <div class="flex flex-col flex-auto w-full p-4">
            <div class="font-medium">
                <ng-container *ngIf="selectedQuestion">
                    <!-- this feature is for TPRM only -->
                    <div class="flex items-start justify-between">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">&nbsp;</div>
                        <ng-container *ngIf="isVendorPoc && isTPRM">
                           <div *ngIf="selectedQuestion.delegated_email" class="bg-primary-100 rounded-2xl">
                                <div class=" ml-4 mr-4">
                                    <span class="uppercase">Delegated to</span>
                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'"></mat-icon>
                                    {{selectedQuestion.delegated_email}}
                                    <button mat-icon-button (click)="deleteDelegatedUser($event, selectedQuestion.control_guid)" matSuffix>
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="ml-2 -mt-2 -mr-3"  *ngIf="!selectedQuestion.delegated_email">
                                <button
                                    mat-icon-button
                                    [matMenuTriggerFor]="summaryMenu">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                                </button>
                                <mat-menu #summaryMenu="matMenu">
                                    <button mat-menu-item
                                        (click)="openReassignDialog(selectedQuestion)">
                                        <mat-icon>perm_identity</mat-icon> Delegate to another user
                                    </button>
                                </mat-menu>
                            </div>
                        </ng-container>
                    </div>
                    <div class="uppercase text-2xl mb-4" style="text-align: center;">FROM THE PERSPECTIVE OF
                        <span class="text-primary-600 dark:text-primary-400">{{selectedQuestion.term}}</span>
                    </div>
                    <div>
                        <span>CONTROL: #{{selectedQuestion.control_id}}</span>
                        <p [innerHTML]="selectedQuestion.safeguard_summarized" class="mt-1 py-4 px-5 rounded-lg bg-gray-50 dark:bg-gray-200 dark:text-gray-800"></p>
                    </div>
                    <div class="mt-4">
                        <span>QUESTION: #{{selectedQuestion.question_order_id || 1}} </span>
                        <div class="mt-1 py-4 px-5 rounded-lg bg-amber-100 dark:bg-amber-200 dark:text-amber-800">
                            <ul class="list-decimal ml-4">
                                <li *ngIf="selectedQuestion.video_question1">{{selectedQuestion.video_question1}}</li>
                                <li *ngIf="selectedQuestion.video_question2">{{selectedQuestion.video_question2}}</li>
                                <li *ngIf="selectedQuestion.video_question3">{{selectedQuestion.video_question3}}</li>
                                <li>Is there a documented policy and procedures?</li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="selectedQuestion.example">
                        <div class="mt-4 ">
                            <span>EXAMPLE ANSWER:</span>
                            <div class="py-4 px-5 rounded-lg bg-gray-50 dark:bg-gray-200 dark:text-gray-800">
                                <ul [innerHTML]="selectedQuestion.example"></ul>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!selectedQuestion">
                    Select a question to answer!
                </ng-container>
            </div>
            <ng-container *ngIf="interviewType === 'Written'">
                <div class="flex flex-col mt-4 sm:mt-6">
                    <div>Supplier Response:</div>
                    <quill-editor class="mt-2" ngModel="response" [modules]="quillModules"></quill-editor>
                </div>
                <div class="flex flex-col sm:flex-row sm:items-center justify-center mt-4 sm:mt-6">
                    <div class="-ml-2">
                    </div>
                    <div class="flex items-center mb-4">
                        <!-- <button class="ml-auto sm:ml-0" mat-stroked-button (click)="discard()">
                            Cancel
                        </button> -->
                        <button class="order-first sm:order-last" mat-flat-button [color]="'primary'" (click)="submitWritten()" [disabled]="!response">
                            Submit
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="interviewType === 'Audio'">
                <!-- audio controls -->
                <div class="flex items-center w-full divide-x mt-4">
                    <div class="flex flex-auto items-center justify-center py-4 hover:bg-hover" >
                        <button class="start-button" mat-flat-button *ngIf="!isAudioRecording && !audioBlobUrl && selectedQuestion"
                                (click)="startAudioRecording()">
                                <mat-icon class="icon-size-7 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:microphone'"></mat-icon>
                                <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Start Recording</span>
                        </button>
                        <button class="stop-button" mat-flat-button *ngIf="audioStatus === 'start'"
                            (click)="pauseAudioRecording()">
                            <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:pause'"></mat-icon>
                            <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Pause</span>
                        </button>
                        <button class="stop-button" mat-flat-button *ngIf="!audioBlobUrl && audioStatus === 'pause'"
                            (click)="resumeAudioRecording()">
                            <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:microphone'"></mat-icon>
                            <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Resume</span>
                        </button>
                        <button class="stop-button" mat-flat-button *ngIf="isAudioRecording && !audioBlobUrl"
                            (click)="stopAudioRecording()">
                            <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:stop'"></mat-icon>
                            <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Finish Recording</span>
                        </button>
                        <button class="cancel-button" mat-flat-button *ngIf="!isAudioRecording && audioBlobUrl"
                            (click)="clearAudioRecordedData()">
                            <mat-icon class="icon-size-5 text-hint mr-2" style="vertical-align: middle;" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                            <span class="font-semibold text-2xl mr-0 text-red-600 dark:text-orange-400">Re-record Recording</span>
                        </button>
                    </div>
                    <!-- submit -->
                    <div class="flex flex-auto items-center justify-center py-4 hover:bg-hover cursor-pointer bg-red-200"
                            *ngIf="audioBlobUrl" (click)="submitAudio()">
                        <button class="cancel-button">
                            <span class="font-semibold text-2xl mr-0 text-primary dark:text-primary-400">Submit Recording</span>
                        </button>
                    </div>
                </div>
                <audio #audioPlayer class="full-width mt-2" controls hidden>
                    <source *ngIf="audioBlobUrl" [src]="audioBlobUrl" type="audio/mp3">
                </audio>
                <div id="audioTimer" class="timer" *ngIf="isAudioRecording && !audioBlobUrl">
                    {{ audioRecordedTime }}
                </div>
            </ng-container>
        </div>
    </div>

</ng-container>

// import { Injectable, OnDestroy } from '@angular/core';
// import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError, distinctUntilChanged, debounceTime, first, switchMap, tap, retry } from 'rxjs/operators';
// import { AuthService } from 'app/core/auth/auth.service';
// import { AuthUtils } from 'app/core/auth/auth.utils';
// import { ProjectUserService } from 'app/shared/service/productuser.service';
// import { SubSink } from 'subsink';
// import { ActivatedRoute, Router } from '@angular/router';

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor, OnDestroy
// {
//     private subs = new SubSink();
//     private isRefreshing = false;

//     /**
//      * Constructor
//      */
//     constructor(private _authService: AuthService,
//                 private _router: Router,
//                 private _activatedRoute: ActivatedRoute,
//                 private _projectUserService: ProjectUserService)
//     {
//     }

//     ngOnDestroy(): void
//     {
//         // Unsubscribe from all subscriptions
//         this.subs.unsubscribe();
//     }

//     /**
//      * Intercept
//      *
//      * @param req
//      * @param next
//      */
//     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
//     {
//         // Clone the request object
//         // let newReq = req.clone();
//         let newReq = req;
//         const redirectAttempt = this._router.url;
//         const expired = AuthUtils.isTokenExpired(this._authService.accessToken);
//         if (!expired) {
//             newReq = this.addTokenHeader(req, this._authService.accessToken)
//             // newReq = req.clone({
//             //     headers: req.headers
//             //                 .set('Authorization', 'Bearer ' + this._authService.accessToken)
//             //                 .set('domain', this._projectUserService.company)
//         };

//         // Response
//         return next.handle(newReq).pipe(
//             retry(1),
//             tap(() => {
//                 this.isRefreshing = true;
//             }),
//             catchError((error) => {
//                 // Catch "401 Unauthorized" responses
//                 if ( error instanceof HttpErrorResponse && error.status === 401)
//                 {
//                     console.log('auth.interceptor.ts -- catchError() 222222222222', error);
//                     return this._authService.generateRefereshToken()
//                     .pipe(
//                         switchMap((data: any) => {
//                             this.isRefreshing = false;

//                             // this.tokenService.saveToken(token.accessToken);
//                             // this.refreshTokenSubject.next(token.accessToken);
//                             this._authService.accessToken = data.access_token;
//                             this._authService.refreshToken = data.refresh_token;

//                             return next.handle(this.addTokenHeader(newReq, data.accessToken));
//                           }),
//                           catchError((err) => {
//                             this.isRefreshing = false;
//                             // this.tokenService.signOut();
//                             return throwError(err);
//                           })
//                     )
//                 }

//                 return next.handle(this.addTokenHeader(newReq, this._authService.accessToken));
//             })
//         );
//     }

//     private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
//         if (!this.isRefreshing) {
//             this.isRefreshing = true;

//         }
//     }

//     private addTokenHeader(request: HttpRequest<any>, token: string) {
//         /* for Spring Boot back-end */
//         return request.clone({ headers: request.headers
//                                     .set('Authorization', 'Bearer ' + token)
//                                     .set('domain', this._projectUserService.company) });

//         /* for Node.js Express back-end */
//         // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
//     }
// }

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectUserService } from 'app/shared/service/project-user.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AuthService,
        private _projectUserService: ProjectUserService,)
    {}

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem("id_token");
        //skip injection for this
        // if (req.url === "https://geolocation-db.com/json/") {
        //     return next.handle(req);
        // }

        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", idToken)
                                    .set('domain', this._authService.domain)// this._projectUserService.company)
            });

            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}

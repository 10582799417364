import Docxtemplater from 'docxtemplater';
import HTMLModule from 'docxtemplater-html-module';
import ImageModule from 'docxtemplater-image-module';
import ChartModule from 'docxtemplater-chart-module';
import DocxtemplaterMetaModule from 'docxtemplater-meta-module'

import saveAs from 'file-saver';
import moment from 'moment';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';

const expressions = require('angular-expressions'); //todo: issue heres
const assign = require('lodash/assign');  //todo: issue here

// ? Angular Parser Configuration -- This will allow us to use angular expressions, and solve expressions within docx templates
function loadFile(url: string, callback: any) {
    PizZipUtils.getBinaryContent(url, callback);   //todo: issue here
}

expressions.filters.lower = function (input) {
    if (!input) return input;
    return input.toLowerCase();
};

function angularParser(tag) {
    tag = tag
        .replace(/^\.$/, 'this')
        .replace(/(’|‘)/g, "'")
        .replace(/(“|”)/g, '"');
    const expr = expressions.compile(tag);
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        },
    };
}
// ----- uncommented above

import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { combineLatestWith, map, mergeMap, Observable, of, tap } from 'rxjs';
import { StreamState } from '../../../modules/interview-panel/models/stream-state';
import { base_component } from 'app/shared/components/base_component';
import { NotificationService } from 'app/shared/service/notification.service';
import { UserService } from 'app/core/user/user.service';
import { HelperService } from 'app/shared/service/helper.service';
import { InternalMessageService } from 'app/layout/layout.service';
import { DOCUMENT } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import { CurrentUser } from 'app/core/auth/user';
import { AssignInterviewerService } from 'app/shared/service/assign-interviewer.service';
import { DocumentService } from 'app/shared/service/document.service';
import { RiskService } from '../../../modules/interview-panel/services/risk.service';
import { DomainService } from '../../../modules/interview-panel/services/domain.service';
import { Engagement } from 'app/shared/model/engagement.types';
import { EngagementService } from 'app/shared/service/engagement.service';
import { RoleService } from 'app/shared/service/role.service';
import { MyCompanyService } from 'app/shared/service/my-company.service';
import { AssetService } from 'app/shared/service/asset.service';
import { ReportService } from '../../../modules/interview-panel/services/report.service';
import { ReportCoverService } from '../../../modules/interview-panel/services/report-cover.service';
import { FacilityService } from '../../../modules/interview-panel/services/facility.service';
import { MediaService } from 'app/shared/service/media.service';
import { FuseLoadingService } from '@fuse/services/loading';
import { RawTranscriptService } from 'app/modules/engagement/details/interview/raw-transcript.service';
import { userTypes } from 'app/shared/model/user-type-list';

@Component({
    selector: 'av-interviewee-panel',
    templateUrl: './interviewee-panel.component.html',
    styleUrls: ['./interviewee-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class IntervieweePanelComponent extends base_component implements OnInit, OnDestroy {
    @ViewChild('courseSteps', {static: true}) courseSteps: MatTabGroup;
    video: any;
    currentStep: number = 1;
    drawerOpened: boolean = true;

    // * RecordRTC Configuration
    recorder:any;
    project: Engagement;
    // *  Variables for recording
    files: Array<any> = [];
    state: StreamState | undefined;
    currentFile: any = {};
    playing = true;
    recording = false;
    recordObject: any;
    recordingURL: any;
    file: any;
    // * Form variables
    isLinear = false;
    formGroup : FormGroup;
    form: FormArray;
    questions: any[] = [];
    interviewActive = true;
    camEnabled = true;
    micEnabled = true;
    drawerFormOpened: boolean = true;
    drawerMode: 'over' | 'side' = 'side';
    roleTitle: string;
    selectQuestionNumber: string = '';
    currentStepControlGuid: string;
    roleId: number;
    isCampaign: boolean = false;
    hasNoCampaignAnswer: boolean = false;
    projectId: string = '';
    groupid: string = '';
    mediaGuidSentFromUser: number = 0;
    campaign: string = '';
    user_id: number;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _projectService: EngagementService,
        private _mediaService: MediaService,
        private _helper: HelperService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _roleService: RoleService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _internalMessageService: InternalMessageService,
        private _reportService: ReportService,  //testing
        private _reportCoverService: ReportCoverService, //testing
        private _facilityService: FacilityService, //testing
        private _docService: DocumentService,
        private _domainService: DomainService,
        private _assetService: AssetService,
        private _myCompanyService: MyCompanyService,
        private _assignInterviewerService: AssignInterviewerService,
        private _fuseLoadingService: FuseLoadingService,
        private _riskService: RiskService,
    ) {
        super(_notifyService, _userService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.campaign = this._route.snapshot?.paramMap?.get('campaign') || 'cmmc';
        this.projectId = this._route.snapshot.paramMap.get('projectid');
        this.roleId = +this._route.snapshot.paramMap.get('roleid');
        this.groupid = this._route.snapshot.paramMap.get('groupid');

        this.listenToMessage();
        this.getCurrentUser();
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    // ? -----------------------------------------------------------------------------------------------------
    // * @ Public methods
    // ? -----------------------------------------------------------------------------------------------------

    selectQuestion(question: any, index: number): void {
        this.selectQuestionNumber = `Question ${index + 1}`;
        this._internalMessageService.sendMessage({name:'interview', data: {message: question, selected: this.selectQuestionNumber} });
    }

    assessorView(): void {
        this._router.navigate([`/rapidassess/assessor/${this.campaign}/${this.project.guid}`]);
    }

    getCurrentQuestion(): any {
        this.currentStep = this.questions.findIndex((x:any) => x.control_guid === this.currentStepControlGuid);
        this.currentStep += 1;
        this.courseSteps.selectedIndex = this.currentStep;

        this.goToStep(this.currentStep - 1);

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    backToLastQuestion(): void
    {
        this.subs.sink = this._mediaService.getLastSavedQuestion(this.project.guid, this.roleId)
        .pipe(
            map((val: any) => val?.data[0]  || [])
        )
        .subscribe((result: any) => {
            if (result && result?.length > 0) {
                const lastQuestion = this.questions.find((x:any) => x.question_number == result[0].question_number);
                this.currentStep = lastQuestion.question_order_id;
                this._scrollCurrentStepElementIntoView();
            }
        });
    }

     goToStep(step: number): void
     {
        const currentQuestion = this.questions.find((x:any) => x.question_order_id === step);
        this._internalMessageService.sendMessage({
            name:'interview',
            data: {message: currentQuestion}
            // data: {message: question, selected: this.selectQuestionNumber}
        });

         // Set the current step
         if (step > 0) {
            this.currentStep = step;
         }
         // Mark for check
         this._changeDetectorRef.markForCheck();
     }

     goToPreviousStep(): void
     {
         // Return if we already on the first step
         if ( this.currentStep === 0 )
         {
             return;
         }
         this.goToStep(this.currentStep - 1);

         // Scroll the current step selector from sidenav into view
         this._scrollCurrentStepElementIntoView();
     }

     goToNextStep(): void
     {
         // Return if we already on the last step
         if ( this.currentStep === this.questions.length)
         {
             return;
         }
         this.goToStep(this.currentStep + 1);

         // Scroll the current step selector from sidenav into view
         this._scrollCurrentStepElementIntoView();
     }

     showGoToNextStep(): boolean {
        return false;
     }

     removeHtml(text:string): string{
        return this._helper.removeHTMLTags(text).replace('<br>','');
     }

     trackByFn(index: number, item: any): any
     {
         return item.id || index;
     }

    // ? -----------------------------------------------------------------------------------------------------
    // * @ Private methods
    // ? -----------------------------------------------------------------------------------------------------
    private _scrollCurrentStepElementIntoView(): void
    {
        // Wrap everything into setTimeout so we can make sure that the 'current-step' class points to correct element
        setTimeout(() => {

            //Get the current step element and scroll it into view
            const currentStepElement = this._document.getElementsByClassName('current-step')[0];
            if ( currentStepElement )
            {
                currentStepElement.scrollIntoView({
                    behavior: 'smooth',
                    block   : 'start'
                });
            }
        });
    }

    private getProject(): void {
        this.subs.sink = this._projectService.getEngagementById(this.projectId)
            .pipe(
                // map((val: any) => val?.data[0] || [])
            )
            .subscribe((project: Engagement) => {
                this.project = project[0];

                if (this.currentUser.roles.find((x:any) => x.id === userTypes.interviewee.id)) {
                    if (this.project.user_id != this.currentUser.id) {
                        this._router.navigate(['/dashboard/interviewee/list']);
                    }
                }

                this.fetchMediaQuestions();
                this.getRoles();
                this.isCampaign = this.project.campaign_guid ? true : false;
            });
    }

    private fetchMediaQuestions(): void {
        // const roleId = +this._helper.getProjectId(this._router.url, 'role');
        if (this.groupid) {
            this.subs.sink = this._mediaService.getMediaQuestionByStandardAndRoleCampaign(this.project.standard_id, this.roleId, this.project.guid, this.groupid)
            .subscribe((result: any) => {
                if (result) {
                    this.questions = result.map((x:any, index) => {
                        return {question_order_id: index +1, ...x}
                    });

                    this.backToLastQuestion();

                    // this.questions = this._helper.removeDuplicateObjectByProperty(result, 'order_id');
                    this.selectQuestionNumber = `Question 1`;
                    this._internalMessageService.sendMessage({name:'interview', data: {message: result[0]} });
                    // this._changeDetectorRef.markForCheck();
                }
            });
        }
        else {
            this.subs.sink = this._mediaService.getMediaQuestionByStandardAndRole(this.project.standard_id, this.roleId, this.project.guid)
            .subscribe((result: any) => {
                if (result) {
                    this.questions = result.map((x:any, index) => {
                        return {question_order_id: index +1, ...x}
                    });

                    this.backToLastQuestion();

                    // this.questions = this._helper.removeDuplicateObjectByProperty(result, 'order_id');
                    this.selectQuestionNumber = `Question 1`;
                    this._internalMessageService.sendMessage({name:'interview', data: {message: result[0]} });
                    // this._changeDetectorRef.markForCheck();
                }
            });
        }

    }

    private getRoles(): void {
        // const roleId = this._helper.getProjectId(this._router.url, 'role');

        this.subs.sink = this._roleService.getRoles(this.project.guid)
        .pipe(
            // map((val: any) => val?.data[0]  || []),
        )
        .subscribe((roles: any) => {
            this.roleTitle = roles.find((x:any) => x.id == this.roleId).interview_area;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    getCurrentUser(): void {
        this.subs.sink = this._userService.currentUser$
        .pipe(
            map((val: any) => val ?
                (Array.isArray(val) ? val[0] : val) : null)
        )
        .subscribe((x: CurrentUser) => {
            this.currentUser = x;
            this.getProject();

            this.hasNoCampaignAnswer = false;

            this._changeDetectorRef.detectChanges();
        });
    }

    private listenToMessage(): void {
        this.subs.sink = this._internalMessageService.getMessage()
            .subscribe((arg: any) => {
                if (arg.name === 'interview') {
                    if (arg.data.action === 'next') {
                        this.mediaGuidSentFromUser = arg.data.question_order_id;
                        this._changeDetectorRef.detectChanges();
                        this.goToNextStep();
                    }
                    if (arg.data.action === 'prev') {
                        this.goToPreviousStep();
                    }
                    // if (arg.data.action === 'back') {
                    //     // const lastQuestion = this.questions.find((x:any) => x.question_number == arg.data.question_number);
                    //     // lastQuestion.media_guid = arg.data.media_guid;

                    //     const lastQuestion = this.questions.find((x:any) => x.question_number == arg.data.question_number);
                    //     // lastQuestion.media_guid = arg.data.media_guid;
                    //     console.log("3 ~ IntervieweePanelComponent ~ .subscribe ~ lastQuestion:", lastQuestion.question_order_id)
                    //     this.goToStep(lastQuestion.question_order_id);
                    // }
                }
            });
    }

    // ? -----------------------------------------------------------------------------------------------------
    // * @ Campaign only
    // ? -----------------------------------------------------------------------------------------------------

    async generateReport(): Promise<void> {
        this._fuseLoadingService.show();
        if (this.project) {
            //Test ONLY ------------------------------------------------------
            // const generatedReportFileName = 'SOC 2 Report';
            // const reportUri = `../assets/${generatedReportFileName}.docx`;
            // this.createReport(reportUri, generatedReportFileName);

            // ----------------------------------------------------------------
            let env = this._helper.getEnvironment() || 'dev';
            env = this._helper.getEnvironment();
            // const reportFileName = this._reportService.getReportFile(this.project.standard_id, this.project.assessment_type_id);
            // const generatedReportFileName = `${reportFileName}.docx`;
            const generatedReportFileName = `CMMC Self Assessment Report.docx`;

            let folder = '';
            this.subs.sink = this._myCompanyService.getCompanyById(this.currentUser.company_id)
            .pipe(
                tap((company: any) => {
                    // const companyName = this.currentUser.db_name ? this.currentUser.db_name : 'aurora_audit_signal';
                    const companyName = 'aurora_audit_signal';
                    folder = `${env}/${companyName}/reports/${generatedReportFileName}`;
                }),
                mergeMap(() => this._docService.getGetSignedUrl(folder, 'auroraaudit-customers')),
            )
            .subscribe((result: any) => {
                const reportUri = result.data;
                this.createReport(reportUri, generatedReportFileName);
            });
        }
        else {
            this._notifyService.showWarning('', 'Could not load project information.');
            this._fuseLoadingService.hide();
            this._changeDetectorRef.detectChanges();
        }
    }

    public getCompliant = function(assesmentGroup:any[], prop:string, total:number){
        const data = [];
        assesmentGroup.reduce( function(a, b){
           if (b.name != 'TOTALS' && b.name != '%') {
                return data.push(b[prop] ? (b[prop]/total * 100) : 0);
           }
        }, 0);
        return data;
    };

    public getNonCompliant = function(assesmentGroup:any[], total:number){
        const data = [];
        assesmentGroup.reduce( function(a, b){
           if (b.name != 'TOTALS' && b.name != '%') {
                return data.push(
                    (b['nonCompliant'] ?
                        ((b['nonCompliant'] +
                        b['notApplicable'] +
                        b['partial'])/total * 100) : 0)
                );
           }
        }, 0);
        return data;
    };

    public formatChartData(uniqueAssessments: any[], assesmentGroup: any[]): any {
        const total = assesmentGroup.find(x => x.name === 'TOTALS')?.totals || 0;
        const series = [];
        series.push({
            "title": "Met",
            "data": this.getCompliant(assesmentGroup, 'compliant', total)
        });
        series.push({
            "title": "Not Met",
            "data": this.getNonCompliant(assesmentGroup, total)
        })
        const reportData = {
            "categories": uniqueAssessments,
            "series": series
        };
        return reportData;
    }

    async createReport(reportFileUri:string, generatedReportFileName: string): Promise<void> {

        const reportInfo$ = this._reportCoverService.getReportInfo(this.projectId)
                                    .pipe(map((val: any) => val?.data ? val?.data[0][0] : []));
        // const revision$ =  this._revision.getRevisions(this.projectId);
        const revision$ =  of([]);
        const facilities$ =  this._facilityService.getFacilities(this.projectId);
        const application$ = this._assetService.getAssets(this.projectId)
                                .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        const interviewee$ = this._assignInterviewerService.getAssignIntervieweeList(this.projectId)
                                .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        const documents$ = this._docService.getDocuments(this.projectId)
                                .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        const securityRisks$ = this._riskService.getAllRisks(this.projectId);
        //use interview vs interview_control
        const detailedFindings$ = this._reportService.getdetailedFindings(this.projectId)
                                .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        //use interview vs interview_control
        const assessments$ = this._reportService.getAssessments(this.projectId)
                                .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        const criticals$ = this._assetService.getAssets(this.projectId)
                                    .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        const documentationReview$ = this._reportService.documentationReview(this.projectId)
                                    .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        //use interview vs interview_control
        const avgDomain$ = this._domainService.getAvgDomains(this.projectId)
                                    .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        const evidenceCommentAndDomain$ = this._domainService.getEvidenceCommentAndDomains(this.projectId)
                                    .pipe(map((val: any) => val?.data ? val?.data[0] : []));
        // const reportDataService$ = this._reportDataService.getChartData();

        let reportData:any = {};
        // Combine the changes by adding them together
        this.subs.sink = revision$.pipe(
            combineLatestWith(facilities$, application$, interviewee$, documents$, securityRisks$, reportInfo$, detailedFindings$,
                assessments$, criticals$, documentationReview$, avgDomain$, evidenceCommentAndDomain$),
        )
        .subscribe((x:any) => {
            this._fuseLoadingService.hide();
            this._changeDetectorRef.detectChanges();

            let facilityIndex = 1;
            reportData.facilities = x[1].map((x:any) => {
                return {...x, id: facilityIndex++}
            });
            let appIndex = 1;
            // reportData.applications = x[2];
            reportData.applications = x[2].map((x:any) => {
                return {...x, id: appIndex++}
            });
            let intervieweeIndex = 1;
            // reportData.interviewee = x[3];
            reportData.interviewee = x[3].map((x:any) => {
                return {...x,
                        id: intervieweeIndex++,
                        name: x.first_name ? `${x.first_name} ${x.last_name}` : x.email.split('@')[0].replace('.', ' ')
                    }
            });
            // console.log('2--------------', reportData);
            // reportData.documents = x[4];
            let documentIndex = 1;
            reportData.documents = x[4].map((x:any) => {
                return {...x,
                        id: documentIndex++
                    }
            });

            let securityRisksIndex = 1;
            reportData.securityRisks = x[5].map((x:any) => {
                x.id = securityRisksIndex++;
                x.narrative = x.narrative ? x.narrative.replace(/(<([^>]+)>)/gi, " ") : 'N/A';
                x.risk_description = x.risk_description ? x.risk_description.replace(/(<([^>]+)>)/gi, " ") : 'N/A';
                x.recommendation = x.recommendation ? x.recommendation.replace(/(<([^>]+)>)/gi, " ") : 'N/A';
                x.moscow = x.moscow || 'N/A';
                x.residual_impact = this._helper.impactToWord(x.residual_impact) || 'N/A';
                x.residual_likelihood = this._helper.impactToWord(x.residual_likelihood) || 'N/A';
                x.residual_risk_score = this._helper.getLevel(x.residual_risk_score)["text"];
                x.discrete_risk_score = this._helper.getLevel(x.discrete_risk_score)["text"];
                x.discrete_controls_score = this._helper.getLevel(x.discrete_controls_score)["text"];
                x.discrete_risk_exposure = this._helper.getLevel(x.discrete_risk_exposure)["text"];
                x.control_id_name = x.control_id_name;
                x.threat_sources = x.threats?.split('||').map(m => {
                    const row = m.split('|');
                    return {
                        category:  row[0],
                        threat: row[1]
                    }
                });
                return x;
            });
            reportData.residualRisk = reportData.securityRisks.map((x:any) => {
                x.control_id_name = x.control_id_name.split(',').length > 5 ? 'Multiple': x.control_id_name;
                return x;
            });

            const complianceList = this._helper.getComplianceList(this.project.standard_id);
            function getCompliace(compliance): string {
                switch (compliance) {
                    case 1:
                        return complianceList[0].name;
                    case 2:
                        return complianceList[1].name;
                    case 3:
                        return complianceList[2].name;
                    case 4:
                        return complianceList[3].name;
                    case 5:
                        return complianceList[4].name;
                    default:
                        return 'N/A';
                }
            }

            reportData.info = x[6];
            const detailedFindings = x[7];
            reportData.detailedFindings = detailedFindings.map((x:any) => {
                x.findings = x.findings ? x.findings.replace(/(<([^>]+)>)/gi, " ") : 'N/A';
                x.filenames = x.filenames ? x.filenames : 'N/A';
                x.comments = x.comments ? x.comments : 'N/A';
                x.safeguard = x.safeguard ? x.safeguard?.replace(/(<([^>]+)>)/gi, " ") : 'N/A';
                x.recommendation = x.recommendation ? x.recommendation?.replace(/(<([^>]+)>)/gi, " ") : 'N/A';
                x.compliant = x.compliance ? getCompliace(x.compliance) : 'N/A';
                x.control_id_name = x.control_id_name;
                return x;
            });
            reportData.recommendations = detailedFindings;
            let assesmentData = [];
            let assesmentIndex = 1;
            x[8].map((x:any) => {
                return assesmentData.push({...x, id: assesmentIndex++})
            });
            console.log('assesmentData-1:', assesmentData)
            let groupBy = function(xs:any, key:any) {
                    return xs.reduce(function(rv:any, x:any) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;
                    }, {});
                }
            let assesmentGroup = [];
            let assesmentList = [];
            assesmentData.forEach((group:any) => {
                assesmentList.push(group.domain);
            });
            console.log('assesmentList-2:', assesmentList)
            const uniqueAssessments = (Array.from(new Set(assesmentList)));
            // console.log('+++++++++++++++++++++>', uniqueAssessments, assesmentData)
            uniqueAssessments.forEach(assessment => {
                const keyGroup = groupBy(assesmentData, 'domain');
                const compliantTotal = keyGroup[assessment]?.filter((x:any) => x.compliance === 1)[0]?.count || 0;
                const partialTotal = keyGroup[assessment]?.filter((x:any) => x.compliance === 2)[0]?.count || 0;
                const nonCompliantTotal = keyGroup[assessment]?.filter((x:any) => x.compliance === 3)[0]?.count || 0;
                const notApplicable = keyGroup[assessment]?.filter((x:any) => x.compliance === 4)[0]?.count || 0;
                const domainTotal = assesmentData.find((x:any) => x.domain == assessment)?.totals || 0;
                assesmentGroup.push(
                    {
                        name: assessment,
                        compliant: compliantTotal,
                        partial: partialTotal,
                        nonCompliant: nonCompliantTotal,
                        notApplicable: notApplicable,
                        totals: domainTotal
                    }
                );
            });
            //totals
            assesmentGroup.push({
                name: `TOTALS`,
                compliant: assesmentGroup.reduce((accumulator, object) => {
                    return accumulator + object.compliant;
                }, 0) || 0,
                partial: assesmentGroup.reduce((accumulator, object) => {
                    return accumulator + object.partial;
                }, 0) || 0,
                nonCompliant: assesmentGroup.reduce((accumulator, object) => {
                    return accumulator + object.nonCompliant;
                }, 0) || 0,
                notApplicable: assesmentGroup.reduce((accumulator, object) => {
                    return accumulator + object.notApplicable;
                }, 0) || 0,
                totals: assesmentGroup.reduce((accumulator, object) => {
                    return accumulator + object.totals;
                }, 0) || 0
            });
            const totalsRow = assesmentGroup.length - 1;
            console.log('test >>', totalsRow, assesmentGroup  )
            assesmentGroup.push({
                name: `By Percentage`,
                compliant: ((assesmentGroup[totalsRow]?.compliant / assesmentGroup[totalsRow].totals) * 100)?.toFixed() + '%',
                partial: ((assesmentGroup[totalsRow]?.partial / assesmentGroup[totalsRow].totals) * 100)?.toFixed() + '%',
                nonCompliant: ((assesmentGroup[totalsRow]?.nonCompliant / assesmentGroup[totalsRow].totals) * 100)?.toFixed() + '%',
                notApplicable: ((assesmentGroup[totalsRow]?.notApplicable / assesmentGroup[totalsRow].totals) * 100)?.toFixed() + '%',
                totals: ''
            });
            reportData.assessments = assesmentGroup;

            let criticalIndex = 1;
            reportData.criticals = x[9].map((x:any) => {
                return {...x, id: criticalIndex++}
            });

            let docReviewIndex = 1;
            reportData.docReviews = x[10].map((x:any) => {
                return {...x, id: docReviewIndex++}
            });

            reportData.domain = x[11];
            reportData.control = x[12];
            // const reportChart:any = x[13];
            reportData.chart1 = this.formatChartData(uniqueAssessments, assesmentGroup);
            reportData.reportName = "CMMC Self Assessment" // this.setupForm.get('report_name').value;
            reportData.teamName = ""; //this.setupForm.get('team_name').value;
            reportData.version = 1; //this.setupForm.get('report_version').value;
            reportData.overallFindings = "";// this.setupForm.get('conclusion').value;
            reportData.servicesDescription = ""; //this.setupForm.get('servicesDescription').value;

            loadFile(
                reportFileUri,
                function (error, content) {
                    if (error) {
                        console.error(error);
                        return;
                    }
                    const imageOpts = {
                        centered: false,
                        getImage: function (tagValue: any, tagName: string) : Promise<string> {
                            const result : Promise<string> = new Promise(function (resolve, reject) {
                                PizZipUtils.getBinaryContent(
                                    tagValue,
                                    function (error: Error, content: string) {
                                        if (error) {
                                            reject(error);
                                        }
                                        resolve(content);
                                    }
                                );
                            });
                            return result;
                        },
                        getSize: function (imgData: any, tagValue: any, tagName: string): [number,number] {
                            return [150, 150];
                        },
                        getProps: function (img: any, tagValue: any, tagName: any): any {
                            return {
                                position: 'right',
                                layout: 'fixed',
                            };
                        },
                    };
                    const htmlModuleOptions = {
                        styleSheet: ``,
                    };
                    // * Doc generation
                    const zip = new PizZip(content);
                    const metaModule = new DocxtemplaterMetaModule();
                    const doc = new Docxtemplater(zip, {
                        modules: [
                            new ImageModule(imageOpts),
                            new HTMLModule(htmlModuleOptions),
                            new ChartModule(),
                            metaModule
                        ],
                        // parser: angularParser,
                    });
                    const today = moment();
                    const date = today.format('MM/DD/YYYY');
                    const auditor = reportData.info.auditors;
                    // if (!this.isCampaign) {
                    //     metaModule.addTextWatermark("Self Assessment", {
                    //         diagonal: true,
                    //     });
                    // }
                    const that = this;
                    doc.renderAsync({
                        companyName: reportData.info.name,
                        companyLogo: reportData.info.logo_uri,
                        reportVersion: reportData.version,
                        teamName: reportData.teamName,
                        overallFindings: reportData.overallFindings,
                        servicesDescription: reportData.servicesDescription,
                        auditorName: 'Automated Assessed',
                        auditorEmail: 'Automated Assessed',
                        systemName: 'FedShark',
                        reportDate: date,
                        projectName: reportData.reportName || 'N/A',
                        // projectStandard: projectInfo.projectStandard,
                        // projectAssessmentType: projectInfo.projectAssessmentType,

                        // * Revision table (Page 2)
                        // ? Available in all reports
                        // revision: reportData.revisions,

                        // * Assessment Scope (Page 3)
                        // ? Available in most reports *
                        documentsCount: reportData.documents.length,
                        intervieweesCount: reportData.interviewee.length,
                        applicationsCount: reportData.applications.length,
                        facilitiesCount: reportData.facilities.length,

                        // * Assessment Results (Page 5)
                        // ? Available in HIPAA reports
                        assessmentList: [],

                        // * Assessment Results (Page 5) - HIPAA
                        // ? Available in HIPAA reports
                        hipaaAssessmentList: [],
                        criticals: reportData.criticals,

                        chart1: reportData.chart1,

                        // * Findings Table (Page 6)
                        // ? Available in most reports *
                        findings: reportData.securityRisks,

                        // * Residual Risks Table (Page 6)
                        // ? Available in most reports *
                        residualRisk: reportData.residualRisk,

                        assessments: reportData.assessments,

                        // * Appendix detailed findings
                        // ? Available in all reports
                        // ? HIPAA
                        detailedFindings: reportData.detailedFindings,
                        recommendations: reportData.recommendations,

                        // * Interview List
                        // ? Available in most risk reports
                        // ? HIPAA
                        interviewees: reportData.interviewee,

                        // * Evidence list
                        // ? Available in most risk reports
                        // ? HIPAA
                        evidences: reportData.documents,

                        // ? HIPAA
                        facilities: reportData.facilities,

                        docReviews: reportData.docReviews,

                        domain: reportData.domain,
                        control: reportData.control,

                        // * Cricials table
                        // ? Available in most risk reports
                        // ? HIPAA
                        applications: reportData.applications,

                        // * Remediation Timeline Table:
                        remediationTimelineTable: [],

                    }).then(function (result) {
                        const docxType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                        const out = doc.getZip().generate({
                            type: 'blob',
                            mimeType: docxType,
                        });

                        saveAs(out, generatedReportFileName);

                        if (result.error) {
                            that._notifyService.showError('','Failed to generate report!');
                        }
                        else {
                            that._notifyService.showSuccess('','Successfully generated report, please check your download folder.');
                        }
                        this._fuseLoadingService.hide();
                        that._changeDetectorRef.detectChanges();
                    });
                }
            );
        });
    }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const PROJECT_ID = 'auroraaudit-llc';
export const PROJECT_API = 'https://xev-api.fedshark.com/';
// export const PROJECT_API= 'http://localhost:3100/';
export const environment = {
  production: false,
  mfaOn: false
};

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';
import { PROJECT_API } from 'environments/environment';
import { Asset, IAsset } from '../model/asset.types';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _asset: BehaviorSubject<IAsset | null> = new BehaviorSubject<IAsset | null>(null);
    private _assets: BehaviorSubject<IAsset[] | null> = new BehaviorSubject<IAsset[] | null>(null);

    constructor(private http: HttpClient) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for roles
     */
     get assets$(): Observable<Asset[] | null>
     {
         return this._assets.asObservable();
     }

     get asset$(): Observable<Asset | null>
     {
         return this._asset.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Assets
     */
     getAssets(engagementId: string): Observable<any>
     {
         return this.http.get<IAsset[]>(this.apiURL + 'assets/engagement/' + engagementId ).pipe(
             tap((response: any) => {
                 this._assets.next(response);
             })
         );
     }

    /**
     * Get Asset
     */
     getAsset(id: string): Observable<IAsset[]>
     {
         return this.http.get<IAsset[]>(this.apiURL + 'assets/' + id ).pipe(
            map((val: any) => val?.data[0] || []),
            tap((response: any) => {
                this._asset.next(response[0]);
            })
         );
     }

    // HttpClient API post() method => Create employee
    createAsset(asset: any): Observable<any> {
        return this.http.post<IAsset>(this.apiURL + 'assets', JSON.stringify(asset), this.httpOptions)
        .pipe(
            // retry(1),
            catchError(this.handleError)
        );
    }

    // HttpClient API post() method => Create employee
    updateAsset(asset: any): Observable<any> {
        return this.http.put<IAsset>(this.apiURL + `assets/${asset.id}`, JSON.stringify(asset), this.httpOptions)
        .pipe(
            // map((val: any) => val?.data[0] || []),
            // tap((response: any) => {
            //     this._asset.next(response[0]);
            // }),
            // retry(1),
            catchError(this.handleError)
        );
    }

    deleteAsset(guid: string): Observable<any> {
        return this.http.delete<any>(this.apiURL + `assets/delete/${guid}`, this.httpOptions)
        .pipe(
            // retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, retry, switchMap, take, tap, throwError } from 'rxjs';
import { PROJECT_API } from 'environments/environment';
import { IDoc } from 'app/shared/model/doc.model';
import { AuthService } from 'app/core/auth/auth.service';
import { ProjectUserService } from './project-user.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

    // httpOptions = {
    //     headers: new HttpHeaders({
    //         'Authorization': this._authService.accessToken,
    //         'domain': this._projectUserService.company
    //     })
    // };
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    };

    apiURL = PROJECT_API + 'api/';
    private _evidence: BehaviorSubject<IDoc | null> = new BehaviorSubject<IDoc | null>(null);
    private _evidences: BehaviorSubject<IDoc[] | null> = new BehaviorSubject<IDoc[] | null>(null);

    constructor(private http: HttpClient,
        private _authService: AuthService,
        private _projectUserService: ProjectUserService) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get evidences$(): Observable<IDoc[] | null>
    {
        // console.log('Evidences?............', this._evidences);
        return this._evidences.asObservable();
    }

    get evidence$(): Observable<IDoc | null>
    {
        return this._evidence.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    downloadZip(folderName: string): Observable<any[]>
    {
        const httpOptions = {
            responseType: 'blob' as 'json'
        };
        return this.http.post<any>(this.apiURL + `documents/zip`, {folderName}, httpOptions).pipe(
            tap((response: any) => {
            })
        );
    }

    getEvidenceList(projectGuid: string): Observable<IDoc[]>
    {
        return this.http.get<IDoc[]>(this.apiURL + 'evidences/' + projectGuid ).pipe(
            tap((response: any) => {
                this._evidence.next(response);
            })
        );
    }

    getTPRMDocuments(projectGuid: string): Observable<IDoc[]>
    {
        return this.http.get<IDoc[]>(this.apiURL + 'evidences/engagement/tprm/documents/' + projectGuid ).pipe(
            tap((response: any) => {
                this._evidence.next(response);
            })
        );
    }

    getGetSignedUrl(key: string, bucket: string = ''): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `documents/file/signedurl?bucket=${bucket}&key=${key}`);
    }

    getDocuments(engagementId: string): Observable<IDoc[]>
    {
        return this.http.get<IDoc[]>(this.apiURL + 'evidences/engagement/' + engagementId ).pipe(
        retry(1),
            tap((response: any) => {
                this._evidence.next(response);
            })
        );
    }

    uploadFileData(data: any, uri: string = 'evidences/filedata'): Observable<any> {
        return this.http.post(this.apiURL + uri, data, this.httpOptions)
        .pipe(
            tap((response: any) => {
                console.log("🚀 ~ tap ~ response:", response)
            }),
            catchError(this.handleError)
        );
    }

    /**
     * Get item by id
    */
    getDocumentById(id: string): Observable<IDoc>
    {
        return this._evidences.pipe(
            take(1),
            map((items: any) => {
                // Find within the folders and files
                const item = items?.find((x:any) => x.id == id) || null;
                // Update the item
                this._evidence.next(item);

                // Return the item
                return item;
            }),
            switchMap((item) => {

                if ( !item )
                {
                    return throwError('Could not found the item with id of ' + id + '!');
                }

                return of(item);
            })
        );
    }

    //fileType: get reports file or just get evidence files
    getDocumentByEngagementId(projectGuid: string, fileType: string = 'evidences'): Observable<IDoc[]>
     {
         return this.http.get<IDoc[]>(this.apiURL + `evidences/engagement/${projectGuid}?type=${fileType}`).pipe(
            retry(1),
            map((val: any) => val.data ? val.data[0] : []),
             tap((response: any) => {
                 this._evidences.next(response);
             })
         );
     }

    getEvidenceByControlId(projectId: string, controlId: string): Observable<any[]>
    {
        return this.http.get<IDoc[]>(`${this.apiURL}evidences/engagement/${projectId}/control/${controlId}`).pipe(
            retry(1),
            tap((response: any) => {
                this._evidences.next(response);
            })
        );
    }

    addDocuments(form: any): Observable<any> {
        return this.http.post<any>(`${this.apiURL}evidences`, form )
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    mapEvidences(evidence: any): Observable<any> {
        return this.http.post<any>(`${this.apiURL}evidences/control`, evidence)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    downloadEngagementListFile(standardId:number): void{
        switch (standardId) {
            case 27:  //ecfirst
                getPdf('HIPAA RA DCF May2024.xlsx');
                break;
            default:
                getPdf('Engagement-Preparation-Document.pdf');
                break;
        }

        function getPdf(docName: string) {
            var anchor = document.createElement('a');
            anchor.setAttribute('href', `assets/documents/${docName}`);
            anchor.setAttribute('download', '');
            document.body.appendChild(anchor);
            anchor.click();
            anchor.parentNode?.removeChild(anchor);
        }
    }

    downloadFile(guid: string): Observable<any>
    {
        return this.http.get<any>(this.apiURL + `evidences/download/${guid}` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    delete(evidence:any): Observable<IDoc[]>
     {
        return this.http.post(this.apiURL + `evidences/delete`, {evidence: evidence}).pipe(
            tap((response: any) => {
               //  this._document.next(response);
            })
        );
     }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }

    //todo: upload
    // uploadFileData(formData: any, uri: string = 'evidences/filedata'): Observable<any> {
    //     return this.http.post(`${this.apiURL}${uri}`, {formData}).pipe(
    //         tap((response: any) => {
    //            //  this._document.next(response);
    //         })
    //     );
    // }

    //used images as uri (why?)
    // uploadFile(formData: any, uri: string = 'evidences'): Observable<any> {
    //     return Axios.post(`${this.apiURL}${uri}`, formData, {
    //         headers: {
    //             'Authorization': this._authService.accessToken,
    //             'Content-Type': 'multipart/form-data',
    //             'domain': this._projectUserService.company
    //         }
    //     })
    //     .pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             this._evidences.next(response);
    //         })
    //     )
    // }

    // uploadVideo(file: any) {
    //     const formData = new FormData();
    //     // * Get the file name and add it to the form data
    //     const fileName = file.name.replace(' ','-');
    //     formData.append('fileName', fileName);
    //     formData.append('file', file);
    //     // * console log form data

    //     const result = axios.post(`${this.apiURL}images`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    //     return result;
    // }
}

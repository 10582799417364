


import { Component, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserService } from './core/user/user.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  dialogRef: any = null;

  constructor(
        private idle: Idle,
        private keepalive: Keepalive,
        private router: Router,
        private userService: UserService,
        private _fuseConfirmationService: FuseConfirmationService
    ) {
    // sets an idle timeout of 900 seconds (15 minutes)
    idle.setIdle(900);
    // sets a timeout period of 15 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(15);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
    //   console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    //   console.log(this.idleState);
    //   this.router.navigate(['/']);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        this.dialogRef = null;
        // Open the confirmation and save the reference
        this.dialogRef = this._fuseConfirmationService.open({
            title: this.idleState,
            message: 'Do you want to stay logged in?',
            actions: {
                confirm: {
                    show: true,
                    label: 'Stay Logged In',
                    color: 'primary'
                },
                cancel: {
                    show: true,
                    label: 'Logout'
                }
            }}
       );

        // Subscribe to afterClosed from the dialog reference
        this.dialogRef.afterClosed().subscribe((result) => {
            // console.log('afterClosed >> ', result);
            if (result === 'cancelled') {
                this.logout();
            }
            else {
                this.stay();
            }
        });

    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    //   console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.userService.getUserLoggedIn()
    .subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })

  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  stay() {
    this.reset();
  }

  logout() {
    this.userService.setUserLoggedIn(false);
    this.userService.currentUser = null;
    localStorage.clear();
    this.dialogRef = null;
    this.router.navigate(['/']);
    location.reload();
  }

}

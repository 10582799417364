import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectQuestionsService
{
    apiURL = PROJECT_API + 'api/';

    // Private
    private _answers: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _questions: BehaviorSubject<any[] | null> = new BehaviorSubject<any[] | null>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get questions$(): Observable<any>
    {
        return this._questions.asObservable();
    }

    get answers$(): Observable<any>
    {
        return this._answers.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    //

    getQuestions(projectId: string): Observable<any>
    {
        return this._httpClient.get(this.apiURL + `tprmassessmentquestion/${projectId}`)
        .pipe(
            retry(1),
            tap((response: any) => {
                this._questions.next(response);
            })
        );
    }

    // getAnswers(projectId: string): Observable<any>
    // {
    //     return this._httpClient.get(this.apiURL + `tprmassessmentquestion/${projectId}`)
    //     .pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             this._answers.next(response);
    //         })
    //     );
    // }

    postAnswer(data: any): Observable<any>
    {
        return this._httpClient.post(this.apiURL + 'tprmassessmentquestion', data);
    }
}

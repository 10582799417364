import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { SubSink } from 'subsink';
import { AuditInquiryService } from './auditInquiry.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NotificationService } from 'app/shared/service/notification.service';
import { UserService } from 'app/core/user/user.service';
import { v4 as uuid } from 'uuid';
import { base_component } from 'app/shared/components/base_component';
import { HelperService } from 'app/shared/service/helper.service';
import { Router } from '@angular/router';
import { InternalMessageService } from 'app/layout/layout.service';

@Component({
  selector: 'app-control-audit-inquiry',
  templateUrl: './control-audit-inquiry.component.html',
  styleUrls: ['./control-audit-inquiry.component.scss']
})
export class ControlAuditInquiryComponent extends base_component implements OnInit, OnDestroy {

    @Input() controlId: number = -1;
    @Input() controlGuid: string = '';

    auditInquiries = [];
    projectGuid: string = '';

    constructor(
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _helper: HelperService,
        private _router: Router,
        private _internalMessageService: InternalMessageService,
        private _auditInquiryService: AuditInquiryService,
        private _changeDetectorRef: ChangeDetectorRef) {
            super(_notifyService, _userService);
         }

    ngOnInit(): void {
        this.projectGuid = this._helper.getProjectId(this._router.url,'workflow');
        this.getAuditInquiries();
        this.getCurrentUser();
    }

    ngOnDestroy(): void
    {
       // Unsubscribe from all subscriptions
       this.subs.unsubscribe();
    }

    public toggle(auditInquiry:any, event: MatSlideToggleChange) {
        const data = {
            guid: auditInquiry.response_guid ? auditInquiry.response_guid : uuid(),
            response: event.checked ? 1 : 0,
            control_audit_inquiry_id: auditInquiry.id,
            control_id: this.controlId,
            project_id: this.projectGuid,
            user_id: this.currentUser.id,
            created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            updated_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
        }
        this.subs.sink = this._auditInquiryService.insertAuditInquiryResponse(data)
        .subscribe((result: any) => {
            // console.log("🚀 ~ ControlAuditInquiryComponent ~ .subscribe ~ result:", result)
            if (result.status === 'success') {
                this.getAuditInquiries();
                // this.updateComplianceOnInterviewForm(result);
                this._notifyService.showSuccess('','Audit inquiry response updated successfully.');
            }
            else {
                this._notifyService.showError('','Failed to update audit inquiry response.');
            }
        });
    }

    //update interview form from result
    // private updateComplianceOnInterviewForm(result): void {
    //     console.log('updateComplianceOnInterviewForm >>>>> ', result, this.auditInquiries)
    //     const auditInquiryTotal = 1;
    //     // if ()
    //     // this._internalMessageService.sendMessage({name:'auditInquiry', data: {message: {auditInquiryTotal: auditInquiryTotal}}});
    // }

    private getAuditInquiries(): void {
        if (this.controlId > 0) {
            this.subs.sink = this._auditInquiryService.getAuditInquiry(this.projectGuid, this.controlId)
                // .pipe(
                //     map((val: any) => val?.data && val?.data[0] || [])
                // )
                .subscribe((result: any) => {
                    if (result.status === 'success') {
                        if (result) {
                            this.auditInquiries = result.data[0];
                        }

                        this._changeDetectorRef.markForCheck();
                    }
                });
        }
    }

}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/no-auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},

    // Redirect signed in user to the '/dashboard' based on user's role
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module')
                .then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            // {path: 'reset-password',
            //     loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'invited',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path: 'survey',
                loadChildren: () => import('app/modules/survey/survey.module').then(m => m.SurveyModule)},
            //campaigns
            {path: 'rapidassess', loadChildren: () => import('app/modules/campaign/campaign.module').then(m => m.CampaignModule)}
        ]
        // { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)},
        ]
    },

    // Landing routes
    // {
    //     path: '',
    //     component  : LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children   : [
    //         // {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
    //         {path: 'home', loadChildren: () => import('app/modules/landing/home.module').then(m => m.LandingHomeModule)},
    //     ]
    // },

    // Project routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        // data: {
        //     layout: 'empty'
        // },
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            // Dashboards
            {path: 'chat', loadChildren: () => import('app/modules/chat/chat.module').then(m => m.ChatModule)},
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'dashboard/superuser', loadChildren: () => import('app/modules/dashboard/superuser/superuser.module').then(m => m.SuperuserModule)},
            {path: 'dashboard/projectmanager', loadChildren: () => import('app/modules/dashboard/manager/manager.module').then(m => m.ManagerModule)},
            {path: 'dashboard/supplierpoc', loadChildren: () => import('app/modules/dashboard/vendor-poc/vendor-poc.module').then(m => m.VendorPocModule)},
            {path: 'dashboard/supplieruser', loadChildren: () => import('app/modules/dashboard/vendor-user/vendor-user.module').then(m => m.VendorUserModule)},
            {path: 'dashboard/projectpoc', loadChildren: () => import('app/modules/dashboard/project-poc/project-poc.module').then(m => m.ProjectPocModule)},
            {path: 'dashboard/projectuser', loadChildren: () => import('app/modules/dashboard/project-user/project-user.module').then(m => m.ProjectUserModule)},
            {path: 'dashboard/assessor', loadChildren: () => import('app/modules/dashboard/auditor/auditor.module').then(m => m.AuditorModule)},
            {path: 'dashboard/qa', loadChildren: () => import('app/modules/dashboard/qa/qa.module').then(m => m.QaModule)},
            {path: 'dashboard/interviewee', loadChildren: () => import('app/modules/dashboard/interviewee/interviewee.module').then(m => m.IntervieweeModule)},
            {path: 'dashboard/selfassess', loadChildren: () => import('app/modules/dashboard/selfassess/selfassess.module').then(m => m.SelfassessModule)},
            {path: 'interview', loadChildren: () => import('app/modules/request-interview/request.module').then(m => m.RequestModule)},
            {path: 'interview-panel', loadChildren: () => import('app/modules/interview-panel/interview-panel.module').then(m => m.InterviewPanelModule)},
            {path: 'request', loadChildren: () => import('app/modules/interviewee-upload/interviewee.module').then(m => m.IntervieweeUploadModule)},
            {path: 'projects/board', loadChildren: () => import('app/modules/scrumboard/scrumboard.module').then(m => m.ScrumboardModule)},
            {path: 'projects', loadChildren: () => import('app/modules/engagement/engagement.module').then(m => m.EngagementModule)},
            {path: 'tprm/board', loadChildren: () => import('app/modules/scrumboard/scrumboard.module').then(m => m.ScrumboardModule)},
            {path: 'tprm', loadChildren: () => import('app/modules/tprm/tprm.module').then(m => m.ThirdpartyModule)},
            {path: 'messages', loadChildren: () => import('app/modules/mailbox/mailbox.module').then(m => m.MailboxModule)},

            // {path: 'custom', loadChildren: () => import('app/modules/custom/custom.module').then(m => m.CustomModule)},

            //account
            {path: 'my', loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)},

            //PAEI
            // {path: 'paei', loadChildren: () => import('app/modules/paei/paei.module').then(m => m.PaeiModule)},

            //help
            {path: 'help-center', loadChildren: () => import('app/modules/help-center/help-center.module').then(m => m.HelpCenterModule)},
            {path: 'contact', loadChildren: () => import('app/modules/contact-sales/contact.module').then(m => m.ContactSalesModule)},

            // Error
            {path: 'error', children: [
                {path: '404', loadChildren: () => import('app/modules/error/error-404/error-404.module').then(m => m.Error404Module)},
                {path: '500', loadChildren: () => import('app/modules/error/error-500/error-500.module').then(m => m.Error500Module)}
            ]},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    },

    { path: '**', redirectTo: '404-not-found', pathMatch: 'full' }
    // { path: '**', loadChildren: () => import('app/modules/landing/home.module').then(m => m.LandingHomeModule)}
];


import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, mergeAll, distinctUntilChanged, debounceTime, retry  } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';
import { User } from './user';
import { HelperService } from 'app/shared/service/helper.service';
import { ProjectUserService } from 'app/shared/service/project-user.service';
import { PROJECT_API, environment } from 'environments/environment';
import * as moment from "moment";
// import { MessagesService } from 'app/layout/common/messages/messages.service';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    signInResponse: any;

    apiURL = PROJECT_API + 'api/';
    /**
     * Constructor
     */
    constructor(
        public router: Router,
        public ngZone: NgZone, // NgZone service to remove outside scope warning
        private _userService: UserService,
        private productUserService: ProjectUserService,
        private _router: Router,
        private http: HttpClient
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.removeItem('id_token');
        localStorage.setItem('id_token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('id_token') ?? '';
    }

    set domain(domain: string)
    {
        localStorage.removeItem('domain');
        localStorage.setItem('domain', domain);
    }

    get domain(): string
    {
        return localStorage.getItem('domain') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    // set refreshToken(token: string)
    // {
    //     sessionStorage.removeItem('refreshToken');
    //     sessionStorage.setItem('refreshToken', token);
    // }

    // get refreshToken(): string
    // {
    //     return sessionStorage.getItem('refreshToken') ?? '';
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    setLocalStorage(responseObj:any) {
        // Adds the expiration time defined on the JWT to the current moment
        const expiresAt = moment().add(Number.parseInt(responseObj.expiresIn), 'days');

        this._authenticated = true;
        localStorage.setItem('id_token', responseObj.token);
        localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
        localStorage.setItem('domain', responseObj.company_guid);
    }

    setSessionStorage(responseObj:any) {

        // Adds the expiration time defined on the JWT to the current moment
        const expiresAt = moment().add(Number.parseInt(responseObj.expiresIn), 'days');

        this._authenticated = true;
        sessionStorage.setItem('id_token', responseObj.token);
        sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
    }

    logout() {
        this._authenticated = false;
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration(), "second");
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration = localStorage.getItem("expires_at");
        if (expiration) {
            const expiresAt = JSON.parse(expiration);
            return moment(expiresAt);
        } else {
            return moment();
        }
    }

    verifyMail(data: any): Observable<any>
    {
        return this.http.post<any>(this.apiURL + `auth/verifymail`, data)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendVerificationMail(data: any): Observable<any>
    {
        return this.http.post<any>(this.apiURL + `auth/sendEmailVerification`, data)
        .pipe(
            catchError(this.handleError)
        );
    }


    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        const body = {email: email};
        return this.http.post<any>(this.apiURL + `auth/forgotPassword`, body)
        .pipe(
            catchError(this.handleError)
        );
    }

    signUp(registerInfo: any): Observable<any>
    {
        return this.http.post<any>(this.apiURL + `auth/signup`, registerInfo)
        .pipe(
            catchError(this.handleError)
        );
    }

    register(registerInfo: any): Observable<any>
    {
        return this.http.post<any>(this.apiURL + `auth/register`, registerInfo)
        .pipe(
            catchError(this.handleError)
        );
    }

    // signUpSa(registerInfo: any): Observable<any>
    // {
    //     return this.http.post<any>(this.apiURL + `auth/signup/sa`, registerInfo)
    //     .pipe(
    //         catchError(this.handleError)
    //     );
    // }

    /**
     * Reset password
     *
     * @param password
     */
    // resetPassword(email: string): Observable<any>
    // {
    //     return from(this.afAuth.sendPasswordResetEmail(email).then(() => {
    //         this.router.navigate(['forgot-password']);
    //     }).catch((error) => {
    //         // this.notifier.showError(error.message);
    //     }));
    // }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string, ipAddress: string, subdomain: string }): Observable<any>
    {
        if ( this._authenticated )
        {
            this._router.navigateByUrl('/dashboard');
        }
        let user = null;
        const headers = new HttpHeaders({'Content-type': 'application/json'});

        return this.http.post(this.apiURL + 'auth/login', credentials, { headers: headers })
        .pipe(
            tap((response: any) => {
                if (response.status === 'failed') return;

                this._authenticated = true;
                // this.environment = response.env;
                this.signInResponse = response;
                this.setLocalStorage(response);
            }),
            mergeMap(() => this.productUserService.getUserByEmailByDomain(credentials.email, credentials.subdomain)),  //get user info
            tap((args: any) => {
                user = args[0];
                this._userService.currentUser = user;
            }),
            // mergeMap(() => this.logActivity())
        );
    }

    removeTempLogin(): void {
        localStorage.clear();
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // this.userData = null;
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("domain");

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    logSignout(): any {
        const headers = new HttpHeaders({'Content-type': 'application/json'});
        this._userService.currentUser$
        .subscribe
        const data = {
            email: this._userService.userData.email,
            domain: localStorage.getItem('domain'),
            ipAddress: ''
        }
        return this.http.post(this.apiURL + 'auth/logout', data, { headers: headers })
    }

    /**
     * Sign up
     *
     * @param user
     */
    changePassword(user: any): Observable<any>
    {
        return this._userService.changePassword(user)
            .pipe(
                // map((val: any) => val?.data[0] || IDoc[])
            );

        //logout user?
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    // unlockSession(credentials: { email: string = ''; password: string }): Observable<any>
    // {
    //     return this._httpClient.post('api/auth/unlock-session', credentials);
    // }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        return of(this.isLoggedIn());

        // // Check if the user is logged in
        // if ( this.isLoggedIn() )
        // {
        //     return of(true);
        // }

        // Check the access token availability
        // if ( !this.accessToken )
        // {
        //     return of(false);
        // }

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.accessToken) )
        // {
        //     return of(false);
        // }

        // If the access token exists and it didn't expire, sign in using it
        // return this.accessToken ? of(true) : of(false);
    }

    /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    // setUserData(user): any {
    //     const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    //     //const userData: User = {
    //     const userData: any = {
    //         uid: user.uid,
    //         email: user.email,
    //         emailVerified: user.emailVerified
    //     };
    //     this.userData = userData;
    //     return userRef.set(userData, {
    //         merge: true
    //     });
    // }

    // signInWithCustomToken(credentials: { email: string, password: string }): Observable<any>
    // {
    //     // * Method variables & configs
    //     var uid: string = '';
    //     var token: string = '';
    //     var user: any = {};
    //     var userData: any = {};
    //     var responseStore: any = {};

    //     // * Http options
    //     const httpOptions = {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    //             'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    //         })
    //     };

    //     // * Console log the PROJECT_API
    //     // console.log('METHOD 3 *** PROJECT_API: ', PROJECT_API);

    //     // * Make a request to the API / auth/login endpoint
    //     // ? This will check the email and password and generate a new custom token
    //     return from(this.http.post(PROJECT_API + 'api/auth/login', credentials, httpOptions)
    //     .pipe(
    //         mergeMap((userData: any) => this.afAuth.signInWithCustomToken(userData.token)),
    //     )
    //     .pipe(
    //         tap((response: any) => {
    //             this.setUserData(response.user);
    //             response.user.getIdToken().then((token) => {
    //                 this.accessToken = token;
    //             });
    //             this.refreshToken = response.user.refreshToken;
    //             this._authenticated = true;
    //             this._userService.user = response.user;
    //         }),
    //         // tap((x:any) => console.log('>>>>>>>>>>2', x)),
    //         mergeMap((x: any) => this.productUserService.getUsersByUID(x.user.uid))
    //     ));
    // }

    // generateRefereshToken(): Observable<any> {
    //     let params = {
    //         key: environment.firebase.apiKey
    //     }
    //     let body = new URLSearchParams();
    //         body.set('grant_type', 'refresh_token');
    //         body.set('refresh_token',  this.refreshToken);

    //         let options = {
    //         params: params,
    //         headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    //     };
    // //   console.log('generateRefereshToken >>>>>>>>>>>>>>>>>>>>> ', body, options);
    //   return this.http.post('https://securetoken.googleapis.com/v1/token', body, options)
    //         .pipe(
    //             debounceTime(2000), //let it catch up
    //             distinctUntilChanged() //don't ping me with same value
    //         );
    // }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}


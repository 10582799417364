import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { IDefault } from 'app/shared/model/default';

@Injectable({
    providedIn: 'root'
})
export class UserTypesService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _roles: BehaviorSubject<IDefault[] | null> = new BehaviorSubject<IDefault[] | null>(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for roles
     */
     get userTypes$(): Observable<IDefault[] | null>
     {
         return this._roles.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get categories
     */
    getUserTypes(): Observable<IDefault[]>
    {
        return this.http.get<IDefault[]>(this.apiURL + 'usertypes' ).pipe(
            retry(1),
            tap((response: any) => {
                this._roles.next(response);
            })
        );
    }

    getManagerandpoc(projectId:string): Observable<IDefault[]>
    {
        return this.http.get<IDefault[]>(this.apiURL + `usertypes/${projectId}/managerandpoc` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    getUserTypesByUserId(projectId:string, userTypeId: number): Observable<IDefault[]>
    {
        return this.http.get<IDefault[]>(this.apiURL + `usertypes/${projectId}/usertype/${userTypeId}` ).pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    // getProjectPoc(projectId:string): Observable<IDefault[]>
    // {
    //     return this.http.get<IDefault[]>(this.apiURL + `usertypes/${projectId}/projectpoc` ).pipe(
    //         retry(1),
    //         tap((response: any) => {
    //             console.log('projectpoc:', response);
    //         })
    //     );
    // }
    mapUserTypeWithProject(data:any): Observable<any> {
        return this.http.post<any>(this.apiURL + 'usertypes', JSON.stringify(data), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseAlertType } from '@fuse/components/alert';
import { CurrentUser } from 'app/core/auth/user';
import { UserService } from 'app/core/user/user.service';
import { InternalMessageService } from 'app/layout/layout.service';
import { Control } from 'app/shared/model/findings.type';
import { base_component } from 'app/shared/components/base_component';
import { userTypes } from 'app/shared/model/user-type-list';
import { NotificationService } from 'app/shared/service/notification.service';
import { ProjectUserService } from 'app/shared/service/project-user.service';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ControlAssignService } from 'app/shared/service/control-assign.service';

@Component({
  selector: 'app-reassign',
  templateUrl: './reassign.component.html',
  styleUrls: ['./reassign.component.scss']
})
export class ReassignComponent extends base_component implements OnInit, OnDestroy {

    reassignForm: FormGroup;
    vendorUserForm: FormGroup;

    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    showAlert: boolean = false;
    selectedMember: any;
    currentUser: CurrentUser;
    _members: any;
    projectId: string = '';

    constructor(
        public _notifyService: NotificationService,
        public _userService: UserService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: { control: any, projectId: string, companyGuid: string },
        private _changeDetectorRef: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _controlAssignService: ControlAssignService,
        private _productuserService: ProjectUserService,
        private _internalMessageService: InternalMessageService,
        private _router: Router) {
            super(_notifyService, _userService);
        }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get members(): any
    {
        return this._members;
    }

    get selectedControl(): any
    {
        return this._data.control;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
     ngOnInit(): void
     {
        console.log('ReassignComponent ++++++++++++');
         // Create the form
         this.vendorUserForm = this._formBuilder.group({
            title: ['', [Validators.required]],
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]],
            fax: ['', []],
            assign_user_id: []
         });
         this.reassignForm = this._formBuilder.group({
            assign_user_id: ['', [Validators.required]]
         });

        //  this.getMembers();
         this.getUser();
         this.getCurrentUser();
         console.log('reassignFormSubmit >> ', this._data)
     }

     ngOnDestroy(): void
     {
         // Unsubscribe from all subscriptions
         this.subs.unsubscribe();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    selectedIndexChange(event:any): void {
    }

    /**
     * assign to existing user
     */
    reassignFormSubmit(email:string = ''): void
    {
        console.log('reassignFormSubmit email >> ', email)
        this.showAlert = false;
        this.reassignForm.disable();
        const formValue = this.reassignForm.value;
        console.log('reassignFormSubmit formValue >> ', formValue)
        console.log('reassignFormSubmit selectedControl >> ', this.selectedControl)
        let foundEmail = '';
        let foundMemeber = null;
        if (formValue.assign_user_id) {
            foundMemeber = this.members.find(x => x.id === formValue.assign_user_id);
            this.selectedMember = `${foundMemeber.first_name} ${foundMemeber.last_name}`;
            foundEmail = foundMemeber.email;
        }
        console.log('reassignFormSubmit 1 >> ', foundEmail)
        if (formValue.first_name) {
            this.selectedMember = `${formValue.first_name} ${formValue.last_name}`;
            foundEmail = formValue.email;
        }
        console.log('reassignFormSubmit 2 >> ', this._data.projectId, this._data.control.control_guid)
        const data = {
            company_guid: foundMemeber.company_guid,
            user_id: foundMemeber.id,
            uid: foundMemeber.uid,
            project_guid: this._data.projectId,
            control_guid: this._data.control.control_guid,
            email: email ? email : foundEmail,
            user_type_id: userTypes.supplierUser.id,
            created_by_user_id: this.currentUser.id,
            created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
        }
        console.log('reassignFormSubmit 3 >> ',foundMemeber)
        console.log('reassignFormSubmit 4 >> ', data)
        const assingedTo = `Assigned ${this.selectedControl?.control_id} to ${data.email}`;

        this.subs.sink = this._controlAssignService.assign(data)
        .subscribe((users: any) => {
            // this.addRecordForDelegatedUser();
            this.showAlert = true;
            this.alert = {
                type   : 'success',
                message: assingedTo
            };
            this.reassignForm.enable();
            this._internalMessageService.sendMessage({name:'delegate', data: {action: 'update', control: this.selectedControl}});
            this._notifyService.showSuccess('', `Delegate to ${assingedTo}`);
        });
    }

    /**
     * create new project user
     */
    projectUserSubmit():void {
        const guid = uuid();
        let data = this.vendorUserForm.value;
        data.guid = guid;
        data.active = 1,
        data.role_type_id = userTypes.supplierUser.id;
        data.roles = [userTypes.supplierPOC];
        data.company_id = this.currentUser.company_id,
        data.company_guid = this.currentUser.company_guid;
        data.vendor_id = this._data.companyGuid,
        data.user_id = this.currentUser.id;
        data.created_by = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
        //email data
        data.emailCreatorName = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
        data.emailCreatorEmail = this.currentUser.email;
        data.emailCreatorPhone = this.currentUser.phone;
        this.subs.sink = this._userService.createUser(data)
        .subscribe((result: any) => {
            if (result?.status === 'success') {
                this._notifyService.showSuccess('','Supplier User created successfully.');
                // this._internalMessageService.sendMessage({name:'vendor', data: {vendor: guid, tabName: 'vendor'}});

                this.reassignFormSubmit(this.vendorUserForm.value.email);
                // Mark for check
                this._changeDetectorRef.markForCheck();
            }
            else {
                if (result?.message?.message) {
                    this._notifyService.showError('', result.message.message);
                }
                else {
                    this._notifyService.showError('','Failed to create the Supplier User.');
                }
            }
        });
    }

    memberSelected(member: any) {
        this.selectedMember = this.members.find((x:any) => x.id === member.value) ;
        this._changeDetectorRef.markForCheck();
    }

    close(): void {
        this._matDialog.closeAll();
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    //a link sent to user will need this to view in order to submit evidence and comment
    // private addRecordForDelegatedUser(): void {
    //     const formData = {
    //         control_guid: this._data.control.guid,
    //         engagement_id: this._data.projectId,
    //         created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
    //         user_id: this.currentUser.id,
    //     };
    //     console.log('submit2', formData);
    //     this._interviewService.createInterview(formData)
    //     .subscribe((x) => {
    //             console.log('submit2', x);
    //     });
    // }

    private getUser(): void {
        this.subs.sink = this._productuserService.getUsers()
            .pipe(
                map((val: any) => val?.data[0] || [])
            )
            .subscribe((arg: any) => {
                this._members = arg;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

    // getMembers(): void
    // {
    //     const projectId = this._data.projectId;
    //     console.log(this._data.projectId);
    //     this.memberService.getData(projectId)
    //     .pipe(
    //         map((val: any) => val?.data[0] || [])
    //     )
    //     .subscribe((users: any) => {
    //         //get unique user
    //         this._members = this._helper.findUnique(users, d => d.type);
    //         console.log('>>>>>>>>> users', users , this._members);
    //         this._changeDetectorRef.markForCheck();
    //     });
    // }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap, take, filter } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { Interview } from './interview.types';

@Injectable({
    providedIn: 'root'
})
export class InterviewService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _interview: BehaviorSubject<Interview | null> = new BehaviorSubject(null);
    private _interviews: BehaviorSubject<Interview[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for interviews
     */
     get interviews$(): Observable<Interview[]>
     {
         return this._interviews.asObservable();
     }


    /**
     * Getter for course
     */
     get interview$(): Observable<Interview>
     {
         return this._interview.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get categories
     */
    getInterviews(id: string): Observable<Interview[]>
    {
        return this._httpClient.get<Interview[]>(this.apiURL + 'interviews/' + id ).pipe(
            tap((response: any) => {
                this._interviews.next(response);
            })
        );
    }

    getProjectPercetage(projectId: string, roleId:number): Observable<any[]>
    {
        return this._httpClient.get<any[]>(this.apiURL + `interviews/done/${projectId}/${roleId}` ).pipe(
            retry(1),
            tap((response: any) => {
                this._interviews.next(response);
            })
        );
    }

    submit(data: any): Observable<any>
    {
        return this._httpClient.post(this.apiURL + 'interviews', JSON.stringify(data), this.httpOptions)
        .pipe(
            retry(1),
            tap((response: any) => {
            })
        );
    }

    getProjectResponse(projectId: string, controlId: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `projectresponses/${projectId}/${controlId}`).pipe(
            retry(1),
                map((newInterview) => {
                    // Return the new interview
                    return newInterview;
                })
        );
    }

    createProjectResponse(data: any): Observable<Interview>
    {
        return this._httpClient.post<Interview>(this.apiURL + 'projectresponses', data).pipe(
            retry(1),
            map((newInterview) => {

                // Update the interviews with the new interview
                this._interviews.next([newInterview]);

                // Return the new interview
                return newInterview;
            })
        );
    }

    getRiskGroupNames(projectId: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `interviews/get/riskgroup/names/${projectId}` ).pipe(
            retry(1),
            // map((result: any) => {
            //     // const currentInterview = interview.data[0];
            //     console.warn(result);
            //     return result;
            // }),
            // switchMap((interview) => {
            //     if ( !interview )
            //     {
            //         return throwError('Could not found riskgroups!');
            //     }
            //     return of(interview);
            // })
        );
    }

    getInterviewByRoleId(projectId: string, roleId: number, controlGuid: string): Observable<Interview>
    {
        return this._httpClient.get<Interview>(this.apiURL + `interviews/${projectId}/${roleId}/${controlGuid}` ).pipe(
            retry(1),
            map((interview: any) => {
                const currentInterview = interview.data[0];

                this._interview.next(currentInterview);

                return currentInterview;
            }),
            switchMap((interview) => {

                if ( !interview )
                {
                    return throwError('Could not found interview with id of ' + projectId + '!');
                }

                return of(interview);
            })
        );
    }

    findingWithMatchingRiskGroup(projectId: string, roleId: number, controlGuid: string): Observable<Interview>
    {
        return this._httpClient.get<Interview>(this.apiURL + `interviews/riskgroup/${projectId}/${roleId}/${controlGuid}` ).pipe(
            retry(1),
            map((arg: any) => {
                const matchingRiskGroup = arg.data[0];

                return matchingRiskGroup;
            })
        );
    }

    getInterviewById(projectId: string, controlGuid: string): Observable<Interview>
    {
        return this._httpClient.get<Interview>(this.apiURL + `interviews/${projectId}/${controlGuid}` ).pipe(
            retry(1),
            map((interview: any) => {
                const currentInterview = interview.data[0];

                this._interview.next(currentInterview);

                return currentInterview;
            }),
            switchMap((interview) => {

                if ( !interview )
                {
                    return throwError('Could not found interview with id of ' + projectId + '!');
                }

                return of(interview);
            })
        );
    }

    getCompletedControlCount(projectId: string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `interviews/project/${projectId}/complete/count` ).pipe(
            retry(1),
            map((result: any) => {
                // this._interview.next(currentInterview);

                // Return the course
                return result;
            }),
            switchMap((result) => {
                if ( !result )
                {
                    return throwError('Could not found result with id of ' + projectId + '!');
                }
                return of(result);
            })
        );
    }

    /**
     * Update interview
     *
     * @param id
     * @param interview
     */
     updateInterview(id: number, interview: Interview): Observable<any>
     {
         return this.interviews$.pipe(
             take(1),
             switchMap(interviews => this._httpClient.patch<Interview>(this.apiURL + 'interviews/' + id, {
                 interview
             }).pipe(
                retry(1),
                 map((updatedInterview) => {
                     // Find the index of the updated interview
                     const index = interviews.findIndex(item => item.id === id);

                     // Update the interview
                     interviews[index] = updatedInterview;

                     // Update the interviews
                     this._interviews.next(interviews);

                     // Return the updated interview
                     return updatedInterview;
                 }),
                 switchMap(updatedInterview => this.interview$.pipe(
                     take(1),
                     filter(item => item && item.id === id),
                     tap(() => {

                         // Update the interview if it's selected
                         this._interview.next(updatedInterview);

                         // Return the updated interview
                         return updatedInterview;
                     })
                 ))
             ))
         );
     }

     /**
      * Delete the interview
      *
      * @param id
      */
     deleteInterview(id: number): Observable<boolean>
     {
         return this.interviews$.pipe(
             take(1),
             switchMap(interviews => this._httpClient.delete(this.apiURL + 'interviews', {params: {id}}).pipe(
                retry(1),
                 map((isDeleted: boolean) => {

                     // Find the index of the deleted interview
                     const index = interviews.findIndex(item => item.id === id);

                     // Delete the interview
                     interviews.splice(index, 1);

                     // Update the interviews
                     this._interviews.next(interviews);

                     // Return the deleted status
                     return isDeleted;
                 })
             ))
         );
     }


    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { v4 as uuid } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class EvidenceRequestService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    };

    apiURL = PROJECT_API + 'api/';
    // Private
    private _evidenceRequest: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _evidenceRequests: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for evidenceRequest
     */
    get evidenceRequest$(): Observable<any>
    {
        return this._evidenceRequest.asObservable();
    }

    /**
     * Getter for evidenceRequests
     */
    get evidenceRequests$(): Observable<any[]>
    {
        return this._evidenceRequests.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get evidenceRequests
     */
    getRequestsByProject(projectId: string): Observable<any>
     {
         return this._httpClient.get(this.apiURL + `evidencerequests/project/${projectId}`).pipe(
            //  map((val: any) => val?.data[0] || []),
             tap((evidenceRequests:any) => {
                 this._evidenceRequests.next(evidenceRequests);
             })
         );
     }

    /**
     * Get evidenceRequests
     */
    getRequestsByUser(userId: number): Observable<any>
     {
         return this._httpClient.get(this.apiURL + `evidencerequests/user/${userId}`).pipe(
            retry(1),
             map((val: any) => val?.data[0] || []),
             tap((evidenceRequests) => {
                 this._evidenceRequests.next(evidenceRequests);
             })
         );
     }

    /**
     * Get evidenceRequest by id
     */
    getRequestById(guid: string): Observable<any>
    {
        return this._evidenceRequests.pipe(
            take(1),
            map((val: any) => val?.data[0] || []),
            map((evidenceRequests) => {
                if (evidenceRequests) {
                    // Find the evidenceRequest
                    const evidenceRequest = evidenceRequests.find((x:any) => x.guid === guid) || null;
                    // Update the evidenceRequest
                    this._evidenceRequest.next(evidenceRequest);

                    // Return the evidenceRequest
                    return evidenceRequest;
                }
                else {
                    return null;
                }
            }),
            switchMap((evidenceRequest) => {

                if ( !evidenceRequest )
                {
                    return throwError('Could not found evidenceRequest with id of ' + guid + '!');
                }

                return of(evidenceRequest);
            })
        );
    }

    /**
     * Create evidenceRequest
     */
    createRequest(evidenceRequest: any): Observable<any>
    {
        const guid: string = uuid();
        evidenceRequest.guid = guid;
        const data = JSON.stringify(evidenceRequest);

       return this._httpClient.post<any>(this.apiURL + 'evidencerequests', data, this.httpOptions)
       .pipe(
            map((newRequest: any) => {
                // this._evidenceRequests.next([evidenceRequest, ...evidenceRequests]);

                return newRequest;
            })
       )
    }

    /**
     * Update evidenceRequest
     *
     * @param id
     * @param evidenceRequest
     */
    updateRequest(id: number, evidenceRequest: any): Observable<any>
    {
        return this.evidenceRequests$.pipe(
            take(1),
            switchMap(evidenceRequests => this._httpClient.post<any>(this.apiURL + `evidencerequests/${id}`, {
                evidenceRequest
            }).pipe(
                retry(1),
                map((updatedRequest) => {

                    // Find the index of the updated evidenceRequest
                    const index = evidenceRequests.findIndex(item => item.id === id);

                    // Update the evidenceRequest
                    evidenceRequests[index] = updatedRequest;

                    // Update the evidenceRequests
                    // this._evidenceRequests.next(evidenceRequests);

                    // Return the updated evidenceRequest
                    return updatedRequest;
                }),
                switchMap(updatedRequest => this.evidenceRequest$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the evidenceRequest if it's selected
                        // this._evidenceRequest.next(updatedRequest);

                        // Return the updated evidenceRequest
                        return updatedRequest;
                    })
                ))
            ))
        );
    }

    /**
     * Delete the evidenceRequest
     *
     * @param id
     */
    deleteRequest(id: number): Observable<boolean>
    {
        return this.evidenceRequests$.pipe(
            take(1),
            switchMap(evidenceRequests => this._httpClient.delete(this.apiURL + `evidencerequests/${id}`).pipe(
                map((isDeleted: any) => {

                    // Find the index of the deleted evidenceRequest
                    const index = evidenceRequests.findIndex(item => item.id === id);

                    // Delete the evidenceRequest
                    evidenceRequests.splice(index, 1);

                    // Update the evidenceRequests
                    this._evidenceRequests.next(evidenceRequests);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { Injectable } from '@angular/core';
import { PROJECT_API } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderServiceService {
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'accept': 'application/json',
            'content-type': 'application/json'
        })
    };

    constructor(private _httpClient: HttpClient) { }

    apiURL = PROJECT_API + 'api/';

    async uploadToS3(file:File, key:string): Promise<any> {
        const fileType = encodeURIComponent(file.type);
        const { data } = await axios.get(this.apiURL + `storage/signedUrl?fileName=${file.name}&contentType=${fileType}&key=${key}`);
        const { url } = data;

        return axios.put(url, file);
    }

    //todo: upload
    uploadFileData(formData: any): Observable<any> {
        return this._httpClient.post(this.apiURL + 'companies/upload/logo', JSON.stringify(formData), this.httpOptions)
        .pipe(
            tap((response: any) => {
                console.log("🚀 ~ tap ~ response:", response)
            }),
            catchError(this.handleError)
        );
    }

    getSignedUrls(fileName:string, contentType:string): Observable<any> {
        return this._httpClient.get(this.apiURL + 'storage/signedUrl', {
            params: {
                fileName,
                contentType
            }
        });
    }

    uploadFile(url: string, contentType:string, file: File): Observable<any> {
        return this._httpClient.put(url, file, {
            headers: {
            'Content-Type': contentType
            }
        });
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}


import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { EngagementService } from 'app/shared/service/engagement.service';
import { Engagement } from 'app/shared/model/engagement.types';
import { base_component } from 'app/shared/components/base_component';
import { NotificationService } from 'app/shared/service/notification.service';
import { map } from 'rxjs';
import { AudioRecordingService } from '../../service/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../service/media.service';
import { CurrentUser } from 'app/core/auth/user';
import { v4 as uuid, validate } from 'uuid';
import { TprmProjectService } from 'app/shared/service/tprm-project.service';
import { FileUploaderServiceService } from 'app/shared/service/file-uploader-service.service';
import { AudioTestService } from 'app/shared/service/audio-test.service';
import { FuseLoadingService } from '@fuse/services/loading';

@Component({
    selector: 'app-test-audio',
    templateUrl: './test-audio.component.html',
    styleUrls: ['./test-audio.component.scss']
})
export class TestAudioComponent extends base_component implements OnInit {
    @ViewChild('audioPlayer') audioPlayer: any;
    audio: any;
    project: Engagement;
    currentStep: number = 1;
    isPlaying = false;
    displayControls = true;
    isAudioRecording = false;
    audioRecordedTime;
    audioBlob;
    audioName;
    audioStream;
    audioConf = { audio: true}
    selectedQuestion: any;
    recordedFile: any = null;
    recordingURL: string;
    selectQuestionNumber: number;
    guid: string = '';
    projectId: string = '';
    roleId: string = '';
    questionAnswered: any[] = [];
    context: CanvasRenderingContext2D;
    audioBlobUrl: any;
    interviewType: string = 'Written';
    isTPRM: boolean = false;
    interviewLanguage: string = 'english';
    response: string = '';
    returnedContent: string = '';
    matchRecording: string = '';
    audioStatus:string = '';
    audioStarted: boolean = false;
    campaign: string = '';

    constructor(
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private audioRecordingService: AudioRecordingService,
        private sanitizer: DomSanitizer,
        private _router: Router,
        private _route: ActivatedRoute,
        private _fuseLoadingService: FuseLoadingService,
        private _mediaService: MediaService,
        private _projectService: EngagementService,
        private _audioTestService: AudioTestService,
        private _fileUploadService: FileUploaderServiceService,
        private _tprmProjectService: TprmProjectService
    ) {
        super(_notifyService, _userService);
    }

    ngOnInit(): void {
        this.interviewType = localStorage.getItem('interview') || 'Audio';
        this.interviewLanguage = localStorage.getItem('language') || 'english';
        this.projectId = this._activatedRoute.snapshot.paramMap.get('projectid');
        this.roleId = this._activatedRoute.snapshot.paramMap.get('roleid');
        this.projectId = this._activatedRoute.snapshot.paramMap.get('projectid');
        this.campaign = this._route.snapshot?.paramMap?.get('campaign') || 'cmmc';
        this.isTPRM = this._router.url.includes('tprm');

        this.getCurrentUser();
        this.loadAudioControls();

        if (this.isTPRM) {
            this.getTPRMProject();
        }
        else {
            this.getProject();
        }
    }

    ngOnDestroy() {
        this.abortAudioRecording();
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    startAudioRecording() {
        this.audioStatus = 'start';
        this.audioStarted = true;
        if (this.audioPlayer?.nativeElement) {
            this.audio = this.audioPlayer?.nativeElement;
            this.audio.muted = true;
            this.audio.volume = 0;
            this.audio.setAttribute("hidden", true);
        }
        if (!this.isAudioRecording) {
            this.isAudioRecording = true;
            this.audioRecordingService.startRecording();
        }
        this._changeDetectorRef.detectChanges();
    }

    pauseAudioRecording(): void {
        this.audioStatus = 'pause';
        if (this.isAudioRecording) {
            this.audioRecordingService.pauseRecording();
        }
        this._changeDetectorRef.detectChanges();
    }

    resumeAudioRecording(): void {
        this.audioStatus = 'resume';
        if (this.isAudioRecording) {
            this.audioRecordingService.resumeRecording();
        }
        this._changeDetectorRef.detectChanges();
    }

    abortAudioRecording() {
        this.audioStatus = 'abort';
        this.audioStarted = false;
        if (this.isAudioRecording) {
            this.isAudioRecording = false;
            this.audioRecordingService.abortRecording();
        }
        this._changeDetectorRef.detectChanges();
    }

    stopAudioRecording() {
        this.audioStatus = 'stop';
        this.audioStarted = false;
        this.audio.removeAttribute("hidden");
        if (this.isAudioRecording) {
            // this.setGuid();
            this.audioRecordingService.stopRecording();
            this.isAudioRecording = false;
            if (this.audio) {
                this.audio.muted = false;
                this.audio.volume = 0.8;
            }
        }
        this._changeDetectorRef.detectChanges();
    }

    clearAudioRecordedData() {
        this.audioStatus = '';
        this.audioBlobUrl = null;
        if (this.audio) {
            this.audio.srcObject = null;
            this.audio.removeAttribute("hidden");
        }
        this._changeDetectorRef.detectChanges();
    }

    downloadAudioRecordedData() {
        this._downloadFile(this.audioBlob, 'audio/mp3', this.audioName);
    }

    _downloadFile(data: any, type: string, filename: string): any {
        const blob = new Blob([data], { type: type });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    deleteRecorded(): void {
        this.subs.sink = this._mediaService.delete(this.selectedQuestion.media_guid)
        .subscribe((response:any) => {
            if (response.status === "success" && response.data[0].affectedRows > 0) {
                this._notifyService.showSuccess('', 'Successfully delete the recorded interview.');
                this.selectedQuestion = null;
                this._changeDetectorRef.detectChanges();
            }
            else {
                this._notifyService.showError('', 'Failed to delete the recorded interview.');
            }
        });
    }

    submitAudio(): void {
        const guid = uuid();
        const fileName = `audio-test_${this.currentUser.id}_${guid}`;
        const file = new File([this.audioBlob], fileName, { type: 'audio/mp3' });
        this.uploadMediaFile(file, fileName);
    }

    startInterview(mediaType: string): void {
        // localStorage.setItem('interview', mediaType);
        // const projectId = this._activatedRoute.snapshot.paramMap.get('projectid');
        const roleId = this._activatedRoute.snapshot.paramMap.get('roleid');

        if (this.project?.interaction_id === 1) { //media
            this._router.navigateByUrl(`/interview/interviewer/${this.projectId}/role/${roleId}`);
        }
        else { //control
            this._router.navigateByUrl(`/interview/interviewer/${this.projectId}}`);
        }
    }

    okToStart(): void {
        if (this.projectId && this.roleId) {
            if (this.project?.campaign_guid) {
                const responsibilitygroup = 16; //Self Assessment
                this._router.navigateByUrl(`rapidassess/interview/${this.campaign}/${this.projectId}/${this.roleId}`);
            }
            else {
                if (this.project?.interaction_id === 1) { //media
                    this._router.navigateByUrl(`/interview/interviewer/${this.projectId}/role/${this.roleId}`);
                }
                else { //control
                    this._router.navigateByUrl(`/interview/control/${this.projectId}/role/${this.roleId}`);
                }
            }
        }
        else {
            this._notifyService.showWarning('','Project or Role is missing.');
        }
    }

     // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private uploadMediaFile(file: File, fileName: string) {
        this._fuseLoadingService.show();

        //1. upload file
        const guid = uuid();
        const extArray = file.type.split("/");
        const extension = extArray[extArray.length - 1];
        const key = `${this.currentUser.db_name}/projects/${this.projectId}/audiotest/${guid}_${fileName}.${extension}`;
        this._fileUploadService.uploadToS3(file, key)
        .then((response:any) => {
            this.uploadMediaData(fileName, file.type, key);
        }).catch((response:any) => {
            this._fuseLoadingService.hide();
            this._notifyService.showError('', 'Failed to upload the file.');
        })
    }

    //2. upload data for processing
    private uploadMediaData(fileName:string, fileType:string, key:string) {
        try {
            // const iso = this._helper.getISO(this.interviewLanguage);
            const data =  {
                fileName: fileName,
                contentType: fileType,
                key: key
            }
            this.subs.sink = this._audioTestService.uploadFileData(data)
            .subscribe((response:any) => {
                if (response && response.status === 'success') {
                    this._notifyService.showSuccess('', 'Successfully uploaded the file.');
                    this.returnedContent = response.data.returnedContent;
                }
                else {
                    this._notifyService.showError('', 'Failed to upload the file.');
                }
                this._fuseLoadingService.hide();
                this._changeDetectorRef.detectChanges();
            });

        } catch (error) {
            this._fuseLoadingService.hide();
            this._changeDetectorRef.detectChanges();
        }
    }

    private loadAudioControls() {
        this.subs.sink = this.audioRecordingService.recordingFailed()
        .subscribe(() => {
            this.isAudioRecording = false;
            this._changeDetectorRef.detectChanges();
        });

        this.subs.sink = this.audioRecordingService.getRecordedTime()
        .subscribe((time) => {
            this.audioRecordedTime = time;
            this._changeDetectorRef.detectChanges();
        });

        this.subs.sink = this.audioRecordingService.getRecordedBlob()
        .subscribe((data) => {
            this.audioBlob = data.blob;
            this.audioName = data.title;
            this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
            this._changeDetectorRef.detectChanges();
        });
    }

    private getProject(): void {
        this.subs.sink = this._projectService.getEngagementById(this.projectId)
            .pipe(
                // map((val: any) => val?.data[0] || [])
            )
            .subscribe((project: any) => {
                this.project = project[0];
                this._changeDetectorRef.detectChanges();
            });
    }

    private getTPRMProject(): void {
        this.subs.sink = this._tprmProjectService.getEngagementById(this.projectId)
            .pipe(
                // map((val: any) => val?.data[0] || [])
            )
            .subscribe((project: any) => {
                this.project = project[0];
                this._changeDetectorRef.detectChanges();
            });
    }

    getCurrentUser(): void {
        this.subs.sink = this._userService.currentUser$
        .pipe(
            map((val: any) => val ?
                (Array.isArray(val) ? val[0] : val) : null)
        )
        .subscribe((x: CurrentUser) => {
            this.currentUser = x;
            this._changeDetectorRef.detectChanges();
        });

    }
}

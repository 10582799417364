import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IDoc } from 'app/shared/model/doc.model';
import { HelperService } from 'app/shared/service/helper.service';
import { map } from 'rxjs';
import { SubSink } from 'subsink';
import { DocumentService } from '../../service/document.service';

@Component({
  selector: 'app-display-documents',
  templateUrl: './display-documents.component.html',
  styleUrls: ['./display-documents.component.scss']
})
export class DisplayDocumentsComponent implements OnInit, OnDestroy {

    public files: IDoc[] = [];
    public columns: string[] = [ 'preview', 'name', 'type', 'size'];
    dataSource = [];

    private subs = new SubSink();

    constructor(private route: ActivatedRoute,
                private helper: HelperService,
                private sanitizer: DomSanitizer,
                private doc: DocumentService) { }

    ngOnInit(): void {
        // this.loadExistingFiles();
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    loadExistingFiles(): void {
        const projectId = this.helper.getValidId(this.route);
        this.subs.sink = this.doc.getDocumentByEngagementId(projectId)
            .pipe(
                map((val: any) => val?.data[0] || [])
            )
            .subscribe((docs: any) => {
                this.dataSource = docs;
        });
    }

    sanitizeImageUrl(imageUrl: string): SafeUrl {
        if (imageUrl) {
            return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
        }
        return this.sanitizer.bypassSecurityTrustUrl('assets/images/noimage.png');
    }
}

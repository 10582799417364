<div class="mb-3">
    <fuse-alert [showIcon]="false" [type]="'info'" >
        <div class="font-medium text-blue-300" >{{title}}</div>
        <mat-expansion-panel [expanded]="false" >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Responses ({{replies.length}})
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Conversation -->
            <ng-container *ngIf="messages?.length > 0">
                <div class="flex overflow-y-auto flex-col-reverse">
                    <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent">
                        <ng-container *ngFor="let message of messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
                            <!-- Start of the day -->
                            <ng-container *ngIf="first || (messages[i - 1].created_at | date:'d') !== (message.created_at | date:'d')">
                                <div class="flex items-center justify-center my-3 -mx-6">
                                    <div class="flex-auto border-b"></div>
                                    <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                                        {{message.created_at | date: 'longDate'}}
                                    </div>
                                    <div class="flex-auto border-b"></div>
                                </div>
                            </ng-container>
                            <div
                                class="flex flex-col"
                                [ngClass]="{'items-end': message.parent_id,
                                            'items-start': !message.parent_id,
                                            'mt-0.5': i > 0 && messages[i - 1].isMine === message.parent_id,
                                            'mt-3': i > 0 && messages[i - 1].isMine !== message.parent_id}">
                                <!-- Bubble -->
                                <div
                                    class="relative max-w-3/4 px-3 py-2 rounded-lg"
                                    [ngClass]="{'bg-blue-500 text-blue-50': message.parent_id,
                                                'bg-gray-500 text-gray-50': !message.parent_id}">
                                    <!-- Speech bubble tail -->
                                    <ng-container *ngIf="last || messages[i + 1].isMine !== message.parent_id">
                                        <div
                                            class="absolute bottom-0 w-3"
                                            [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': message.parent_id,
                                                        'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !message.parent_id}">
                                            <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                        </div>
                                    </ng-container>
                                    <!-- Message -->
                                    <div
                                        class="min-w-4 leading-5"
                                        [innerHTML]="message.content">
                                    </div>
                                </div>
                                <!-- Time -->
                                <ng-container
                                    *ngIf="first
                                    || last
                                    || message.content[i + 1].isMine !== message.parent_id
                                    || message.content[i + 1].created_at !== message.created_at">
                                    <div
                                        class="my-0.5 text-sm font-medium text-secondary"
                                        [ngClass]="{'mr-3': message.parent_id,
                                                    'ml-3': !message.parent_id}">
                                        {{message.created_by}} : {{message.created_at | date:'h:mm a'}}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </mat-expansion-panel>

        <!-- Speech bubble tail SVG -->
        <!-- @formatter:off -->
        <ng-template #speechBubbleExtension>
            <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
                </g>
            </svg>
        </ng-template>
        <!-- @formatter:on -->

    </fuse-alert>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ControlAssignService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };
    apiURL = PROJECT_API + 'api/';
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any>
    {
        return this._data.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * assign control
     */
    assign(data: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + 'assigncontrols', JSON.stringify(data), this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    /**
     * Get data by
     * projectId
     * controlId
     */
    // getAssignedUser(projectId: string, controlId: number): Observable<any>
    // {
    //     return this._httpClient.get<any>(`${this.apiURL}assigncontrols/project/${projectId}/control/${controlId}`)
    //     .pipe(
    //         retry(1),
    //         catchError(this.handleError)
    //     );
    // }

    getControlAssignedToVendorUser(userId: number, projectId:string): Observable<any>
    {
        return this._httpClient.get<any>(this.apiURL + `assigncontrols/assigned/${userId}/${projectId}`).pipe(
            tap((response: any) => {
                // this._controls.next(response);
            })
        );
    }

    cancelAssign(projectId: string, controlId: string): Observable<any>
    {
        return this._httpClient.get<any>(`${this.apiURL}assigncontrols/project/${projectId}/control/${controlId}/cancel`)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

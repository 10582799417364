

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, retry, take, tap } from 'rxjs/operators';
import { Company, Country, Tag } from 'app/shared/model/company.types';
import { PROJECT_API } from 'environments/environment';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class EmailService
{
    apiURL = PROJECT_API + 'api/';
    // Private
    private _company: BehaviorSubject<Company | null> = new BehaviorSubject(null);
    private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    sendEmailToVendorPOC(contact: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/sendQuestionnaireCompleteEmail`, contact)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendProjectActiveMsg(project: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/projectActive`, project)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendSales(contact: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/sales`, contact)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendSupport(contact: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/support`, contact)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendInvite(contact: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/initialemail`, contact)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendNewProjectAssignmentAnonymousamousEmail(contact: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/sendNewProjectAssignmentEmail`, contact)
        .pipe(
            catchError(this.handleError)
        );
    }

    sendEmailToAssessor(contact: any): Observable<any>
    {
        return this._httpClient.post<any>(this.apiURL + `email/sendNotifyAssessor`, contact)
        .pipe(
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

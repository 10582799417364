import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, retry, take, tap } from 'rxjs/operators';
import { Company, Country, Tag } from 'app/shared/model/company.types';
import { PROJECT_API } from 'environments/environment';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class MyCompanyService
{
    apiURL = PROJECT_API + 'api/';
    // Private
    private _company: BehaviorSubject<Company | null> = new BehaviorSubject<Company | null>(null);
    // private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for company
     */
    get company$(): Observable<Company | null>
    {
        return this._company.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get company by id
     */
    getCompanyById(id: number): Observable<Company>
    {
        return this._httpClient.get<any>(this.apiURL + 'companies/' + id ).pipe(
            map((val: any) => val?.data[0]  || []),
            map((result: any) => {
                const company = result[0];
                this._company.next(company);
                // Return the course
                return company;
            }),
            switchMap((engagement) => {
                if ( !engagement )
                {
                    return throwError('Could not found engagement with id of ' + id + '!');
                }
                return of(engagement);
            })
        );
    }

    /**
     * Create company
     */
    createCompany(data: any): Observable<any>
    {
        return this._httpClient.post(this.apiURL + 'companies', data)
        .pipe(
            retry(1),
            tap((result: any) => {
                this._company.next(result);
            }),
            catchError(this.handleError)
        );
    }

    /**
     * Update company
     *
     * @param id
     * @param company
     */
    updateCompany(id: string, company: Company): Observable<Company>
    {
        return this._httpClient.patch<Company>(this.apiURL + `companies/${id}`, company).pipe(
                tap((result: any) => {
                    retry(1),
                    // Update the companies
                    this._company.next(result);
                })
            );
    }

    /**
     * Delete the company
     *
     * @param id
     */
    deleteCompany(id: string): Observable<boolean>
    {
        return this._httpClient.delete(this.apiURL + 'companies', {params: {id}})
        .pipe(
            tap((result: any) => {
                retry(1),
                // Update the companies
                this._company.next(result);
            })
        );
    }


    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

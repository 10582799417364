import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { PROJECT_API } from 'environments/environment';
import { Domain } from '../models/domain.types';

@Injectable({
    providedIn: 'root'
})
export class DomainService
{
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
        })
    };

    // Private
    apiURL = PROJECT_API + 'api/';
    private _domain: BehaviorSubject<Domain | null> = new BehaviorSubject(null);
    private _domains: BehaviorSubject<Domain[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private http: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for domains
     */
     get domains$(): Observable<Domain[]>
     {
         return this._domains.asObservable();
     }


    /**
     * Getter for course
     */
     get domain$(): Observable<Domain>
     {
         return this._domain.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get categories
     */
    getDomains(id: string): Observable<Domain[]>
    {
        return this.http.get<Domain[]>(this.apiURL + 'domains/' + id ).pipe(
            tap((response: any) => {
                this._domains.next(response);
            })
        );
    }

    getAvgDomains(projectGuid: string): Observable<any[]>
    {
        return this.http.get<Domain[]>(this.apiURL + 'domains/avg/' + projectGuid ).pipe(
            tap((response: any) => {
                // this._domains.next(response);
            })
        );
    }

    getEvidenceCommentAndDomains(projectGuid: string): Observable<any[]>
    {
        return this.http.get<Domain[]>(this.apiURL + 'domains/evidence/' + projectGuid ).pipe(
            tap((response: any) => {
                // this._domains.next(response);
            })
        );
    }

    /**
     * Get course by id
     */
    // getDomainById(id: string): Observable<Domain>
    // {
    //     // return this._httpClient.get<Domain>('api/apps/domain/' + toNumberId, {}).pipe(
    //     return this.http.get<Domain>(this.apiURL + 'domains/' + id ).pipe(
    //         map((domain) => {
    //             // Update the course
    //             this._domain.next(domain[0]);

    //             // Return the course
    //             return domain[0];
    //         }),
    //         switchMap((domain) => {

    //             if ( !domain )
    //             {
    //                 return throwError('Could not found domain with id of ' + id + '!');
    //             }

    //             return of(domain);
    //         })
    //     );
    // }

    // Error handling
    handleError(error:any): any {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { combineLatestAll, map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { SubSink } from 'subsink';
import { UserService } from 'app/core/user/user.service';
import { CurrentUser } from 'app/core/auth/user';
import { AuthService } from 'app/core/auth/auth.service';
import { Theme } from 'app/core/config/app.config';
import { FuseConfigService } from '@fuse/services/config';
import { HelperService } from 'app/shared/service/helper.service';
import { MailboxService } from 'app/shared/service/mailbox.service';

@Component({
    selector    : 'modern-layout',
    templateUrl : './modern.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    clientName: string = '';
    clientLogo: string = '';

    private subs = new SubSink();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _helper: HelperService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        // private _messagesService: MessagesService,
        private _mailboxService: MailboxService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _authService: AuthService,
        private _fuseConfigService: FuseConfigService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loadPageData();

        // Subscribe to media changes
        this.subs.sink = this._fuseMediaWatcherService.onMediaChange$
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    setTheme(theme: Theme): void
    {
        this._fuseConfigService.config = {theme};
    }

    /*
    * 1. superuser, manager and auditor
    * 2. superuser, manager
    * 3. manager and auditor
    * 4. proejctpoc and Supplier poc
    */
    private loadPageData() {
        let navigation: Navigation = null;
        let currentUser: CurrentUser = null;
        let help = null;
        let tprm = null;
        let chat = null;
        // let homeIndex = 4;
        combineLatest([this._navigationService.navigation$, this._userService.currentUser$])
            .pipe(
                map(([a$, b$]) => ({
                    nav: a$,
                    user: b$
                })),
                tap((x:any) => {
                    navigation = x.nav;
                    currentUser = x.user.length ? x.user[0] : x.user;
                    currentUser.roles = this._helper.removeDuplicateObjectByProperty(currentUser.roles, 'id');

                    this._userService.userData = currentUser;
                    // const messages: Message[] = x.message;
                    this.clientName = currentUser.company || 'FedShark';
                    this.clientLogo = currentUser.logo_uri;
                    this.setTheme(currentUser.theme || 'default');

                    //user value is not valid then sign out
                    if (currentUser === null || currentUser === undefined || (x.user.length === 0)){
                        this._authService.signOut();
                        return;
                    }

                    // 1.Super User // 3.Manager // 4.Supplier POC  // 5.Project POC // 6.Assessor  // 7.Client QA // 8.Peer Review
                    navigation.default[0] = { id: 'apps.dashboard', title: 'Dashboard', type: 'group', link: '/dashboard', icon: 'heroicons_outline:view-grid', children: [] };
                    tprm = {id : 'apps.tprm',title: 'TPRM',type : 'basic',icon : 'heroicons_outline:view-grid',link : '/tprm'} as FuseNavigationItem;
                    // const custom = {id : 'apps.custom',title: 'CUSTOM',type : 'basic',icon : 'heroicons_outline:color-swatch',link : '/custom'} as FuseNavigationItem;
                    help = {id: 'apps.support',title : 'Help Center', subtitle: '', type  : 'group',icon  : 'heroicons_outline:support',children: []} as FuseNavigationItem;
                    // const company = {id: 'apps.home',title : this.clientName, subtitle: '', type  : 'group',icon  : 'heroicons_outline:home',children: []} as FuseNavigationItem;
                    help.children.push(
                            { id : 'help-center.home', title : 'Home', type  : 'basic', link  : '/help-center', exactMatch: true },
                            // { id : 'help-center.faqs', title: 'FAQs', type : 'basic', link : '/help-center/faqs' },
                            // { id : 'help-center.howto', title: 'How To', type : 'basic', link : '/help-center/how-to' },
                            // { id : 'help-center.guides', title: 'Guides', type : 'basic',  link : '/help-center/guides' },
                            { id : 'help-center.support', title: 'Support', type : 'basic', link : '/help-center/support' }
                        );
                    chat = {id: 'apps.chat',title : 'Chat', type : 'basic', icon  : 'heroicons_outline:chat',link : '/chat'} as FuseNavigationItem;
                    currentUser.roles.forEach(role => {
                        switch (role.id) {
                            case 1: //superuser
                                navigation.default[0].children.push({ id: 'apps.dashboard.superuser', title: 'Admin', type: 'basic', link: '/dashboard/superuser/projects', icon: 'heroicons_outline:adjustments' });
                                break;
                            case 2: //Project User
                                navigation.default[0].children.push({ id: 'apps.dashboard.user', title: 'Project User', type: 'basic', link: '/dashboard/projectuser/projects', icon: 'heroicons_outline:identification' });
                                break;
                            case 3: //Manager
                                navigation.default[0].children.push({ id: 'apps.dashboard.mananger', title: 'Project Manager', type: 'basic', link: '/dashboard/projectmanager/projects', icon: 'heroicons_outline:identification' });
                                break;
                            case 4: //Supplier POC
                                navigation.default[0].children.push({ id: 'apps.dashboard.vendor', title: 'Supplier POC', type: 'basic', link: '/dashboard/supplierpoc/dashboard', icon: 'heroicons_outline:identification' });
                                break;
                            case 5: //Project POC
                                navigation.default[0].children.push({ id: 'apps.dashboard.project', title: 'Project POC', type: 'basic', link: '/dashboard/projectpoc/projects', icon: 'heroicons_outline:identification' });
                                break;
                            case 6: //Assessor
                                navigation.default[0].children.push({ id: 'apps.dashboard.assessor', title: 'Assessor', type: 'basic', link: '/dashboard/assessor/projects', icon: 'heroicons_outline:identification' });
                                // roles.forEach(role => {
                                //     switch(+role) {
                                //         case 7: //Client QA
                                //             navigation.default[0].children.push({ id: 'apps.dashboard.qa', title: 'QA', type: 'basic', icon: 'heroicons_outline:identification', link: '/dashboard/qa' });
                                //             break;
                                //         case 8: //Peer Review
                                //             navigation.default[0].children.push({ id: 'apps.dashboard.reviewer', title: 'Reviewer', type: 'basic', icon: 'heroicons_outline:identification', link: '/dashboard/reviewer' });
                                //             break;
                                //     }
                                // })
                                break;
                            case 7: //QA
                                navigation.default[0].children.push({ id: 'apps.dashboard.qa', title: 'QA', type: 'basic', link: '/dashboard/qa/review-reports/projects', icon: 'heroicons_outline:identification' });
                                break;
                            case 9: //Interviewee
                                navigation.default[0].children.push({ id: 'apps.dashboard.interviewee', title: 'Interviewee', type: 'basic', link: '/dashboard/interviewee/list', icon: 'heroicons_outline:identification' });
                                break;
                            case 10: //Supplier user
                                navigation.default[0].children.push({ id: 'apps.dashboard.vendorUser', title: 'Supplier User', type: 'basic', link: '/dashboard/supplieruser/dashboard', icon: 'heroicons_outline:identification' });
                                break;
                            case 11: //tprm manager
                                navigation.default[0].children.push({ id: 'apps.dashboard.tprmManager', title: 'TPRM Manager', type: 'basic', link: '/tprm/suppliers/list', icon: 'heroicons_outline:identification' });
                                break;
                            case 12: //security manager
                                navigation.default[0].children.push({ id: 'apps.dashboard.securityManager', title: 'Security Manager', type: 'basic', link: '/tprm/projects/type/queue', icon: 'heroicons_outline:identification' });
                                break;
                            case 13: //selfassess manager
                                navigation.default[0].children.push({ id: 'apps.dashboard.selfassessManager', title: 'Selfassess Manager', type: 'basic', link: '/dashboard/selfassess/list', icon: 'heroicons_outline:identification' });
                                break;
                        }
                    });

                }),
                mergeMap(() => this._mailboxService.getAll(currentUser?.id)),
            ).subscribe((result: any) => {
                const messages = result?.data[0];
                //using navigation.service inbox place holder

                navigation.default[2] = {id : 'apps.inbox',title: 'Inbox',type : 'basic',icon : 'heroicons_outline:inbox',link : '/messages/inbox',children: [],
                    badge: {
                        title  : messages ? (messages.filter((x:any) => x.unread === 1 || x.unread === true).length || 0).toString() : '0',
                        classes: 'px-2 bg-pink-600 text-white rounded-full'
                    }
                };
                navigation.default[3] = help;
                const allowedRoleToTPRM = [11, 6, 12];
                currentUser.roles.forEach(role => {
                    if (currentUser.tprm_active === 1 && allowedRoleToTPRM.includes(role.id)) {
                        navigation.default[4] = tprm;
                    }
                });
                // navigation.default[5] = chat;

                // navigation.default[homeIndex] = company;

                this.navigation = navigation;
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}

<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-8=100 sm:w-100 mx-auto sm:mx-0" *ngIf="currentUser && status">
            <fuse-alert class="mt-2 mb-2" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type" >
                {{alert.message}}
            </fuse-alert>
            <div>
                <div class="mt-4 text-4xl font-extrabold tracking-tight leading-tight">
                    {{entity?.name?.toUpperCase()}}
                    <span class="mt-4 mb-4 text-2xl font-extrabold">{{status?.message}}</span>
                </div>
            </div>
                <!-- --m:{{isProjectManager}} --a:{{isAssessor}}-- {{statusId}} -- {{status | json}} -->
                <ng-container *ngIf="isProjectManager && status?.id != 1">
                    <mat-form-field style="width: 400px;" class="mt-6">
                        <mat-select [(value)]="statusId">
                            <ng-container *ngFor="let status of statusList">
                                <mat-option [value]="status.id">{{status.title.toUpperCase()}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <!-- Submit button -->
                <button class="fuse-mat-button-large w-full mt-10" mat-flat-button type="button" [color]="'primary'"
                    (click)="submit()" *ngIf="isAssessor || isProjectManager">
                    <span *ngIf="status?.id === 1">{{status?.action}}</span>
                    <span *ngIf="status?.id !== 1">Submit</span>
                    <!-- <mat-progress-spinner
                        *ngIf="kickoffForm.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner> -->
                </button>

                <div class="mt-10 mb-4 tracking-tight leading-tight">{{status?.description}}</div>
                <ng-container *ngIf="status?.id > 1">
                    <div class="mt-12 text-4xl font-medium cursor-pointer text-primary-500 text-center" (click)="routeToProject()" >
                        Go to the Project
                    </div>
                </ng-container>
        </div>
        <div class="w-full max-w-8=100 sm:w-100 mx-auto sm:mx-0" *ngIf="currentUser && (!status || status?.code == 'unknown') ">
            <ng-container *ngIf="entity?.name">
                <div class="mt-4 text-4xl font-extrabold tracking-tight leading-tight">{{entity?.name?.toUpperCase()}}</div>
                <div class="mt-4 mb-4 text-2xl font-extrabold justify-center">Unable to determine status of the project.</div>
            </ng-container>
        </div>
        <ng-container *ngIf="!entity ">
            <div class="mt-2 text-lg md:text-xl font-medium tracking-tight text-center text-secondary">
                The project you requested could not be found.
            </div>
            <div class="mt-2 text-lg md:text-xl font-medium tracking-tight text-center text-secondary">
                <a class="mt-12 font-medium underline text-primary-500 text-center"
                    [routerLink]="['/dashboard']">
                    Back to Dashboard
                </a>
            </div>
        </ng-container>
    </div>
</div>


import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { Engagement, Task } from 'app/shared/model/engagement.types';
import { fuseAnimations } from '@fuse/animations';
import { base_component } from 'app/shared/components/base_component';
import { UserService } from 'app/core/user/user.service';
import { NotificationService } from 'app/shared/service/notification.service';
import { v4 as uuid } from 'uuid';
import { InternalMessageService } from 'app/layout/layout.service';
import { FuseAlertType } from '@fuse/components/alert';
import { UserCompanyService } from 'app/shared/service/user-company.service';
import { FileUploaderServiceService } from 'app/shared/service/file-uploader-service.service';
import { FuseLoadingService } from '@fuse/services/loading';

@Component({
    selector: 'app-upload-logo-control',
    templateUrl: './upload-logo-control.component.html',
    styleUrls: ['./upload-logo-control.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations : fuseAnimations
})
export class UploadLogoControlComponent extends base_component implements OnInit, OnDestroy
{
    @Input() drawer: MatDrawer;
    @Input() controlName: string = '';
    @Input() companyId: string = '';
    @Input() projectId: string = '';
    @Input() controlId: string = '';
    @Input() showUploadedFiles: boolean = true;
    @Input() showFinalButton: boolean = false;
    @Input() showFlow: boolean = false;
    @Input() showNote: boolean = true;
    @Input() isAuditor: boolean = true;
    @Input() showClose: boolean = false;
    @Input() docType: string = 'evidences';
    @Input() customerType: string = 'client';

    // control: Control;
    showDocComment: boolean = false;
    currentDoc: string = '';
    projectAuditorId: number = 0;
    engagement: Engagement;
    uploadForm: FormGroup;
    evidenceDocuments: any[] = [];
    evidenceList: any[] = [];
    documents: any[] = [];
    file: any;
    evidences: any[] = [];
    memberList: any[] = [];
    members: any[] = [];
    reportFlow: string = '';
    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
      };

    /**
     * Constructor
     */
    constructor(
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _formBuilder: FormBuilder,
        private _fuseLoadingService: FuseLoadingService,
        private _companyService: UserCompanyService,
        private _fileUploadService: FileUploaderServiceService,
        private _internalMessageService: InternalMessageService,
    )
    {
        super(_notifyService, _userService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    // get isAuditor(): boolean {
    //     return this.getUserType('Auditor');
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.setUpForm();
        this.getCurrentUser();
        // this.getProjectInfo();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    async onSelect(event:any): Promise<void> {
        // this.files.push(...event.addedFiles);
        this.file = event.addedFiles[0];
    }

    onRemove(): void {
        // this.files.splice(this.files.indexOf(event), 1);
        this.file = null;
    }

    //1. get presigned url
    uploadFile() {
        const file = this.file;
        const fileName = file.name;
        this._fuseLoadingService.show();

        //1. upload file
        const extArray = file.type.split("/");
        const extension = extArray[extArray.length - 1];
        const key = `${this.currentUser.db_name}/logo/${fileName}.${extension}`;
        this._fileUploadService.uploadToS3(file, key)
        .then((response:any) => {
            this.uploadFileData(fileName, file.type, file.size, key);
        }).catch((response:any) => {
            this._fuseLoadingService.hide();
            this._notifyService.showError('', 'Failed to upload the file.');
        })
    }

    /*
    1.if user is not auditor then report gets send back to auditor unless marked as final
    2.if final then it goes to project poc
    3.last in queue shows up in their inbox/report page
    */
    //2. upload data for processing
    private uploadFileData(fileName:string, fileType:string, fileSize:number, key:string) {
        try {
            this.alert.message = '';
            const data = this.uploadForm.getRawValue();
            const guid = uuid();

            const formData = new FormData();
            formData.append('guid', guid);
            formData.append('doc_type', this.docType);
            formData.append('file_name', fileName);
            formData.append('file_type', fileType);
            formData.append('file_size', fileSize.toString());
            formData.append('key', key);
            formData.append('user_id', this.currentUser.id.toString());
            formData.append('project_guid', this.projectId);
            formData.append('company_id', this.companyId);
            formData.append('customerType', this.customerType);
            formData.append('created_by', `${this.currentUser.first_name} ${this.currentUser.last_name}`);

            //todo: upload
            this.subs.sink = this._companyService.uploadFile(formData) //, 'companies/upload/logo')
            .subscribe((response:any) => {
                if (response.data.status === "success") {
                    this._notifyService.showSuccess('', 'Successfully uploaded the file.');
                    this.file = null;
                    this.uploadForm.reset();
                    this._internalMessageService.sendMessage({name:'upload', data: {success: true} });
                    this.alert.type = 'success';
                    this.alert.message = 'File uploaded successfully.';
                }
                else {
                    this._notifyService.showError('', 'Failed to upload the file.');
                    this.alert.type = 'error';
                    this.alert.message = 'Failed to upload the file.';
                }
            });
        }
        catch(error) {
            this.alert.type = 'warning';
            this.alert.message = 'Failed to upload the file.';
            this._notifyService.showWarning('', 'Error uploding logo');
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private setUpForm() {
        this.uploadForm = this._formBuilder.group({
            note: ['', []],
            report_user_id: [0],
            isFinal: [0]
        });
    }
}


